import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
let logoutTimer;
const authContext = createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

const calculateTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const remainingTime = expirationTime - currentTime;
  //   console.log("Current time", currentTime);
  //   console.log("Expiration time", expirationTime);
  //   console.log("Remaining time", remainingTime);
  return remainingTime;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const expirationTime = localStorage.getItem("expirationTime");

  const remainingTime = calculateTime(expirationTime);

  if (remainingTime <= 3600) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    return null;
  }
  return { token: storedToken, duration: remainingTime };
};

export const AuthContextProvider = (props) => {
  const [userDetailedData, setUserDetailedData] = useState(
    JSON.parse(localStorage.getItem("userDetailedData"))
  );
  const tokenData = retrieveStoredToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [token, setToken] = useState(initialToken);
  const [userData, setUserData] = useState({});
  // let Data = jwt_decode(token);
  //   setUserData(Data);
  const isLoggedIn = !!token;

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");

    localStorage.removeItem("activeLocation");
    localStorage.removeItem("showEmployeeDirectory");
    localStorage.removeItem("activeCompany");
    localStorage.removeItem("activeVisitor");
    localStorage.removeItem("userDetailedData");
    localStorage.removeItem("activeEditCompanyId");
    localStorage.removeItem("activeEditType");
    localStorage.removeItem("activeBuilding");
    localStorage.removeItem("activeEditEmployeeId");
    localStorage.removeItem("activeEditLocationId");
    localStorage.removeItem("activeBuilding");
    localStorage.removeItem("activeEditLocationId");
    localStorage.removeItem("multipleLocationId");
    localStorage.removeItem("barChartPayload");
    localStorage.removeItem("visitorFormData");
    localStorage.removeItem("activeEditSubadminId");

    window.location.reload();
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };
  const loginHandler = (token, expirationTime) => {
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);
    const remainingTime = calculateTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, userData, setUserData]);

  const contextValue = {
    token: token,
    isLoggedIn: isLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    userData: tokenData ? jwt_decode(token) : undefined,
    userDetailedData: userDetailedData,
    setUserDetailedData: setUserDetailedData,
  };
  return (
    <authContext.Provider value={contextValue}>
      {props.children}
    </authContext.Provider>
  );
};

export default authContext;
