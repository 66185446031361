import classes from "../../Employee/AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useContext } from "react";
import axios from "axios";
import authContext from "../../../../AuthContext/authContext";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const EditSubadmin = ({ initialData }) => {
  const ctx = useContext(authContext);
  const user_id = ctx.userData.user_id;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const nameRegExp = /^[A-Za-z ]+$/;
  const locationRegExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\S]*$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialData.name,
      email: initialData.email,
      phone: initialData.phone,
      password: "",
      subAdminLocation: initialData.location,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(nameRegExp, "Please enter valid name")
        .required("Please enter your name"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email address"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter your phone number"),
      password: Yup.string().min(8, "password must be 8 characters long"),
      subAdminLocation: Yup.string()
        .matches(locationRegExp, "Location cannot be a number")
        .required("Please enter your location"),
    }),
    onSubmit: (values) => {
      let dataObj = {
        userId: initialData._id,
        email: values.email,
        phone: values.phone,
        name: values.name,
        location: values.subAdminLocation,
      };

      if (values.password !== "") {
        dataObj["password"] = values.password;
      }
      let reqObj = {
        method: "PUT",
        url: `${base_url}/user/profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ctx.token}`,
        },
        data: dataObj,
      };

      axios(reqObj)
        .then((res) => {
          toast.dismiss();
          toast.success("Sub Admin Data Updated Successfully!!");
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss();
          toast.error(
            err.response.data.error.message || "Failed to Update Subadmin"
          );
        });
    },
  });
  return (
    <>
      <div className={classes.mainCont}>
        <h3>Edit Sub Admin</h3>

        <form className={classes.FormDiv} onSubmit={formik.handleSubmit}>
          <div className={classes.formGrp}>
            <label for="name">Sub Admin Name:</label>
            <div>
              <input
                type="text"
                id="name"
                name="name"
                {...formik.getFieldProps("name")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.name && formik.errors.name ? (
                  <div>{formik.errors.name}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="email">Sub Admin Email:</label>
            <div>
              <input
                type="text"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.email && formik.errors.email ? (
                  <div>{formik.errors.email}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="phone">Sub Admin Phone: </label>
            <div>
              <input
                type="text"
                id="phone"
                name="phone"
                {...formik.getFieldProps("phone")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.phone && formik.errors.phone ? (
                  <div>{formik.errors.phone}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="subAdminLocation">Sub Admin Location:</label>
            <div>
              <input
                type="text"
                id="subAdminLocation"
                name="subAdminLocation"
                {...formik.getFieldProps("subAdminLocation")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.subAdminLocation &&
                formik.errors.subAdminLocation ? (
                  <div>{formik.errors.subAdminLocation}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="password">Password:</label>
            <div>
              <input
                type="password"
                id="password"
                name="password"
                {...formik.getFieldProps("password")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.password && formik.errors.password ? (
                  <div>{formik.errors.password}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formBtn}>
            <button type="submit" className={classes.createBtn}>
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditSubadmin;
