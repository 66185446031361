import classes from "./CovidQuestions.module.css";
import headClasses from "../Terms/Terms.module.css";
import footerClass from "../Terms/Terms.module.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import SubmitForm from "../submitApi/SubmitForm";
import axios from "axios";
import { useState } from "react";
import Footer from "../Footer/Footer";
import "../../../assets/css/visitor-css/master.css";
import ClearData from "../ClearData/ClearData";
import { useEffect } from "react";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";

const moment = require("moment");
const superagent = require("superagent");
const base_url = process.env.REACT_APP_BASE_URL;
const CovidQuestions = () => {
  const navigate = useNavigate();
  const companyData = useSelector((state) => state.visitorData.companyData);
  const OTPTime = useSelector((state) => state.visitorData.OTPTime);
  const visitorFormData = useSelector(
    (state) => state.visitorData.visitorFormData
  );
  const isRepeated = useSelector((state) => state.visitorData.isRepeated);
  const visitorPhone = useSelector((state) => state.visitorData.visitorPhone);
  const doc1 = useSelector((state) => state.visitorData.doc1);
  const doc2 = useSelector((state) => state.visitorData.doc2);
  const selfie = useSelector((state) => state.visitorData.selfie);
  const [loading, setLoading] = useState(false);

  // console.log("Selfie....", selfie.file);

  let DeviceDetails = navigator.userAgent;

  // const handleCompression = (imgFile) => {
  //   return new Promise((resolve, reject) => {
  //     const options = {
  //       maxSizeMB: 0.5,
  //       maxWidthOrHeight: 200,
  //       useWebWorker: false,
  //     };
  //     imageCompression(imgFile, options).then((img) => {
  //       console.log("Compressed image", img);

  //       const convertedBlobFile = new File([img], imgFile.name, {
  //         type: imgFile.type,
  //         lastModified: Date.now(),
  //       });
  //       console.log("Compressed File", convertedBlobFile);
  //       resolve(convertedBlobFile);
  //     });
  //   });
  // };

  // handleCompression(selfie.file);
  // const handleSubmit = (e) => {
  //   navigate("/visitor-pass");
  // };

  // window.addEventListener("load", function (e) {
  //   // e.preventDefault();
  //   this.setTimeout(() => {
  //     navigate("/selfie");
  //   }, 5000);
  // });

  useEffect(() => {
    if (!selfie || !doc1) {
      navigate("/selfie");
    }
  }, []);

  let initialValues = {
    err1: "",
    err2: "",
    err3: "",
    err4: "",
    err5: "",
  };
  let covidSchema = {
    err1: Yup.string(),
    err2: Yup.string(),
    err3: Yup.string(),
    err4: Yup.string(),
    err5: Yup.string(),
  };

  if (companyData.covidAnswerOne) {
    // companyData.covidAnswerOne.split("|").forEach((element) => {
    initialValues["ans1"] = "";
    covidSchema["ans1"] = Yup.string().required("Please answer question que 1");
    // });
  }

  if (companyData.covidAnswerTwo) {
    // companyData.covidAnswerTwo.split("|").forEach((element) => {
    initialValues["ans2"] = "";
    covidSchema["ans2"] = Yup.string().required("Please answer question que 2");
    // });
  }

  if (companyData.covidAnswerThree) {
    // companyData.covidAnswerThree.split("|").forEach((element) => {
    initialValues["ans3"] = "";
    covidSchema["ans3"] = Yup.string().required("Please answer question que 3");
    // });
  }

  if (companyData.covidAnswerFour) {
    // companyData.covidAnswerFour.split("|").forEach((element) => {
    initialValues["ans4"] = "";
    covidSchema["ans4"] = Yup.string().required("Please answer question que 4");
    // });
  }

  if (companyData.covidAnswerFive) {
    // companyData.covidAnswerFive.split("|").forEach((element) => {
    initialValues["ans5"] = "";
    covidSchema["ans5"] = Yup.string().required("Please answer question que 5");
    // });
  }

  function getAnswer(values, ansNo) {
    const result = Object.entries({ ...values })
      .filter(([key, value]) => key.includes(ansNo))
      .map(([key, value]) => {
        return { [key]: value };
      });
    let ansArr = [];

    const ansArr1 = result.filter((item) => {
      Object.entries({ ...item }).forEach((obj) => {
        if (obj[1] == true) {
          ansArr.push(obj[0]);
        }
      });
    });

    let str = "";
    const uniqueAnsArr = [...new Set([...ansArr])];
    uniqueAnsArr.map((item, index) => {
      if (index === uniqueAnsArr.length - 1) {
        str += item.substring(4, item.length);
      } else {
        str += `${item.substring(4, item.length)}|`;
      }
    });
    return str;
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(covidSchema),
    onSubmit: (values) => {
      // if (!selfie || !doc1) {
      //   navigate("/selfie");
      //   return;
      // }
      // return;

      // console.log("Selfie Object...", selfie["file"]);
      // console.log("Selfie Object...", JSON.stringify(selfie["file"]["name"]));
      // return;

      setLoading(true);
      const reqObj = {};
      // let hasErr = false;
      if (companyData.covidQuestionOne) {
        reqObj.questionOne = companyData.covidQuestionOne;
        reqObj.answerOne = values.ans1;
      }

      if (companyData.covidQuestionTwo) {
        reqObj.questionTwo = companyData.covidQuestionTwo;
        reqObj.answerTwo = values.ans2;
      }

      if (companyData.covidQuestionThree) {
        reqObj.questionThree = companyData.covidQuestionThree;
        reqObj.answerThree = values.ans3;
      }

      if (companyData.covidQuestionFour) {
        reqObj.questionFour = companyData.covidQuestionFour;
        reqObj.answerFour = values.ans4;
      }

      if (companyData.covidQuestionFive) {
        reqObj.questionFive = companyData.covidQuestionFive;
        reqObj.answerFive = values.ans5;
      }
      // SubmitForm(visitorFormData, selfie.file, doc1.file, doc2.file, reqObj);
      // return;
      const formData = new FormData();
      // formData.append("phone", 9988998840);
      formData.append("phone", Number(visitorPhone));
      formData.append("firstName", visitorFormData.firstName);
      formData.append("lastName", visitorFormData.lastName);
      formData.append("companyName", visitorFormData.companyName);
      formData.append("address", visitorFormData.Address);
      formData.append("purposeOfVisit", visitorFormData.Purpose);
      if (companyData.cardCheck) {
        if (visitorFormData["card_no"]) {
          formData.append("cardNo", visitorFormData["card_no"]);
        }
      }

      if (companyData.accessoryCheck) {
        if (visitorFormData["laptop_check"]) {
          formData.append("isLaptop", visitorFormData["laptop_check"]);
          if (visitorFormData["laptop_serial_no"]) {
            formData.append(
              "laptopSerial",
              visitorFormData["laptop_serial_no"]
            );
          }
        }

        if (visitorFormData["mobile_check"]) {
          formData.append("isMobile", visitorFormData["mobile_check"]);
          if (visitorFormData["mobile_serial_no"]) {
            formData.append(
              "mobileSerial",
              visitorFormData["mobile_serial_no"]
            );
          }
        }

        if (visitorFormData["hard_disk_check"]) {
          formData.append("isHardDisk", visitorFormData["hard_disk_check"]);
          if (visitorFormData["hard_disk_serial_no"]) {
            formData.append(
              "hardDiskSerial",
              visitorFormData["hard_disk_serial_no"]
            );
          }
        }

        if (visitorFormData["charger_check"]) {
          formData.append("isCharger", visitorFormData["charger_check"]);
          if (visitorFormData["charger_serial_no"]) {
            formData.append(
              "chargerSerial",
              visitorFormData["charger_serial_no"]
            );
          }
        }

        if (visitorFormData["other_accessory_check"]) {
          formData.append(
            "isOtherAccessory",
            visitorFormData["other_accessory_check"]
          );
          if (visitorFormData["other_accessory_serial_no"]) {
            formData.append(
              "otherAccessorySerial",
              visitorFormData["other_accessory_serial_no"]
            );
          }
        }
      }

      formData.append("empPhoneNumber", visitorFormData.meeting_person_phone);
      formData.append("empName", visitorFormData.meeting_person_name);
      formData.append(
        "empDesignation",
        visitorFormData.meeting_person_designation
      );
      formData.append(
        "empDepartment",
        visitorFormData.meeting_person_department
      );

      // formData.append("selfie", selfie.base64);
      // formData.append("docFront", doc1.base64);

      // if (doc2) {
      //   formData.append("docBack", doc2.base64);
      // }

      // formData.append("selfieFile", selfie.file);
      // formData.append("docFrontFile", doc1.file);
      // if (doc2) {
      //   formData.append("docBackFile", doc2.file);
      // }
      if (typeof selfie == "string") {
        formData.append("existingSelfie", selfie);
      } else {
        formData.append("selfieFile", selfie.file);
      }
      if (companyData.validId) {
        if (typeof doc1 == "string") {
          formData.append("existingDocFront", doc1);
        } else {
          formData.append("docFrontFile", doc1.file);
        }

        if (doc2) {
          if (typeof doc2 == "string") {
            formData.append("existingDocBack", doc2);
          } else {
            formData.append("docBackFile", doc2.file);
          }
        }
      }

      formData.append("isRepeated", isRepeated);

      // formData.append("selfieFile", await handleCompression(selfie.file));
      // formData.append("docFrontFile", await handleCompression(doc1.file));
      // if (doc2) {
      //   formData.append("docBackFile", await handleCompression(doc2.file));
      // }

      formData.append("companyId", companyData._id);
      if (visitorFormData.locationId) {
        formData.append("locationId", visitorFormData.locationId);
      }

      formData.append("lat", visitorFormData.lat);
      formData.append("long", visitorFormData.long);
      formData.append("smsCount", 0);
      formData.append("otpSent", OTPTime);
      formData.append("checkedIn", true);
      // formData.append("otpSent", utcSmsTime);

      if (reqObj.questionOne) {
        formData.append("questionOne", reqObj.questionOne);
        formData.append("answerOne", reqObj.answerOne);
      }
      if (reqObj.questionTwo) {
        formData.append("questionTwo", reqObj.questionTwo);
        formData.append("answerTwo", reqObj.answerTwo);
      }
      if (reqObj.questionThree) {
        formData.append("questionThree", reqObj.questionThree);
        formData.append("answerThree", reqObj.answerThree);
      }
      if (reqObj.questionFour) {
        formData.append("questionFour", reqObj.questionFour);
        formData.append("answerFour", reqObj.answerFour);
      }
      if (reqObj.questionFive) {
        formData.append("questionFive", reqObj.questionFive);
        formData.append("answerFive", reqObj.answerFive);
      }
      formData.append("passType", "Normal");
      console.log("Form Data", formData);

      //Submit Form

      // fetch(`${base_url}/visitor/add`, {
      //   method: "POST",
      //   body: formData,
      // })

      axios
        .post(`${base_url}/visitor/add`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("Fetch Ran");
          navigate("/thank-you");
          setLoading(false);
          ClearData();
        })
        .catch((err) => {
          console.log("Fetch Ran");
          console.log(err);

          toast.error(
            "Your request is taking longer than usual, Please check your internet connection and try again.",
            {
              position: "top-center",
            }
          );

          axios
            .post(
              "https://digipass-logger-default-rtdb.firebaseio.com/logs.json",
              {
                error: err,
                device: DeviceDetails,
                issueTime: new Date().toLocaleString(),
                payload: {
                  phone: visitorPhone,
                  visitorFormData: visitorFormData,
                  selfie: JSON.stringify(selfie["file"]["name"]) || "No Selfie",
                  doc1: JSON.stringify(doc1["file"]["name"]) || "No govt-id 1",
                  covidQues: reqObj,
                },
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });

          //Retry
          setTimeout(() => {
            // axios
            //   .post(`${base_url}/visitor/add`, formData, {
            //     headers: {
            //       "Content-Type": "multipart/form-data",
            //     },
            //   })
            //   .then((res) => {
            //     navigate("/thank-you");
            //     setLoading(false);
            //     ClearData();
            //   })
            //   .catch((err) => {
            //     axios.post(
            //       "https://digipass-logger-default-rtdb.firebaseio.com/logs.json",
            //       {
            //         error: err,
            //         device: DeviceDetails,
            //         retry: true,
            //         issueTime: new Date().toLocaleString(),
            //         payload: {
            //           phone: visitorPhone,
            //           visitorFormData: visitorFormData,
            //           selfie:
            //             JSON.stringify(selfie["file"]["name"]) || "No Selfie",
            //           doc1:
            //             JSON.stringify(doc1["file"]["name"]) || "No govt-id 1",
            //           covidQues: reqObj,
            //         },
            //       }
            //     );

            //     //Redirect to selfie
            //     localStorage.setItem("submit_failed", "True");
            //     window.location.reload();
            //     setLoading(false);
            //   });

            superagent
              .post(`${base_url}/visitor/add`)
              .send(formData)
              .end((err, res) => {
                console.log("Created with super agent", err);
                // localStorage.setItem("Superagent err", err);
                // return;
                if (err) {
                  //Log error

                  axios
                    .post(
                      "https://digipass-logger-default-rtdb.firebaseio.com/logs.json",
                      {
                        error: err,
                        device: DeviceDetails,
                        retry: "Retry failed with superagent as well",
                        issueTime: new Date().toLocaleString(),
                        payload: {
                          phone: visitorPhone,
                          visitorFormData: visitorFormData,
                          selfie:
                            JSON.stringify(selfie["file"]["name"]) ||
                            "No Selfie",
                          doc1:
                            JSON.stringify(doc1["file"]["name"]) ||
                            "No govt-id 1",
                          covidQues: reqObj,
                        },
                      }
                    )
                    .then(() => {
                      //Redirect to selfie
                      localStorage.setItem("submit_failed", "True");
                      window.location.reload();
                      setLoading(false);
                    })
                    .catch((err) => {
                      //Redirect to selfie
                      localStorage.setItem("submit_failed", "True");
                      window.location.reload();
                      setLoading(false);
                    });

                  return;
                }

                navigate("/thank-you");
                setLoading(false);
                ClearData();
                console.log(res.body);
              });
          }, 10000);

          // setLoading(false);
        });
    },
  });

  return (
    <>
      <div className="wrapper-box">
        <div className="container">
          <div className="wrapper-mid" style={{ background: "#fff" }}>
            <div className="covid-head">
              <h4>Please answer the following questions correctly</h4>
            </div>

            <form className={classes.formDiv} onSubmit={formik.handleSubmit}>
              <div className="covidquestions-panel">
                {companyData.covidQuestionOne && (
                  <div className={classes.inputDiv}>
                    <p>Q1. {companyData.covidQuestionOne}</p>
                    <div className={classes.inputField}>
                      {companyData.covidAnswerOne.split("|").map((ans) => {
                        return (
                          <>
                            <input
                              type="radio"
                              value={ans}
                              id="ans1"
                              name="ans1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                              <label>{ans}</label>
                          </>
                        );
                      })}
                    </div>

                    <span className={classes.errMsg}>
                      {formik.touched.ans1 && formik.errors.ans1 ? (
                        <div>{formik.errors.ans1}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                {companyData.covidQuestionTwo && (
                  <div className={classes.inputDiv}>
                    <p>Q2. {companyData.covidQuestionTwo}</p>
                    <div className={classes.inputField}>
                      {companyData.covidAnswerTwo.split("|").map((ans) => {
                        return (
                          <>
                            <input
                              type="radio"
                              value={ans}
                              id="ans2"
                              name="ans2"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                              <label>{ans}</label>
                          </>
                        );
                      })}
                    </div>
                    <span className={classes.errMsg}>
                      {formik.touched.ans2 && formik.errors.ans2 ? (
                        <div>{formik.errors.ans2}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                {companyData.covidQuestionThree && (
                  <div className={classes.inputDiv}>
                    <p>Q3. {companyData.covidQuestionThree}</p>
                    <div className={classes.inputField}>
                      {companyData.covidAnswerThree.split("|").map((ans) => {
                        return (
                          <>
                            <input
                              type="radio"
                              value={ans}
                              id="ans3"
                              name="ans3"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                              <label>{ans}</label>
                          </>
                        );
                      })}
                    </div>
                    <span className={classes.errMsg}>
                      {formik.touched.ans3 && formik.errors.ans3 ? (
                        <div>{formik.errors.ans3}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                {companyData.covidQuestionFour && (
                  <div className={classes.inputDiv}>
                    <p>Q4. {companyData.covidQuestionFour}</p>
                    <div className={classes.inputField}>
                      {companyData.covidAnswerFour.split("|").map((ans) => {
                        return (
                          <>
                            <input
                              type="radio"
                              value={ans}
                              id="ans4"
                              name="ans4"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                              <label>{ans}</label>
                          </>
                        );
                      })}
                    </div>
                    <span className={classes.errMsg}>
                      {formik.touched.ans4 && formik.errors.ans4 ? (
                        <div>{formik.errors.ans4}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                {companyData.covidQuestionFive && (
                  <div className={classes.inputDiv}>
                    <p>Q5. {companyData.covidQuestionFive}</p>
                    <div className={classes.inputField}>
                      {companyData.covidAnswerFive.split("|").map((ans) => {
                        return (
                          <>
                            <input
                              type="radio"
                              value={ans}
                              id="ans5"
                              name="ans5"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                              <label>{ans}</label>
                          </>
                        );
                      })}
                    </div>
                    <span className={classes.errMsg}>
                      {formik.touched.ans5 && formik.errors.ans5 ? (
                        <div>{formik.errors.ans5}</div>
                      ) : null}
                    </span>
                  </div>
                )}
                <button type="submit" className={classes.btn}>
                  {loading ? (
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
            <Footer footerColor={"black"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CovidQuestions;
