import { useNavigate } from "react-router-dom";
import classes from "./BackButton.module.css";

const BackButton = ({ path }) => {
  const navigate = useNavigate();
  return (
    <>
      <img
        className={classes.backBtn}
        src={require("../../../assets/img/visitor-images/back.png")}
        onClick={() => navigate(`${path}`)}
      />
    </>
  );
};

export default BackButton;
