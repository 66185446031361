import Home from "../../Common/Home/Home";
import { useContext, useState } from "react";
import authContext from "../../../../AuthContext/authContext";
import axios from "axios";
import Table from "../../Table/Table";
import moment from "moment";
import ConfirmModal from "../../Common/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;

const CompaniesOnboaredWrapper = () => {
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [isLoading, setIsloading] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const updateSwitch = (companyId, fieldName, fieldValue) => {
    console.log(companyId, fieldName, fieldValue);

    setIsloading(true);
    let reqObj = {
      method: "PUT",
      url: `${base_url}/company/update-company-field`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      data: {
        companyId: companyId,
        fieldName: fieldName,
        fieldValue: fieldValue
      }
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
        toast.success("Company updated successfully.");
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        toast.error("Failed to update company.");
      });
  };

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: "Company Type",
      selector: (row) => row.type,
      sortable: true
    },
    {
      name: "Created Date",
      selector: (row) =>
        moment.utc(row.createdAt).local().format("DD MMMM YYYY"),
      sortable: true
    },
    {
      name: "Renewal Date",
      selector: (row) =>
        moment.utc(row.subscriptionEndDate).local().format("DD MMMM YYYY"),
      sortable: true
    },
    {
      name: "No of locations",
      selector: (row) => (row.type == "Company" ? row.noOfLocation : "-"),
      sortable: true
    },
    {
      name: "Data storage",
      selector: (row) => row.storageDuration,
      sortable: true
    },
    {
      name: "Geo location",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "geoLocation",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.geoLocation ? true : false} selected>
                {row.geoLocation ? "ON" : "OFF"}
              </option>
              <option value={row.geoLocation ? false : true}>
                {row.geoLocation ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Employee directory",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "employeeDirectory",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.employeeDirectory ? true : false} selected>
                {row.employeeDirectory ? "ON" : "OFF"}
              </option>
              <option value={row.employeeDirectory ? false : true}>
                {row.employeeDirectory ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Visitor verification",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "visitorVerification",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.visitorVerification ? true : false} selected>
                {row.visitorVerification ? "ON" : "OFF"}
              </option>
              <option value={row.visitorVerification ? false : true}>
                {row.visitorVerification ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Valid ID",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "validId",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.validId ? true : false} selected>
                {row.validId ? "ON" : "OFF"}
              </option>
              <option value={row.validId ? false : true}>
                {row.validId ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Accessory",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "accessoryCheck",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.accessoryCheck ? true : false} selected>
                {row.accessoryCheck ? "ON" : "OFF"}
              </option>
              <option value={row.accessoryCheck ? false : true}>
                {row.accessoryCheck ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Card No",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "cardCheck",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.cardCheck ? true : false} selected>
                {row.cardCheck ? "ON" : "OFF"}
              </option>
              <option value={row.cardCheck ? false : true}>
                {row.cardCheck ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Delagate Pass",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "delagateCheck",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.delagateCheck ? true : false} selected>
                {row.delagateCheck ? "ON" : "OFF"}
              </option>
              <option value={row.delagateCheck ? false : true}>
                {row.delagateCheck ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Vendor Pass",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "vendorCheck",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option value={row.vendorCheck ? true : false} selected>
                {row.vendorCheck ? "ON" : "OFF"}
              </option>
              <option value={row.vendorCheck ? false : true}>
                {row.vendorCheck ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "Employee Approval Mail",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "employeeApprovalMailCheck",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option
                value={row.employeeApprovalMailCheck ? true : false}
                selected
              >
                {row.employeeApprovalMailCheck ? "ON" : "OFF"}
              </option>
              <option value={row.employeeApprovalMailCheck ? false : true}>
                {row.employeeApprovalMailCheck ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    },
    {
      name: "End Meeting Notification",
      selector: (row) => (
        <>
          <div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById("hiddenBtn").click();
                // updateSwitch(row._id, "employeeDirectory", e.target.value);
                setFieldData({
                  id: row._id,
                  fieldName: "endMeetingNotification",
                  fieldValue: e.target.value,
                  companyName: row.name
                });
              }}
            >
              <option
                value={row.endMeetingNotification ? true : false}
                selected
              >
                {row.endMeetingNotification ? "ON" : "OFF"}
              </option>
              <option value={row.endMeetingNotification ? false : true}>
                {row.endMeetingNotification ? "OFF" : "ON"}
              </option>
            </select>
          </div>
        </>
      )
    }
  ];
  const options = {
    title: "Onboarded Companies",
    showCsvExport: false,
    showDownload: false,
    showExport: false,
    showLocation: false,
    showSms: false,
    showAddNew: false,
    showFirstHr: false,
    showSecondHr: false,
    showActions: false,
    type: "OnboardedCompanies",
    // type: "Companies",
    columns: columns,
    apiUrl: `${base_url}/company/all-company-details`
  };

  return (
    <>
      <Home propComponent={<Table options={options} />} />
      <ConfirmModal
        msg={`Are you sure you want to update ${fieldData.fieldName} of ${fieldData.companyName} company`}
        updateModal={true}
        updateSwitch={updateSwitch}
        id={fieldData.id}
        fieldName={fieldData.fieldName}
        fieldValue={fieldData.fieldValue}
      />
      <button
        id="hiddenBtn"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
    </>
  );
};

export default CompaniesOnboaredWrapper;
