import { useNavigate } from "react-router-dom";
import classes from "../loginPage/Login.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import appActions from "../../../../ReduxStore/appDataSlice";
import { useState } from "react";
const base_url = process.env.REACT_APP_BASE_URL;

const Phone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (e) => {
    // navigate("/otp-verification");
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter your phone number"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      let reqObj = {
        method: "POST",
        url: `${base_url}/auth/forgot-password`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          phone: values.phone,
        },
      };

      try {
        const response = await axios(reqObj);
        if (response.data.status) {
          dispatch(appActions.setResetPhone(Number(values.phone)));
          localStorage.setItem("resetPhone", values.phone);
          dispatch(appActions.setResetId(response.data.user_id));
          localStorage.setItem("resetId", response.data.user_id);
          setIsLoading(false);
          navigate("/otp-verification");
        } else {
          throw new Error("Something went wrong");
        }
      } catch (error) {
        console.log(error);
        formik.setFieldError("phone", error.response.data.error.message);
        setIsLoading(false);
        return;
      }
    },
  });

  return (
    <div className={classes.loginConntainer}>
      <div className={classes.loginLeft}>
        <div className={classes.loginBg1}>
          <img
            src={require("../../../../assets/img/admin-images/bgLogin_1.png")}
          />
        </div>

        <div className={classes.centerCont}>
          <img
            src={require("../../../../assets/img/admin-images/Digipass_logo.png")}
          />
          <h1>Digi-Pass</h1>
          <p>Visitor Management</p>
          <p>System</p>
        </div>

        <div className={classes.loginBg2}>
          <img
            src={require("../../../../assets/img/admin-images/bgLogin_2.png")}
          />
        </div>
      </div>

      <div className={classes.loginRight}>
        <div className={classes.loginBgR1}>
          <img
            src={require("../../../../assets/img/admin-images/bgLoginR1.png")}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.centerContR}>
            <h2>
              Please enter your phone number to receive a verification code
            </h2>

            <div className={classes.inputDiv}>
              <div className={classes.inputFieldLogo}>
                <img
                  src={require("../../../../assets/img/admin-images/PhoneIcon.png")}
                />
              </div>
              <div className={classes.inputField}>
                <input
                  id="phone"
                  placeholder="Phone No"
                  {...formik.getFieldProps("phone")}
                />
              </div>
            </div>
            <span className={classes.errMsg}>
              {formik.touched.phone && formik.errors.phone ? (
                <div>{formik.errors.phone}</div>
              ) : null}
            </span>

            <button
              type="submit"
              className={classes.loginBtn}
              onClick={handleSubmit}
            >
              {isLoading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </form>
        <div className={classes.loginBgR2}>
          <div className={classes.div1}>
            <p>
              Powered by Fedicoms Pvt Ltd. <br />
              www.fedicoms.com
            </p>
          </div>
          <div className={classes.div2}>
            <img
              src={require("../../../../assets/img/admin-images/bgLoginR2.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Phone;
