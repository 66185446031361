import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useContext } from "react";
import authContext from "../../../../AuthContext/authContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddEmployeeInCompany from "./AddEmployeeInCompany";
import Home from "../../Common/Home/Home";
const base_url = process.env.REACT_APP_BASE_URL;
const AddEmployeeInCompanyWrapper = () => {
  return (
    <>
      <Home propComponent={<AddEmployeeInCompany />} />
    </>
  );
};

export default AddEmployeeInCompanyWrapper;
