import Home from "../Home/Home";
import SmsConfiguration from "./SmsConfiguration";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authContext from "../../../../AuthContext/authContext";
import Loader from "../Loader/Loader";
const base_url = process.env.REACT_APP_BASE_URL;
const SmsConfigWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/sms/settings/details/${activeCompany._id}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        if (res.data) {
          setInitialData({ ...res.data });
        }

        setDomLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Home
        propComponent={
          domLoaded ? (
            <SmsConfiguration initialData={initialData} />
          ) : (
            <Loader centerLoader={"centerLoader"} />
          )
        }
      />
    </>
  );
};

export default SmsConfigWrapper;
