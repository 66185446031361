import { useContext } from "react";
import classes from "./VisitorDetails.module.css";
import authContext from "../../../../AuthContext/authContext";
import { useEffect } from "react";
const moment = require("moment");
const VisitorDetails = ({ initialData }) => {
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const isHidden = userType == "admin" || userType == "subadmin";
  const {
    address,
    companyId,
    companyName,
    createdAt,
    specialVisitorFyi,
    vendorType,
    docFront,
    docBack,
    empDepartment,
    empDesignation,
    empName,
    empPhoneNumber,
    firstName,
    lastName,
    locationId,
    location,
    otpSent,
    actionSms,
    checkOutSms,
    checkedoutBy,
    notifyUserSms,
    notifyVisitorSms,
    phone,
    purposeOfVisit,
    selfie,
    passType,
    updatedAt,
    _id,
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    questionFive,
    answerOne,
    answerTwo,
    answerThree,
    answerFour,
    answerFive,
    checkoutTime,
    cardNo,
    isLaptop,
    laptopSerial,
    isMobile,
    mobileSerial,
    isHardDisk,
    hardDiskSerial,
    isCharger,
    chargerSerial,
    isOtherAccessory,
    otherAccessorySerial,
  } = initialData;

  let showAccessories =
    isLaptop || isMobile || isHardDisk || isCharger || isOtherAccessory;

  const maskText = (text) => {
    text = text.toString();
    if (text.length <= 2) {
      return "*".repeat(text.length);
    }
    const maskedText = text.slice(0, 3) + "*".repeat(text.length - 3);
    return maskedText;
  };

  useEffect(() => {
    if (selfie) {
      document.getElementById("selfie").addEventListener("contextmenu", (e) => {
        if (isHidden) {
          e.preventDefault();
        }
      });
    }

    if (docFront) {
      document
        .getElementById("docFront")
        .addEventListener("contextmenu", (e) => {
          if (isHidden) {
            e.preventDefault();
          }
        });
    }
    if (docBack) {
      document
        .getElementById("docBack")
        .addEventListener("contextmenu", (e) => {
          if (isHidden) {
            e.preventDefault();
          }
        });
    }
  }, [selfie, docFront, docBack]);

  return (
    <>
      <div className={classes.UserDiv}>
        <h3>{firstName}</h3>
        <section className={` ${classes.Container}`}>
          <div className={classes.userImgContainer}>
            <div className={classes.imgDiv}>
              <div className={classes.idCont}>
                <div>
                  {selfie && (
                    <img
                      id="selfie"
                      style={{ filter: isHidden ? "blur(3px)" : "none" }}
                      src={selfie}
                      draggable={isHidden ? "false" : "true"}
                    />
                  )}
                  {!selfie && (
                    <img
                      src={require("../../../../assets/img/admin-images/mountain.png")}
                    />
                  )}
                </div>
                <p>Photo</p>
              </div>

              {docFront && (
                <div className={classes.idCont}>
                  <div>
                    <img
                      id="docFront"
                      style={{ filter: isHidden ? "blur(3px)" : "none" }}
                      src={docFront}
                      draggable={isHidden ? "false" : "true"}
                    />
                  </div>
                  <p>Valid ID (Front)</p>
                </div>
              )}

              {docBack && (
                <div className={classes.idCont}>
                  <div>
                    <img
                      id="docBack"
                      style={{ filter: isHidden ? "blur(3px)" : "none" }}
                      src={docBack}
                      draggable={isHidden ? "false" : "true"}
                    />
                  </div>
                  <p>Valid ID (Back)</p>
                </div>
              )}
            </div>

            <div className={classes.timeDiv}>
              <h2>SMS Notifications</h2>
              {!(passType == "Delegate" || passType == "Vendor") && (
                <p>
                  OTP Notification :{" "}
                  {otpSent && moment.utc(otpSent).format("h:mm A")}
                </p>
              )}
              {!(passType == "Delegate" || passType == "Vendor") && (
                <p>
                  Employee Notification :{" "}
                  {notifyUserSms && moment.utc(notifyUserSms).format("h:mm A")}
                </p>
              )}
              {!(passType == "Delegate" || passType == "Vendor") &&
                notifyVisitorSms && (
                  <p>
                    User FYI SMS :{" "}
                    {notifyVisitorSms &&
                      moment.utc(notifyVisitorSms).format("h:mm A")}
                  </p>
                )}
              {(passType == "Delegate" || passType == "Vendor") && (
                <p>
                  User FYI SMS :{" "}
                  {specialVisitorFyi &&
                    moment(specialVisitorFyi).format("h:mm A")}
                </p>
              )}
              {actionSms && (
                <p>
                  Approval/Disapprove Notification :{" "}
                  {actionSms && moment.utc(actionSms).format("h:mm A")}
                </p>
              )}
              {checkOutSms && (
                <p>
                  End Meeting Notification :{" "}
                  {checkOutSms && moment.utc(checkOutSms).format("h:mm A")}
                </p>
              )}
            </div>
          </div>
          <hr className={classes.hr1} />
          <div className={classes.detailsCont}>
            <div className={classes.detailsDiv}>
              {firstName && (
                <p>First Name : {isHidden ? maskText(firstName) : firstName}</p>
              )}
              {lastName && (
                <p>Last Name : {isHidden ? maskText(lastName) : lastName}</p>
              )}
              {companyName && (
                <p>
                  Company Name :{" "}
                  {isHidden ? maskText(companyName) : companyName}
                </p>
              )}
              {address && (
                <p>Address : {isHidden ? maskText(address) : address}</p>
              )}
              {location && (
                <p>
                  Company Location : {isHidden ? maskText(location) : location}
                </p>
              )}
              {empName && (
                <p>
                  Meeting Person Name : {isHidden ? maskText(empName) : empName}
                </p>
              )}
              {passType == "Vendor" && vendorType && (
                <p>Vendor Type : {vendorType}</p>
              )}
              {empDesignation !== "undefined" && (
                <>
                  {empDesignation && (
                    <p>
                      Meeting Person Designation :{" "}
                      {isHidden ? maskText(empDesignation) : empDesignation}
                    </p>
                  )}
                </>
              )}
              {empDepartment && (
                <p>
                  Meeting Person Department :{" "}
                  {isHidden ? maskText(empDepartment) : empDepartment}
                </p>
              )}
              {passType == "Delegate" || passType == "Vendor" ? (
                <p>
                  Pass created at : {moment(createdAt).format("MMM D, hh:mm A")}
                </p>
              ) : (
                <p>
                  Pass created at :{" "}
                  {moment.utc(otpSent).format("MMM D, hh:mm A")}
                </p>
              )}

              {checkoutTime && (
                <p>
                  End Meeting Time :{" "}
                  {moment
                    .utc(updatedAt)
                    .add(330, "minutes")
                    .format("MMM D, hh:mm A")}
                  {/* {checkOutSms &&
                    moment
                      .utc(updatedAt)
                      .add(330, "minutes")
                      .format("MMM D, hh:mm A")} */}
                </p>
              )}

              {checkedoutBy && <p>Checked Out By : {checkedoutBy}</p>}
              {cardNo && <p>Card No : {cardNo}</p>}
              {showAccessories && (
                <div>
                  <p className="accessories" style={{ textAlign: "left" }}>
                    Accessories
                  </p>
                  {isLaptop && <p>Laptop : {laptopSerial}</p>}
                  {isMobile && <p>Mobile : {mobileSerial}</p>}
                  {isHardDisk && <p>Hard Disk : {hardDiskSerial}</p>}
                  {isCharger && <p>Charger : {chargerSerial}</p>}
                  {isOtherAccessory && <p>Other : {otherAccessorySerial}</p>}
                </div>
              )}
            </div>

            <div className={classes.vl}></div>

            {window.screen.width <= 480 ? <hr className={classes.hr1} /> : ""}
            <div className={classes.questionsDiv}>
              <div>
                {questionOne && (
                  <div>
                    <p>Q1. {questionOne}</p>
                    <div className={classes.answer}>
                      <p>- {answerOne}</p>
                    </div>
                  </div>
                )}
                {questionTwo && <hr className={classes.hr2} />}
                {questionTwo && (
                  <div>
                    <p>Q2. {questionTwo}</p>
                    <div className={classes.answer}>
                      <p>- {answerTwo}</p>
                    </div>
                  </div>
                )}
                {questionThree && <hr className={classes.hr2} />}
                {questionThree && (
                  <div>
                    <p>Q3. {questionThree}</p>
                    <div className={classes.answer}>
                      <p>- {answerThree}</p>
                    </div>
                  </div>
                )}
                {questionFour && <hr className={classes.hr2} />}
                {questionFour && (
                  <div>
                    <p>Q4. {questionFour}</p>
                    <div className={classes.answer}>
                      <p>- {answerFour}</p>
                    </div>
                  </div>
                )}
                {questionFive && <hr className={classes.hr2} />}
                {questionFive && (
                  <div>
                    <p>Q5. {questionFive}</p>
                    <div className={classes.answer}>
                      <p>- {answerFive}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default VisitorDetails;
