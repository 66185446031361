import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passwordId: localStorage.getItem("passwordId"),
  visitorPhone: Number(localStorage.getItem("visitorPhone")),
  visitorFormData: JSON.parse(localStorage.getItem("visitorFormData")) || {},
  companyData: JSON.parse(localStorage.getItem("companyData")),
  multipleLocationId: localStorage.getItem("multipleLocationId"),
  selfie:
    { ...JSON.parse(localStorage.getItem("visitorFormData")) }["SelfiePic"] ||
    "",
  doc1:
    { ...JSON.parse(localStorage.getItem("visitorFormData")) }["docFrontPic"] ||
    "",
  doc2:
    { ...JSON.parse(localStorage.getItem("visitorFormData")) }["docBackPic"] ||
    "",
  isRepeated:
    { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
      "isRepeatedVisitor"
    ] || false,
  activeCamera: "",
  OTP: "",
  OTPTime: localStorage.getItem("OTPTime"),
  facingMode: "user",
  journeyType: localStorage.getItem("journeyType"),
};
export const visitorSlice = createSlice({
  name: "visitorSlice",
  initialState,
  reducers: {
    setVisitorFormData(state, action) {
      state.visitorFormData = action.payload;
    },
    setSelfie(state, action) {
      state.selfie = action.payload;
    },
    setActiveCamera(state, action) {
      state.activeCamera = action.payload;
    },
    setDoc1(state, action) {
      state.doc1 = action.payload;
    },
    setDoc2(state, action) {
      state.doc2 = action.payload;
    },
    setIsRepeated(state, action) {
      state.isRepeated = action.payload;
    },
    setCompanyData(state, action) {
      state.companyData = action.payload;
    },
    setVisitorPhone(state, action) {
      state.visitorPhone = action.payload;
    },
    setMultipleLocationId(state, action) {
      state.multipleLocationId = action.payload;
    },
    setOTP(state, action) {
      state.OTP = action.payload;
    },
    setOTPTime(state, action) {
      state.OTPTime = action.payload;
    },
    setFacingMode(state, action) {
      state.facingMode = action.payload;
    },
    setJourneyType(state, action) {
      state.journeyType = action.payload;
    },
  },
});

const visitorActions = visitorSlice.actions;

export default visitorActions;
