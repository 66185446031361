import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import classes from "../Login/Login.module.css";
import VisitorClasses from "../VisitorPass/VisitorPass.module.css";
import "../../../assets/css/visitor-css/master.css";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");
const EndPass = () => {
  const location = useLocation();
  let Id = location.pathname.substring(9, location.pathname.length);
  const [didPass, setDigiPass] = useState({});
  const [footerText1, setfooterText1] = useState("");
  const [footerText2, setfooterText2] = useState("");
  const [domLoaded, setDomLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${base_url}/visitor/detail/${Id}`).then((res) => {
      setDigiPass(res.data.data);
      // setDomLoaded(true);
      return axios(
        `${base_url}/visitor/company/details/${res.data.data.companyId}`
      ).then((res) => {
        if (res.data.data.footerStatus) {
          setfooterText1(res.data.data.footerTextOne);
          setfooterText2(res.data.data.footerTextTwo);
        }
        // setCompanyData(res.data.data);
        setDomLoaded(true);
      });
    });
  }, []);

  let showAccessories =
    didPass["isLaptop"] ||
    didPass["isMobile"] ||
    didPass["isHardDisk"] ||
    didPass["isCharger"] ||
    didPass["isOtherAccessory"];

  let showValidId = didPass["docFront"] || didPass["docBack"];
  // {
  //   domLoaded && console.log("Date.......", didPass.createdAt);
  // }

  const handleEndMeeting = () => {
    let reqObj = {
      method: "POST",
      url: `${base_url}/visitor/end-meeting`,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        visitorId: Id
      }
    };
    axios(reqObj)
      .then((res) => {
        navigate("/thank-you");
      })
      .catch((err) => {
        console.log("Error checkout...", err);
        toast.dismiss();
        toast.error("Meeting is already ended.", {
          position: "top-center"
        });
      });
    // /visitor/end-meeting
  };

  return (
    <>
      {domLoaded && (
        <div className="wrapper-box z1 digipass-bg-color">
          <div className="container z2" style={{ position: "relative" }}>
            {/* <div className="app-panel"> */}
            <div
              className="bg-special z3"
              // style={{
              //   backgroundColor: "rgb(10 81 170 / 95%)",
              // }}
              style={{
                height: "100%",
                borderRadius: "0",
                background:
                  didPass.passType == "Vendor"
                    ? "linear-gradient(to top, #0f531d, #06521b)"
                    : didPass.passType == "Delegate"
                    ? "linear-gradient(to top, #edd52a, #c99a29)"
                    : "#003ca6"
              }}
            ></div>
            <div className="bg-ellipes-left z3"></div>
            <div className="bg-ellipes-right z3"></div>
            <div className="wrapper-mid z4">
              <div className="logo-div">
                <img
                  src={require("../../../assets/img/visitor-images/images/logo.png")}
                />
              </div>

              <div className="text-logo">
                <h1>Digi-Pass</h1>
                <p>
                  {/* Visitor Management <br></br>System */}
                  {didPass["passType"] == "Vendor" ||
                  didPass["passType"] == "Delegate"
                    ? didPass["passName"]
                    : "VISITOR"}
                </p>
              </div>
              <div className="body-mid">
                <div className="event-div">
                  {didPass.selfie && (
                    <div className="pic-img">
                      <img src={didPass.selfie} />
                    </div>
                  )}
                  {!didPass.selfie && (
                    <div className="pic-img">
                      <img
                        src={require("../../../assets/img/visitor-images/mountain.png")}
                      />
                    </div>
                  )}
                  <div className="content">
                    <p>
                      <strong>{`${didPass.firstName} ${didPass.lastName}`}</strong>
                    </p>
                    <p>{didPass.phone}</p>
                    <p>{didPass.location}</p>
                  </div>
                </div>
                {showValidId && (
                  <div className="event-div">
                    <div className="content">
                      <p className="govtid">Valid ID -</p>
                    </div>
                    {didPass.docFront && (
                      <div className="pic-img">
                        <img src={didPass.docFront} />
                        <p>Front</p>
                      </div>
                    )}
                    <div className="space-div"></div>
                    {didPass.docBack && (
                      <div className="pic-img">
                        <img src={didPass.docBack} />
                        <p>Back</p>
                      </div>
                    )}
                  </div>
                )}
                <div className="event-div">
                  <p>
                    <label>Company Name</label>- {didPass.companyName}
                  </p>
                  {didPass.empName && (
                    <p>
                      <label>Meeting Person</label>- {didPass.empName}
                    </p>
                  )}
                  {didPass.passType !== "Vendor" && (
                    <p>
                      <label>Pass Type</label>- Daily Pass
                    </p>
                  )}
                  {didPass.passType == "Vendor" && (
                    <p>
                      <label>Pass Type</label>- Vendor Pass
                    </p>
                  )}
                  {didPass.purposeOfVisit && (
                    <p>
                      <label>Purpose of visit</label>- {didPass.purposeOfVisit}
                    </p>
                  )}
                  {didPass.passType == "Delegate" && (
                    <p>
                      <label>Valid Up to</label>-{" "}
                      {moment(didPass.visitingDate).format("YYYY-MM-DD")}
                    </p>
                  )}
                  {didPass.passType == "Vendor" && (
                    <p>
                      <label>Valid Up to</label>-{" "}
                      {moment(didPass.validityEndDate).format("YYYY-MM-DD")}
                    </p>
                  )}
                  {!(
                    didPass.passType == "Vendor" ||
                    didPass.passType == "Delegate"
                  ) && (
                    <p>
                      <label>Valid Up to</label>-{" "}
                      {didPass.createdAt.substring(0, 10)}
                    </p>
                  )}

                  {(didPass.passType == "Vendor" ||
                    didPass.passType == "Delegate") && (
                    <p>
                      <label>Created Date</label>-{" "}
                      {moment(didPass.createdAt).format("MMM D, hh:mm A")}
                    </p>
                  )}

                  {!(
                    didPass.passType == "Vendor" ||
                    didPass.passType == "Delegate"
                  ) && (
                    <p>
                      <label>Created Date</label>-{" "}
                      {moment.utc(didPass.otpSent).format("MMM D, hh:mm A")}
                    </p>
                  )}

                  {/* <p>
                    <label>Created Date</label>-{" "} */}
                  {/* {`${moment
                        .utc(didPass.createdAt)
                        .local()
                        .format("YYYY-MM-DD")}, ${moment
                        .utc(didPass.createdAt)
                        .local()
                        .format("h:mm A")}`} */}
                  {/* {moment(didPass.otpSent).format("MMM D, hh:mm A")} */}
                  {/* {moment.utc(didPass.otpSent).format("MMM D, hh:mm A")} */}
                  {/* {moment(didPass.notifyUserSms).format("MMM D, hh:mm A")} */}
                  {/* {moment(didPass.createdAt).format("MMM D, hh:mm A")} */}
                  {/* </p> */}
                  {didPass["cardNo"] && (
                    <p>
                      <label>Card No</label>- {didPass["cardNo"]}
                    </p>
                  )}

                  {showAccessories && (
                    <div>
                      <div className="accessories">Accessories</div>
                      {didPass["isLaptop"] && (
                        <p>
                          <label>Laptop</label>- {didPass["laptopSerial"]}
                        </p>
                      )}
                      {didPass["isMobile"] && (
                        <p>
                          <label>Mobile</label>- {didPass["mobileSerial"]}
                        </p>
                      )}
                      {didPass["isHardDisk"] && (
                        <p>
                          <label>Hard Disk</label>- {didPass["hardDiskSerial"]}
                        </p>
                      )}
                      {didPass["isCharger"] && (
                        <p>
                          <label>Charger</label>- {didPass["chargerSerial"]}
                        </p>
                      )}
                      {didPass["isOtherAccessory"] && (
                        <p>
                          <label>Other</label>-{" "}
                          {didPass["otherAccessorySerial"]}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <button
                    className="btn btn-danger reject-btn"
                    style={{ width: "100%" }}
                    onClick={handleEndMeeting}
                  >
                    End Meeting
                  </button>
                </div>
              </div>

              <footer
                className="visitor-pass-footer"
                style={{ backgroundColor: "transparent" }}
              >
                <p
                  style={{
                    color: didPass["passType"] == "Delegate" ? "#000" : "#fff"
                  }}
                >
                  {footerText1} <br></br> {footerText2}
                </p>
              </footer>
              {/* <Footer /> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default EndPass;
