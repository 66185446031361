import Home from "../../Common/Home/Home";
import EditSubadmin from "./EditSubadmin";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authContext from "../../../../AuthContext/authContext";
const base_url = process.env.REACT_APP_BASE_URL;

const EditSubadminWrapper = () => {
  const activeEditSubadminId = useSelector(
    (state) => state.appData.activeEditSubadminId
  );
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/user/profile/${activeEditSubadminId}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setInitialData({ ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Home propComponent={<EditSubadmin initialData={initialData} />} />
    </>
  );
};

export default EditSubadminWrapper;
