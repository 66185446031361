import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authContext from "../../../../AuthContext/authContext";
import Home from "../Home/Home";
import Loader from "../Loader/Loader";
import SpecialVisitorDetails from "./SpecialVisitorDetails";
const base_url = process.env.REACT_APP_BASE_URL;

const SpecialVisitorDetailsWrapper = () => {
  const activeVisitor = useSelector((state) => state.appData.activeVisitor);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let reqObj = {
      method: "GET",
      url: `${base_url}/visitor/get-special-visitor/${activeVisitor._id}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setInitialData({ ...res.data.data });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Home
        propComponent={
          isLoading ? (
            <Loader centerLoader={"centerLoader"} />
          ) : (
            <SpecialVisitorDetails initialData={initialData} />
          )
        }
      />
    </>
  );
};

export default SpecialVisitorDetailsWrapper;
