import Home from "../../Common/Home/Home";
import Table from "../../Table/Table";

const SingleCompanyWrapper = () => {
  const options = {
    title: "Google",
    showCsvExport: false,
    showDownload: true,
    showExport: false,
    showLocation: true,
    showSms: true,
    showAddNew: false,
    showFirstHr: true,
    showSecondHr: true,
    showActions: true,
    type: "SingleCompany",
  };
  return (
    <>
      <Home propComponent={<Table options={options} />} />
    </>
  );
};

export default SingleCompanyWrapper;
