import { useNavigate } from "react-router-dom";
import classes from "../loginPage/Login.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
const base_url = process.env.REACT_APP_BASE_URL;

const ChangePassword = () => {
  const resetId = useSelector((state) => state.appData.resetId);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (e) => {
    // navigate("/dashboard");
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "password must be 8 characters long")
        .required("Please enter a password"),

      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please enter a password"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      let reqObj = {
        method: "PUT",
        url: `${base_url}/auth/reset-password`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          user_id: resetId,
          password: values.password,
        },
      };

      axios(reqObj)
        .then((res) => {
          if (res.data.status) {
            toast.success("Password reset Successfully");
            setIsLoading(false);
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
          formik.setFieldError(
            "confirm_password",
            err.response.data.error.message
          );
          toast.error("Something went wrong");
          setIsLoading(false);
          return;
        });
    },
  });

  return (
    <div className={classes.loginConntainer}>
      <div className={classes.loginLeft}>
        <div className={classes.loginBg1}>
          <img
            src={require("../../../../assets/img/admin-images/bgLogin_1.png")}
          />
        </div>

        <div className={classes.centerCont}>
          <img
            src={require("../../../../assets/img/admin-images/Digipass_logo.png")}
          />
          <h1>Digi-Pass</h1>
          <p>Visitor Management</p>
          <p>System</p>
        </div>

        <div className={classes.loginBg2}>
          <img
            src={require("../../../../assets/img/admin-images/bgLogin_2.png")}
          />
        </div>
      </div>

      <div className={classes.loginRight}>
        <div className={classes.loginBgR1}>
          <img
            src={require("../../../../assets/img/admin-images/bgLoginR1.png")}
          />
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className={classes.centerContR}>
            <h2>Please Enter Your Password</h2>

            <div className={classes.inputDiv}>
              <div className={classes.inputFieldLogo}>
                <img
                  src={require("../../../../assets/img/admin-images/lockIcon.png")}
                />
              </div>
              <div className={classes.inputField}>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  {...formik.getFieldProps("password")}
                />
              </div>
            </div>

            <span className={classes.errMsg}>
              {formik.touched.password && formik.errors.password ? (
                <div>{formik.errors.password}</div>
              ) : null}
            </span>

            <div className={classes.inputDiv}>
              <div className={classes.inputFieldLogo}>
                <img
                  src={require("../../../../assets/img/admin-images/lockIcon.png")}
                />
              </div>
              <div className={classes.inputField}>
                <input
                  type="password"
                  id="confirm_password"
                  placeholder="Confirm Password"
                  {...formik.getFieldProps("confirm_password")}
                />
              </div>
            </div>
            <span className={classes.errMsg}>
              {formik.touched.confirm_password &&
              formik.errors.confirm_password ? (
                <div>{formik.errors.confirm_password}</div>
              ) : null}
            </span>

            <button
              type="submit"
              className={classes.loginBtn}
              onClick={handleSubmit}
            >
              {isLoading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>

        <div className={classes.loginBgR2}>
          <div className={classes.div1}>
            <p>
              Powered by Fedicoms Pvt Ltd. <br />
              www.fedicoms.com
            </p>
          </div>
          <div className={classes.div2}>
            <img
              src={require("../../../../assets/img/admin-images/bgLoginR2.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
