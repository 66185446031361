import classes from "../Login/Login.module.css";
import otpClass from "./otp.module.css";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import "../../../assets/css/visitor-css/master.css";
import visitorActions from "../../../ReduxStore/visitorSlice";

const base_url = process.env.REACT_APP_BASE_URL;
const VisitorOtpVerification = () => {
  const [otp, setOtp] = useState();
  const [isLoading, setLoading] = useState(false);
  const [newWidth, setNewWidth] = useState("80");
  const [newHeight, setNewHeight] = useState("80");
  const visitorPhone = useSelector((state) => state.visitorData.visitorPhone);
  const companyData = useSelector((state) => state.visitorData.companyData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  const formik = useFormik({
    initialValues: {
      otpCode: Number(useSelector((state) => state.visitorData.OTP)),
      // otpCode: Number(sessionStorage.getItem("OTP")),
      termsCheck: false
    },
    validationSchema: Yup.object({
      otpCode: Yup.string()
        .length(4, "Otp must have 4 digits")
        .required("Please enter your phone number"),
      termsCheck: Yup.bool().oneOf(
        [true],
        "You need to accept the terms and conditions"
      )
    }),
    onSubmit: (values) => {
      setLoading(true);
      let reqObj = {
        method: "POST",
        url: `${base_url}/visitor/verification-otp`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          phone: Number(visitorPhone),
          // phone: visitorPhone,
          otp: Number(values.otpCode)
        }
      };

      axios(reqObj)
        .then((res) => {
          if (res.data.status == false) {
            throw new Error("Invalid OTP");
          }
          navigate("/visitor-details");
          setLoading(true);
        })
        .catch((err) => {
          formik.setFieldError(
            "termsCheck",
            err.toString() || "Something went wrong"
          );
          setLoading(false);
        });
    }
  });

  var image = new Image();
  image.src = companyData?.logo;
  image.onload = function () {
    // Get the height and width
    var height = image.naturalHeight;
    var width = image.naturalWidth;

    console.log("Image height:", height);
    console.log("Image width:", width);
    const aspectRatio = width / height;

    if (aspectRatio > 1.7) {
      setNewWidth("160");
      setNewHeight("60");
    } else {
      setNewWidth("80");
      setNewHeight("80");
    }
  };

  return (
    <>
      <div className="wrapper-box z1">
        <div className="container z2" style={{ position: "relative" }}>
          {/* <div className="app-panel"> */}
          <div
            className="bg-special z3"
            // style={{
            //   backgroundColor: "rgb(10 81 170 / 95%)",
            // }}
          ></div>
          <div className="bg-ellipes-left z3"></div>
          <div className="bg-ellipes-right z3"></div>
          <div className="wrapper-mid z4">
            <div className="logo-div">
              <img
                src={require("../../../assets/img/visitor-images/images/logo.png")}
              />
            </div>

            <div className="text-logo">
              <h1>Digi-Pass</h1>
              <p style={{ fontSize: "21px", textTransform: "none" }}>
                Visitor Management System
              </p>
            </div>
            <div className="logo-res">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <img
                  className="logo-parter-img"
                  style={{
                    width: `${newWidth}px`,
                    height: `${newHeight}px`,
                    display: "inline-block",
                    margin: "0 auto"
                  }}
                  src={companyData?.logo}
                />
              </div>
            </div>
            <div className="input-panel">
              <h2>Please enter 4 digit OTP to verify</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group otp-wrapper">
                  <OtpInput
                    className="otp-input"
                    id="otpCode"
                    name="otpCode"
                    onChange={(num) => {
                      setOtp(num);
                      formik.setFieldValue("otpCode", num);
                      // sessionStorage.setItem("OTP", num);
                      dispatch(visitorActions.setOTP(num));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.otpCode}
                    shouldAutoFocus={true}
                    containerStyle={` ${otpClass.otpDiv}`}
                    inputStyle={otpClass.otpField}
                    numInputs={4}
                    isInputNum={true}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.otpCode && formik.errors.otpCode ? (
                      <div>{formik.errors.otpCode}</div>
                    ) : null}
                  </span>

                  <div className={otpClass.terms}>
                    <input
                      type="checkbox"
                      id="termsCheck"
                      name="termsCheck"
                      defaultChecked={formik.values.termsCheck}
                      {...formik.getFieldProps("termsCheck")}
                    />
                    <span>
                      <span style={{ color: "#FFFFFF" }}> I accept the</span>{" "}
                      <span
                        style={{ color: "#3EACE5" }}
                        onClick={() => navigate("/visitor-terms")}
                      >
                        <span>Terms & Conditions</span>
                      </span>
                    </span>
                  </div>
                  <span className={classes.errMsg}>
                    {formik.touched.termsCheck && formik.errors.termsCheck ? (
                      <div>{formik.errors.termsCheck}</div>
                    ) : null}
                  </span>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    value="Submit"
                    className="btn btn-primary submit-btn"
                  >
                    {isLoading ? (
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
            <Footer />
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default VisitorOtpVerification;
