import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import classes from "../Login/Login.module.css";
import VisitorClasses from "./VisitorPass.module.css";
import "../../../assets/css/visitor-css/master.css";
import moment from "moment";

const base_url = process.env.REACT_APP_BASE_URL;
const VisitorPass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let Id;
  // let footerText1 = "";
  // let footerText2 = "";
  if (location.pathname.includes("digipass")) {
    Id = location.pathname.substring(10, location.pathname.length);
  } else {
    Id = location.pathname.substring(6, location.pathname.length);
  }

  const [didPass, setDigiPass] = useState({});
  const [footerText1, setfooterText1] = useState("");
  const [footerText2, setfooterText2] = useState("");
  const [domLoaded, setDomLoaded] = useState(false);
  const path = location.pathname;
  let showApprove = !path.includes("digipass");

  const [isDevToolsOpened, setIsDevToolsOpened] = useState(false);
  /*
  useEffect(() => {
    const handleResize = () => {
      if (window.outerHeight - window.innerHeight > 100) {
        setIsDevToolsOpened(true);
        alert("Dev tool opened");
      } else {
        setIsDevToolsOpened(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Check if dev tools are already open after the initial render
    if (window.outerHeight - window.innerHeight > 100) {
      setIsDevToolsOpened(true);
      console.log("Dev tool opened");
      alert("Dev tool opened");
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  */

  useEffect(() => {
    axios.get(`${base_url}/visitor/detail/${Id}`).then((res) => {
      setDigiPass(res.data.data);
      console.log("Digipass Data", res.data.data);
      return axios(
        `${base_url}/visitor/company/details/${res.data.data.companyId}`
      ).then((res) => {
        if (res.data.data.footerStatus) {
          setfooterText1(res.data.data.footerTextOne);
          setfooterText2(res.data.data.footerTextTwo);
        }
        // setCompanyData(res.data.data);
        setDomLoaded(true);
      });
    });
  }, []);

  const handleApproveReject = (status) => {
    let reqObj = {
      method: "POST",
      url: `${base_url}/visitor/emp-decision`,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        visitorId: Id,
        status: status
      }
    };
    axios(reqObj)
      .then((res) => {
        navigate("/thank-you");
      })
      .catch((err) => {
        console.log(err);
      });
    // /visitor/end-meeting
    if (status == "rejected") {
      axios
        .put(`${base_url}/visitor/check-out/${Number(didPass.phone)}`, {
          checkedoutBy: `${didPass.empName}(Meeting person rejected.)`
        })
        .then((visitor) => {});
    }
  };

  let showAccessories =
    didPass["isLaptop"] ||
    didPass["isMobile"] ||
    didPass["isHardDisk"] ||
    didPass["isCharger"] ||
    didPass["isOtherAccessory"];

  let showValidId = didPass["docFront"] || didPass["docBack"];

  return (
    <>
      {domLoaded && (
        <div className="wrapper-box z1 digipass-bg-color">
          <div className="container z2" style={{ position: "relative" }}>
            {/* <div className="app-panel"> */}
            {/* <div
              className="bg-special z3"
              style={{
                backgroundColor: "#003ca6"
              }}
            ></div> */}
            <div
              className="bg-special z3"
              style={{
                backgroundColor: "#003ca6",
                borderRadius: 0,
                height: "100%"
              }}
            ></div>
            <div className="bg-ellipes-left z3"></div>
            <div className="bg-ellipes-right z3"></div>
            <div className="wrapper-mid z4">
              <div className="logo-div">
                <img
                  src={require("../../../assets/img/visitor-images/images/logo.png")}
                />
              </div>

              <div className="text-logo">
                <h1>Digi-Pass</h1>
                <p>
                  {/* Visitor Management <br></br>System */}
                  VISITOR
                </p>
              </div>
              <div className="body-mid">
                <div className="event-div">
                  {didPass.selfie && (
                    <div className="pic-img">
                      <img src={didPass.selfie} />
                    </div>
                  )}
                  {!didPass.selfie && (
                    <div className="pic-img">
                      <img
                        src={require("../../../assets/img/visitor-images/mountain.png")}
                      />
                    </div>
                  )}
                  <div className="content">
                    <p>
                      <strong>{`${didPass.firstName} ${didPass.lastName}`}</strong>
                    </p>
                    <p>{didPass.phone}</p>
                    <p>{didPass.location}</p>
                  </div>
                </div>
                {showValidId && (
                  <div className="event-div">
                    <div className="content">
                      <p className="govtid">Valid ID -</p>
                    </div>
                    {didPass.docFront && (
                      <div className="pic-img">
                        <img src={didPass.docFront} />
                        <p>Front</p>
                      </div>
                    )}
                    <div className="space-div"></div>
                    {didPass.docBack && (
                      <div className="pic-img">
                        <img src={didPass.docBack} />
                        <p>Back</p>
                      </div>
                    )}
                  </div>
                )}
                <div className="event-div">
                  <p>
                    <label>Company Name</label>- {didPass.companyName}
                  </p>
                  <p>
                    <label>Meeting Person</label>- {didPass.empName}
                  </p>
                  <p>
                    <label>Pass Type</label>- Daily Pass
                  </p>
                  <p>
                    <label>Purpose of visit</label>- {didPass.purposeOfVisit}
                  </p>
                  <p>
                    <label>Valid Up to</label>-{" "}
                    {didPass.createdAt.substring(0, 10)}
                  </p>
                  <p>
                    <label>Created Date</label>-{" "}
                    {/* {moment(didPass.otpSent).format("MMM D, hh:mm A")} */}
                    {moment.utc(didPass.otpSent).format("MMM D, hh:mm A")}
                    {/* {moment(didPass.notifyUserSms).format("MMM D, hh:mm A")} */}
                    {/* {moment(didPass.createdAt).format("MMM D, hh:mm A")} */}
                  </p>
                  {didPass["cardNo"] && (
                    <p>
                      <label>Card No</label>- {didPass["cardNo"]}
                    </p>
                  )}

                  {showAccessories && (
                    <div>
                      <div className="accessories">Accessories</div>
                      {didPass["isLaptop"] && (
                        <p>
                          <label>Laptop</label>- {didPass["laptopSerial"]}
                        </p>
                      )}
                      {didPass["isMobile"] && (
                        <p>
                          <label>Mobile</label>- {didPass["mobileSerial"]}
                        </p>
                      )}
                      {didPass["isHardDisk"] && (
                        <p>
                          <label>Hard Disk</label>- {didPass["hardDiskSerial"]}
                        </p>
                      )}
                      {didPass["isCharger"] && (
                        <p>
                          <label>Charger</label>- {didPass["chargerSerial"]}
                        </p>
                      )}
                      {didPass["isOtherAccessory"] && (
                        <p>
                          <label>Other</label>-{" "}
                          {didPass["otherAccessorySerial"]}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                {showApprove && (
                  <div className="form-group">
                    <button
                      className="btn btn-danger reject-btn"
                      onClick={() => handleApproveReject("rejected")}
                    >
                      Reject
                    </button>
                    <button
                      className="btn btn-success success-btn"
                      onClick={() => handleApproveReject("approved")}
                    >
                      Approve
                    </button>
                  </div>
                )}
              </div>
              <footer
                className="visitor-pass-footer"
                style={{ backgroundColor: "transparent" }}
              >
                <p style={{ color: "#fff" }}>
                  {footerText1} <br></br> {footerText2}
                </p>
              </footer>
              {/* <Footer /> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default VisitorPass;
