import AddEmployeeDirectory from "./AddEmployeeDirectory";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useContext } from "react";
import authContext from "../../../../AuthContext/authContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Home from "../../Common/Home/Home";
const base_url = process.env.REACT_APP_BASE_URL;
const AddEmployeeDirectoryWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const [isLoaded, setIsLoaded] = useState(false);
  const ctx = useContext(authContext);
  const navigate = useNavigate();
  useEffect(() => {
    // setIsloading(false);
    let reqObj = {
      method: "GET",
      url: `${base_url}/company/details/${activeCompany._id}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${ctx.token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        if (!res.data.employeeDirectory) {
          navigate("/dashboard");
          toast.error("Employee directory if OFF");
        }
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  }, []);
  return (
    <>
      <Home propComponent={<AddEmployeeDirectory />} />
    </>
  );
};

export default AddEmployeeDirectoryWrapper;
