import Home from "../../Common/Home/Home";
import AddNewSubAdmin from "./AddNewSubAdmin";

const AddSubAdminWrapper = () => {
  return (
    <>
      <Home propComponent={<AddNewSubAdmin />} />
    </>
  );
};

export default AddSubAdminWrapper;
