import { useSelector } from "react-redux";
import classes from "./Footer.module.css";

const Footer = ({ footerColor }) => {
  const companyData = useSelector((state) => state.visitorData.companyData);
  let footerText1 = "";
  let footerText2 = "";
  if (companyData) {
    footerText1 = companyData.footerTextOne;
    footerText2 = companyData.footerTextTwo;
  }
  return (
    <>
      <footer
      // style={{ backgroundColor: footerColor == "black" ? "" : "#003ca6" }}
      >
        <p style={{ color: footerColor == "black" ? "black" : "#ffffff" }}>
          {footerText1} <br></br> {footerText2}
          {/* Test value <br></br> Test footer */}
        </p>
      </footer>
    </>
  );
};

export default Footer;
