import classes from "../Home/Home.module.css";
const Footer = () => {
  return (
    <>
      <div className={classes.footer}>
        <div>
          <p>Powered by Fedicoms Pvt Ltd.</p>
          <p>www.fedicoms.com</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
