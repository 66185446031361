import "./App.css";
import "semantic-ui-css/semantic.min.css";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate
} from "react-router-dom";

//Visitor

// import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { useEffect } from "react";
import Login from "./components/admin/Common/loginPage/Login";
import authContext from "./AuthContext/authContext";
import Phone from "./components/admin/Common/phoneNo/Phone";
import Home from "./components/admin/Common/Home/Home";
import OTPVerification from "./components/admin/Common/OtpVerification/OTPVerification";
import ChangePassword from "./components/admin/Common/ChangePassword/ChangePassword";
import DashboardWrapper from "./components/admin/Dashboard/DashboardWrapper";
import CompaniesOnboaredWrapper from "./components/admin/Dashboard/CompaniesOnboarded/CompaniesOnboaredWrapper";
import CompaniesWrapper from "./components/admin/Company/CompaniesWrapper/CompaniesWrapper";
import SingleCompanyWrapper from "./components/admin/Company/SingleCompanyWrapper/SingleCompanyWrapper";
import VisitorDetailsWrapper from "./components/admin/Common/VisitorDetails/VisitorDetailsWrapper";
import LocationWrapper from "./components/admin/Company/LocationWrapper/LocationWrapper";
import EmployeeDirectoryWrapper from "./components/admin/Employee/EmployeeDirectoryWrapper/EmployeeDirectoryWrapper";
import CompanyEmployeeDirectory from "./components/admin/Building/CompanyEmployeeDirectory/CompanyEmployeeDirectory";
import VisitorWrapper from "./components/admin/Common/VisitorWrapper/VisitorWrapper";
import BuildingWrapper from "./components/admin/Building/BuildingWrapper/BuildingWrapper";
import AddEmployeeDirectoryWrapper from "./components/admin/Employee/AddEmployeeDirectory/AddEmployeeDirectoryWrapper";
import AddEmployeeInCompanyWrapper from "./components/admin/Employee/AddEmployeeInCompany/AddEmployeeInCompanyWrapper";
import EditEmployeeWrapper from "./components/admin/Employee/EditEmployee/EditEmployeeWrapper";
import AddNewCompanyWrapper from "./components/admin/Company/AddNewCompany/AddNewCompanyWrapper";
import EditNewCompanyWrapper from "./components/admin/Company/EditNewCompany/EditNewCompanyWrapper";
import EditNewBuildingWrapper from "./components/admin/Building/EditNewBuilding/EditNewBuildingWrapper";
import AddNewLocationWrapper from "./components/admin/Company/AddNewLocation/AddNewLocationWrapper";
import AddCompanyInBuildingWrapper from "./components/admin/Building/AddCompanyInBuilding/AddCompanyInBuildingWrapper";
import EditLocationWrapper from "./components/admin/Company/EditLocation/EditLocationWrapper";
import AddNewBuildingWrapper from "./components/admin/Building/AddNewBuilding/AddNewBuildingWrapper";
import AddSubAdminWrapper from "./components/admin/Subadmin/AddNewSubAdmin/AddSubAdminWrapper";
import EditSubadminWrapper from "./components/admin/Subadmin/EditSubadmin/EditSubadminWrapper";
import SmsConfigWrapper from "./components/admin/Common/SmsConfiguration/SmsConfigWrapper";
import SubadminWrapper from "./components/admin/Subadmin/SubadminWrapper/SubadminWrapper";
import ProfileWrapper from "./components/admin/Common/Profile/ProfileWrapper";
import TermsWrapper from "./components/admin/Common/Terms/TermsWrapper";

// Visitor
import VisitorLogin from "./components/visitor/Login/Login";
import VisitorOtpVerification from "./components/visitor/OtpVerification/OtpVerification";
import UserForm from "./components/visitor/UserForm/UserForm";
import Selfie from "./components/visitor/Selfie/Selfie";
import CameraComponent from "./components/visitor/Camera/Camera";
import GovtId from "./components/visitor/GovtId/GovtId";
import CovidQuestions from "./components/visitor/CovidQuestions/CovidQuestions";
import VisitorPass from "./components/visitor/VisitorPass/VisitorPass";
import EndPass from "./components/visitor/EndPass/EndPass";
import Terms from "./components/visitor/Terms/Terms";
import Thankyou from "./components/visitor/Thankyou/Thankyou";
import ToastMsg from "./components/admin/Common/ToastMsg/ToastMsg";
import PrivateRoutes from "./components/admin/Common/PrivateRoutes/PrivateRoutes";
import ActiveVisitorWrapper from "./components/admin/Common/ActiveVisitorWrapper/ActiveVisitorWrapper";
import CheckInCheckOut from "./components/visitor/CheckInCheckOut/CheckInCheckOut";
import SpecialVisitorWrapper from "./components/admin/Common/SpecialVisitorWrapper/SpecialVisitorWrapper";
import SpecialVisitorDetailsWrapper from "./components/admin/Common/SpecialVisitorDetails/SpecialVisitorDetailsWrapper";
import CreatePassWrapper from "./components/admin/Common/CreatePass/CreatePassWrapper";
import EditSpecialPassWrapper from "./components/admin/Common/EditSpecialPass/EditSpecialPassWrapper";
import SpecialVisitorPass from "./components/visitor/VisitorPass/SpecialPass";
import SpecialVisitorPassByAdmin from "./components/visitor/VisitorPass/SepecialPassByAdmin";
import MailConfigWrapper from "./components/admin/Common/MailConfiguration/MailConfigWrapper";
function App() {
  // const navigate = useNavigate();
  const ctx = useContext(authContext);
  const isLoggedIn = ctx.isLoggedIn;

  useEffect(() => {
    // Set the --vh custom property to the height of the viewport
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    setVh();
    // Update the --vh custom property on window resize
    window.addEventListener("resize", setVh);
    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  return (
    // <div style={{ height: "100%" }}>
    <div className="AppContainer">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/phone" element={<Phone />} />
          <Route path="/otp-verification" element={<OTPVerification />} />
          <Route path="/change-password" element={<ChangePassword />} />

          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<Home />} />
            <Route path="/dashboard" element={<DashboardWrapper />} />
            <Route
              path="/dashboard/companyOnboarded"
              element={<CompaniesOnboaredWrapper />}
            />
            <Route path="/companies" element={<CompaniesWrapper />} />
            <Route
              path="/companies/company"
              element={<SingleCompanyWrapper />}
            />
            <Route
              path="/companies/company/visitor-details"
              element={<VisitorDetailsWrapper />}
            />
            <Route
              path="/companies/company/special-visitor-details"
              element={<SpecialVisitorDetailsWrapper />}
            />
            <Route
              path="/employeeDirectory"
              element={<EmployeeDirectoryWrapper />}
            />
            <Route
              path="/CompanyEmployeeDirectory"
              element={<CompanyEmployeeDirectory />}
            />
            <Route path="/location" element={<LocationWrapper />} />
            <Route path="/building" element={<BuildingWrapper />} />
            <Route path="/visitors-list" element={<VisitorWrapper />} />
            <Route
              path="/special-visitors-list"
              element={<SpecialVisitorWrapper />}
            />
            <Route
              path="/create-special-pass"
              element={<CreatePassWrapper />}
            />
            <Route
              path="/edit-special-visitor"
              element={<EditSpecialPassWrapper />}
            />
            <Route
              path="/active-visitors-list"
              element={<ActiveVisitorWrapper />}
            />
            <Route
              path="/addEmployeeDirectory"
              element={<AddEmployeeDirectoryWrapper />}
            />
            <Route
              path="/addEmployeeInCompany"
              element={<AddEmployeeInCompanyWrapper />}
            />
            <Route path="/editEmployee" element={<EditEmployeeWrapper />} />
            <Route path="/addCompany" element={<AddNewCompanyWrapper />} />
            <Route path="/editCompany" element={<EditNewCompanyWrapper />} />
            <Route path="/editBuilding" element={<EditNewBuildingWrapper />} />
            <Route path="/addLocation" element={<AddNewLocationWrapper />} />
            <Route
              path="/addCompanyInBuilding"
              element={<AddCompanyInBuildingWrapper />}
            />
            <Route path="/editLocation" element={<EditLocationWrapper />} />
            <Route path="/addBuilding" element={<AddNewBuildingWrapper />} />
            <Route path="/addSubadmin" element={<AddSubAdminWrapper />} />
            <Route path="/editSubadmin" element={<EditSubadminWrapper />} />
            <Route path="/smsSetting" element={<SmsConfigWrapper />} />
            <Route path="/mailSetting" element={<MailConfigWrapper />} />
            <Route path="/sub-admin" element={<SubadminWrapper />} />
            <Route path="/profile" element={<ProfileWrapper />} />
            <Route path="/terms" element={<TermsWrapper />} />
          </Route>

          {/* Visitor App */}

          <Route path="/visitor/:id" element={<CheckInCheckOut />} />
          <Route path="/visitor-phone" element={<VisitorLogin />} />

          <Route
            path="/visitor-otp-verification"
            element={<VisitorOtpVerification />}
          />

          <Route path="/visitor-details" element={<UserForm />} />
          <Route path="/selfie" element={<Selfie />} />
          <Route path="/camera" element={<CameraComponent />} />
          <Route path="/govt-id" element={<GovtId />} />
          <Route path="/covid-questions" element={<CovidQuestions />} />
          <Route path="/digipass/:id" element={<VisitorPass />} />
          <Route
            path="/special-digipass/:id"
            element={<SpecialVisitorPass />}
          />
          <Route
            path="/special-pass/:id"
            element={<SpecialVisitorPassByAdmin />}
          />
          <Route path="/user/:id" element={<VisitorPass />} />
          <Route path="/meeting/:id" element={<EndPass />} />
          <Route path="/visitor-terms" element={<Terms />} />
          <Route path="/thank-you" element={<Thankyou />} />
        </Routes>
        <ToastMsg />
      </BrowserRouter>
      {/* <Login /> */}
      {/* <Phone /> */}
      {/* <OTPVerification /> */}
      {/* <ChangePassword /> */}
      {/* <Home /> */}
      {/* <Table /> */}
      {/* </div> */}
    </div>
  );
}

export default App;
