import classes from "./SmsConfiguration.module.css";
import btnClasses from "../../Employee/AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useContext } from "react";
import axios from "axios";
import authContext from "../../../../AuthContext/authContext";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;

const SmsConfiguration = ({ initialData }) => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [loading, setLoading] = useState(false);
  let initialValues;
  if (initialData) {
    initialValues = {
      otp_api_url: initialData.apiUrl,
      otp_access_code: initialData.accessCode,
      forgot_password_sms_type: initialData.forgotPwd.smsType,
      forgot_password_otp_template_id: initialData.forgotPwd.templateId,
      forgot_password_otp_sender_id: initialData.forgotPwd.senderId,
      forgot_password_sms_content: initialData.forgotPwd.smscontent,
      visitor_mobile_sms_type: initialData.visitorOtp.smsType,
      visitor_mobile_otp_template_id: initialData.visitorOtp.templateId,
      visitor_mobile_otp_sender_id: initialData.visitorOtp.senderId,
      visitor_mobile_sms_content: initialData.visitorOtp.smscontent,
      employee_approval_sms_type: initialData.empApproval.smsType,
      employee_approval_otp_template_id: initialData.empApproval.templateId,
      employee_approval_otp_sender_id: initialData.empApproval.senderId,
      employee_approval_sms_content: initialData.empApproval.smscontent,
      visitor_approval_sms_type: initialData.visitorApproval.smsType,
      visitor_approval_otp_template_id: initialData.visitorApproval.templateId,
      visitor_approval_otp_sender_id: initialData.visitorApproval.senderId,
      visitor_approval_sms_content: initialData.visitorApproval.smscontent,
      employee_fyi_sms_type: initialData.empFyi.smsType,
      employee_fyi_otp_template_id: initialData.empFyi.templateId,
      employee_fyi_otp_sender_id: initialData.empFyi.senderId,
      employee_fyi_sms_content: initialData.empFyi.smscontent,
      employee_reject_sms_type: initialData.empReject.smsType,
      employee_reject_otp_template_id: initialData.empReject.templateId,
      employee_reject_otp_sender_id: initialData.empReject.senderId,
      employee_reject_sms_content: initialData.empReject.smscontent,
      visitor_end_sms_type: initialData.visitorEnd.smsType,
      visitor_end_otp_template_id: initialData.visitorEnd.templateId,
      visitor_end_otp_sender_id: initialData.visitorEnd.senderId,
      visitor_end_sms_content: initialData.visitorEnd.smscontent,
    };
  } else {
    initialValues = {
      otp_api_url: "",
      otp_access_code: "",
      forgot_password_sms_type: "",
      forgot_password_otp_template_id: "",
      forgot_password_otp_sender_id: "",
      forgot_password_sms_content: "",
      visitor_mobile_sms_type: "",
      visitor_mobile_otp_template_id: "",
      visitor_mobile_otp_sender_id: "",
      visitor_mobile_sms_content: "",
      employee_approval_sms_type: "",
      employee_approval_otp_template_id: "",
      employee_approval_otp_sender_id: "",
      employee_approval_sms_content: "",
      visitor_approval_sms_type: "",
      visitor_approval_otp_template_id: "",
      visitor_approval_otp_sender_id: "",
      visitor_approval_sms_content: "",
      employee_fyi_sms_type: "",
      employee_fyi_otp_template_id: "",
      employee_fyi_otp_sender_id: "",
      employee_fyi_sms_content: "",
      employee_reject_sms_type: "",
      employee_reject_otp_template_id: "",
      employee_reject_otp_sender_id: "",
      employee_reject_sms_content: "",
      visitor_end_sms_type: "",
      visitor_end_otp_template_id: "",
      visitor_end_otp_sender_id: "",
      visitor_end_sms_content: "",
    };
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      otp_api_url: Yup.string().required("Please enter otp api url"),
      otp_access_code: Yup.string().required("Please enter otp access code"),
      forgot_password_sms_type: Yup.string().required("Please select sms type"),
      forgot_password_otp_template_id: Yup.string().required(
        "Please enter otp template id"
      ),
      forgot_password_otp_sender_id: Yup.string().required(
        "Please enter otp sender id"
      ),
      forgot_password_sms_content: Yup.string().required(
        "Please enter sms content"
      ),
      visitor_mobile_sms_type: Yup.string().required("Please select sms type"),
      visitor_mobile_otp_template_id: Yup.string().required(
        "Please enter otp template id"
      ),
      visitor_mobile_otp_sender_id: Yup.string().required(
        "Please enter otp sender id"
      ),
      visitor_mobile_sms_content: Yup.string().required(
        "Please enter sms content"
      ),
      employee_approval_sms_type: Yup.string().required(
        "Please select sms type"
      ),
      employee_approval_otp_template_id: Yup.string().required(
        "Please enter otp template id"
      ),
      employee_approval_otp_sender_id: Yup.string().required(
        "Please enter otp sender id"
      ),
      employee_approval_sms_content: Yup.string().required(
        "Please enter sms content"
      ),
      visitor_approval_sms_type: Yup.string().required(
        "Please select sms type"
      ),
      visitor_approval_otp_template_id: Yup.string().required(
        "Please enter otp template id"
      ),
      visitor_approval_otp_sender_id: Yup.string().required(
        "Please enter otp sender id"
      ),
      visitor_approval_sms_content: Yup.string().required(
        "Please enter sms content"
      ),
      employee_fyi_sms_type: Yup.string().required("Please select sms type"),
      employee_fyi_otp_template_id: Yup.string().required(
        "Please enter otp template id"
      ),
      employee_fyi_otp_sender_id: Yup.string().required(
        "Please enter otp sender id"
      ),
      employee_fyi_sms_content: Yup.string().required(
        "Please enter sms content"
      ),
      employee_reject_sms_type: Yup.string().required("Please select sms type"),
      employee_reject_otp_template_id: Yup.string().required(
        "Please enter otp template id"
      ),
      employee_reject_otp_sender_id: Yup.string().required(
        "Please enter otp sender id"
      ),
      employee_reject_sms_content: Yup.string().required(
        "Please enter sms content"
      ),
      visitor_end_sms_type: Yup.string().required("Please select sms type"),
      visitor_end_otp_template_id: Yup.string().required(
        "Please enter otp template id"
      ),
      visitor_end_otp_sender_id: Yup.string().required(
        "Please enter otp sender id"
      ),
      visitor_end_sms_content: Yup.string().required(
        "Please enter sms content"
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);

      let reqObj = {
        method: "POST",
        url: `${base_url}/sms/settings/save`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          apiUrl: values.otp_api_url,
          accessCode: values.otp_access_code,
          forgotPwd: {
            templateId: values.forgot_password_otp_template_id,
            smsType: values.forgot_password_sms_type,
            senderId: values.forgot_password_otp_sender_id,
            smscontent: values.forgot_password_sms_content,
          },
          visitorOtp: {
            templateId: values.visitor_mobile_otp_template_id,
            smsType: values.visitor_mobile_sms_type,
            senderId: values.visitor_mobile_otp_sender_id,
            smscontent: values.visitor_mobile_sms_content,
          },
          empApproval: {
            templateId: values.employee_approval_otp_template_id,
            smsType: values.employee_approval_sms_type,
            senderId: values.employee_approval_otp_sender_id,
            smscontent: values.employee_approval_sms_content,
          },
          visitorApproval: {
            templateId: values.visitor_approval_otp_template_id,
            smsType: values.visitor_approval_sms_type,
            senderId: values.visitor_approval_otp_sender_id,
            smscontent: values.visitor_approval_sms_content,
          },
          empFyi: {
            templateId: values.employee_fyi_otp_template_id,
            smsType: values.employee_fyi_sms_type,
            senderId: values.employee_fyi_otp_sender_id,
            smscontent: values.employee_fyi_sms_content,
          },
          empReject: {
            templateId: values.employee_reject_otp_template_id,
            smsType: values.employee_reject_sms_type,
            senderId: values.employee_reject_otp_sender_id,
            smscontent: values.employee_reject_sms_content,
          },
          visitorEnd: {
            templateId: values.visitor_end_otp_template_id,
            smsType: values.visitor_end_sms_type,
            senderId: values.visitor_end_otp_sender_id,
            smscontent: values.visitor_end_sms_content,
          },
          companyId: activeCompany._id,
        },
      };

      axios(reqObj)
        .then((res) => {
          toast.success("Sms configured successfully!!");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    },
  });

  return (
    <>
      <div className={classes.Container}>
        <h3>SMS Configuration</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.commonSetting}>
            <p>Common Settings</p>
            <div className={classes.settingFields}>
              <div>
                <label>OTP API URL*</label>
                <input
                  type="text"
                  id="otp_api_url"
                  name="otp_api_url"
                  {...formik.getFieldProps("otp_api_url")}
                />
                <span>
                  {formik.touched.otp_api_url && formik.errors.otp_api_url ? (
                    <div>{formik.errors.otp_api_url}</div>
                  ) : null}
                </span>
              </div>
              <div>
                <label>OTP Access Code*</label>
                <input
                  type="text"
                  id="otp_access_code"
                  name="otp_access_code"
                  {...formik.getFieldProps("otp_access_code")}
                />
                <span>
                  {formik.touched.otp_access_code &&
                  formik.errors.otp_access_code ? (
                    <div>{formik.errors.otp_access_code}</div>
                  ) : null}
                </span>
              </div>
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputDiv}>
              <div className={classes.smsInputLeft}>
                <p>Admin Panel Reset Password</p>
                <div>
                  <label>SMS Type :</label>  <br />
                  <input
                    type="radio"
                    id="Implicit"
                    name="forgot_password_sms_type"
                    value="Implicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.forgot_password_sms_type === "Implicit"
                    }
                  />
                    <label for="Implicit">Implicit</label> {" "}
                  {window.screen.width <= 480 ? <br /> : ""}
                  <input
                    type="radio"
                    id="Explicit"
                    name="forgot_password_sms_type"
                    value="Explicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.forgot_password_sms_type === "Explicit"
                    }
                  />
                    <label for="Explicit">Explicit</label>
                  <div className={classes.errMsg}>
                    {formik.touched.forgot_password_sms_type &&
                    formik.errors.forgot_password_sms_type ? (
                      <div>{formik.errors.forgot_password_sms_type}</div>
                    ) : null}
                  </div>
                </div>

                <label htmlFor="content1" className={classes.contentLable}>
                  Forgot Password SMS Content*
                </label>
              </div>

              <div className={classes.smsInputRight}>
                <div>
                  <label>OTP Template ID*</label>
                  <input
                    type="text"
                    id="forgot_password_otp_template_id"
                    name="forgot_password_otp_template_id"
                    {...formik.getFieldProps("forgot_password_otp_template_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.forgot_password_otp_template_id &&
                    formik.errors.forgot_password_otp_template_id ? (
                      <div>{formik.errors.forgot_password_otp_template_id}</div>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label>OTP Sender ID*</label>
                  <input
                    type="text"
                    id="forgot_password_otp_sender_id"
                    name="forgot_password_otp_sender_id"
                    {...formik.getFieldProps("forgot_password_otp_sender_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.forgot_password_otp_sender_id &&
                    formik.errors.forgot_password_otp_sender_id ? (
                      <div>{formik.errors.forgot_password_otp_sender_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              className={classes.contentInput}
              type="text"
              id="forgot_password_sms_content"
              name="forgot_password_sms_content"
              {...formik.getFieldProps("forgot_password_sms_content")}
            />
            <div className={classes.errMsg}>
              {formik.touched.employee_registration_sms_content &&
              formik.errors.employee_registration_sms_content ? (
                <div>{formik.errors.employee_registration_sms_content}</div>
              ) : null}
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputDiv}>
              <div className={classes.smsInputLeft}>
                <p>Visitor Mobile OTP SMS</p>
                <div>
                  <label>SMS Type :</label>  <br />
                  <input
                    type="radio"
                    id="Implicit"
                    name="visitor_mobile_sms_type"
                    value="Implicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.visitor_mobile_sms_type === "Implicit"
                    }
                  />
                    <label for="Implicit">Implicit</label> {" "}
                  {window.screen.width <= 480 ? <br /> : ""}
                  <input
                    type="radio"
                    id="Explicit"
                    name="visitor_mobile_sms_type"
                    value="Explicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.visitor_mobile_sms_type === "Explicit"
                    }
                  />
                    <label for="Explicit">Explicit</label>
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_mobile_sms_type &&
                    formik.errors.visitor_mobile_sms_type ? (
                      <div>{formik.errors.visitor_mobile_sms_type}</div>
                    ) : null}
                  </div>
                </div>

                <label htmlFor="content1" className={classes.contentLable}>
                  Visitor Mobile OTP SMS Content*
                </label>
              </div>

              <div className={classes.smsInputRight}>
                <div>
                  <label>OTP Template ID*</label>
                  <input
                    type="text"
                    id="visitor_mobile_otp_template_id"
                    name="visitor_mobile_otp_template_id"
                    {...formik.getFieldProps("visitor_mobile_otp_template_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_mobile_otp_template_id &&
                    formik.errors.visitor_mobile_otp_template_id ? (
                      <div>{formik.errors.visitor_mobile_otp_template_id}</div>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label>OTP Sender ID*</label>
                  <input
                    type="text"
                    id="visitor_mobile_otp_sender_id"
                    name="visitor_mobile_otp_sender_id"
                    {...formik.getFieldProps("visitor_mobile_otp_sender_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_mobile_otp_sender_id &&
                    formik.errors.visitor_mobile_otp_sender_id ? (
                      <div>{formik.errors.visitor_mobile_otp_sender_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              className={classes.contentInput}
              type="text"
              id="visitor_mobile_sms_content"
              name="visitor_mobile_sms_content"
              {...formik.getFieldProps("visitor_mobile_sms_content")}
            />
            <div className={classes.errMsg}>
              {formik.touched.visitor_mobile_sms_content &&
              formik.errors.visitor_mobile_sms_content ? (
                <div>{formik.errors.visitor_mobile_sms_content}</div>
              ) : null}
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputDiv}>
              <div className={classes.smsInputLeft}>
                <p>DigiPass Approval SMS to Employee</p>
                <div>
                  <label>SMS Type :</label>  <br />
                  <input
                    type="radio"
                    id="Implicit"
                    name="employee_approval_sms_type"
                    value="Implicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.employee_approval_sms_type === "Implicit"
                    }
                  />
                    <label for="Implicit">Implicit</label> {" "}
                  {window.screen.width <= 480 ? <br /> : ""}
                  <input
                    type="radio"
                    id="Explicit"
                    name="employee_approval_sms_type"
                    value="Explicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.employee_approval_sms_type === "Explicit"
                    }
                  />
                    <label for="Explicit">Explicit</label>
                  <div className={classes.errMsg}>
                    {formik.touched.employee_approval_sms_type &&
                    formik.errors.employee_approval_sms_type ? (
                      <div>{formik.errors.employee_approval_sms_type}</div>
                    ) : null}
                  </div>
                </div>

                <label htmlFor="content1" className={classes.contentLable}>
                  Employee Approval SMS Content*
                </label>
              </div>

              <div className={classes.smsInputRight}>
                <div>
                  <label>OTP Template ID*</label>
                  <input
                    type="text"
                    id="employee_approval_otp_template_id"
                    name="employee_approval_otp_template_id"
                    {...formik.getFieldProps(
                      "employee_approval_otp_template_id"
                    )}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.employee_approval_otp_template_id &&
                    formik.errors.employee_approval_otp_template_id ? (
                      <div>
                        {formik.errors.employee_approval_otp_template_id}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label>OTP Sender ID*</label>
                  <input
                    type="text"
                    id="employee_approval_otp_sender_id"
                    name="employee_approval_otp_sender_id"
                    {...formik.getFieldProps("employee_approval_otp_sender_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.employee_approval_otp_sender_id &&
                    formik.errors.employee_approval_otp_sender_id ? (
                      <div>{formik.errors.employee_approval_otp_sender_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              className={classes.contentInput}
              type="text"
              id="employee_approval_sms_content"
              name="employee_approval_sms_content"
              {...formik.getFieldProps("employee_approval_sms_content")}
            />
            <div className={classes.errMsg}>
              {formik.touched.employee_approval_sms_content &&
              formik.errors.employee_approval_sms_content ? (
                <div>{formik.errors.employee_approval_sms_content}</div>
              ) : null}
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputDiv}>
              <div className={classes.smsInputLeft}>
                <p>DigiPass View Message</p>
                <div>
                  <label>SMS Type :</label>  <br />
                  <input
                    type="radio"
                    id="Implicit"
                    name="visitor_approval_sms_type"
                    value="Implicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.visitor_approval_sms_type === "Implicit"
                    }
                  />
                    <label for="Implicit">Implicit</label> {" "}
                  {window.screen.width <= 480 ? <br /> : ""}
                  <input
                    type="radio"
                    id="Explicit"
                    name="visitor_approval_sms_type"
                    value="Explicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.visitor_approval_sms_type === "Explicit"
                    }
                  />
                    <label for="Explicit">Explicit</label>
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_approval_sms_type &&
                    formik.errors.visitor_approval_sms_type ? (
                      <div>{formik.errors.visitor_approval_sms_type}</div>
                    ) : null}
                  </div>
                </div>

                <label htmlFor="content1" className={classes.contentLable}>
                  Visitor Approval SMS Content*
                </label>
              </div>

              <div className={classes.smsInputRight}>
                <div>
                  <label>OTP Template ID*</label>
                  <input
                    type="text"
                    id="visitor_approval_otp_template_id"
                    name="visitor_approval_otp_template_id"
                    {...formik.getFieldProps(
                      "visitor_approval_otp_template_id"
                    )}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_approval_otp_template_id &&
                    formik.errors.visitor_approval_otp_template_id ? (
                      <div>
                        {formik.errors.visitor_approval_otp_template_id}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label>OTP Sender ID*</label>
                  <input
                    type="text"
                    id="visitor_approval_otp_sender_id"
                    name="visitor_approval_otp_sender_id"
                    {...formik.getFieldProps("visitor_approval_otp_sender_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_approval_otp_sender_id &&
                    formik.errors.visitor_approval_otp_sender_id ? (
                      <div>{formik.errors.visitor_approval_otp_sender_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              className={classes.contentInput}
              type="text"
              id="visitor_approval_sms_content"
              name="visitor_approval_sms_content"
              {...formik.getFieldProps("visitor_approval_sms_content")}
            />
            <div className={classes.errMsg}>
              {formik.touched.visitor_approval_sms_content &&
              formik.errors.visitor_approval_sms_content ? (
                <div>{formik.errors.visitor_approval_sms_content}</div>
              ) : null}
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputDiv}>
              <div className={classes.smsInputLeft}>
                <p>DigiPass Notification SMS to Emplyee</p>
                <div>
                  <label>SMS Type :</label>  <br />
                  <input
                    type="radio"
                    id="Implicit"
                    name="employee_fyi_sms_type"
                    value="Implicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.employee_fyi_sms_type === "Implicit"
                    }
                  />
                    <label for="Implicit">Implicit</label> {" "}
                  {window.screen.width <= 480 ? <br /> : ""}
                  <input
                    type="radio"
                    id="Explicit"
                    name="employee_fyi_sms_type"
                    value="Explicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.employee_fyi_sms_type === "Explicit"
                    }
                  />
                    <label for="Explicit">Explicit</label>
                  <div className={classes.errMsg}>
                    {formik.touched.employee_fyi_sms_type &&
                    formik.errors.employee_fyi_sms_type ? (
                      <div>{formik.errors.employee_fyi_sms_type}</div>
                    ) : null}
                  </div>
                </div>

                <label htmlFor="content1" className={classes.contentLable}>
                  Employee FYI SMS Content*
                </label>
              </div>

              <div className={classes.smsInputRight}>
                <div>
                  <label>OTP Template ID*</label>
                  <input
                    type="text"
                    id="employee_fyi_otp_template_id"
                    name="employee_fyi_otp_template_id"
                    {...formik.getFieldProps("employee_fyi_otp_template_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.employee_fyi_otp_template_id &&
                    formik.errors.employee_fyi_otp_template_id ? (
                      <div>{formik.errors.employee_fyi_otp_template_id}</div>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label>OTP Sender ID*</label>
                  <input
                    type="text"
                    id="employee_fyi_otp_sender_id"
                    name="employee_fyi_otp_sender_id"
                    {...formik.getFieldProps("employee_fyi_otp_sender_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.employee_fyi_otp_sender_id &&
                    formik.errors.employee_fyi_otp_sender_id ? (
                      <div>{formik.errors.employee_fyi_otp_sender_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              className={classes.contentInput}
              type="text"
              id="employee_fyi_sms_content"
              name="employee_fyi_sms_content"
              {...formik.getFieldProps("employee_fyi_sms_content")}
            />
            <div className={classes.errMsg}>
              {formik.touched.employee_fyi_sms_content &&
              formik.errors.employee_fyi_sms_content ? (
                <div>{formik.errors.employee_fyi_sms_content}</div>
              ) : null}
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputDiv}>
              <div className={classes.smsInputLeft}>
                <p>DigiPass Reject SMS</p>
                <div>
                  <label>SMS Type :</label>  <br />
                  <input
                    type="radio"
                    id="Implicit"
                    name="employee_reject_sms_type"
                    value="Implicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.employee_reject_sms_type === "Implicit"
                    }
                  />
                    <label for="Implicit">Implicit</label> {" "}
                  {window.screen.width <= 480 ? <br /> : ""}
                  <input
                    type="radio"
                    id="Explicit"
                    name="employee_reject_sms_type"
                    value="Explicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.employee_reject_sms_type === "Explicit"
                    }
                  />
                    <label for="Explicit">Explicit</label>
                  <div className={classes.errMsg}>
                    {formik.touched.employee_reject_sms_type &&
                    formik.errors.employee_reject_sms_type ? (
                      <div>{formik.errors.employee_reject_sms_type}</div>
                    ) : null}
                  </div>
                </div>

                <label htmlFor="content1" className={classes.contentLable}>
                  Employee Reject SMS Content*
                </label>
              </div>

              <div className={classes.smsInputRight}>
                <div>
                  <label>OTP Template ID*</label>
                  <input
                    type="text"
                    id="employee_reject_otp_template_id"
                    name="employee_reject_otp_template_id"
                    {...formik.getFieldProps("employee_reject_otp_template_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.employee_reject_otp_template_id &&
                    formik.errors.employee_reject_otp_template_id ? (
                      <div>{formik.errors.employee_reject_otp_template_id}</div>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label>OTP Sender ID*</label>
                  <input
                    type="text"
                    id="employee_reject_otp_sender_id"
                    name="employee_reject_otp_sender_id"
                    {...formik.getFieldProps("employee_reject_otp_sender_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.employee_reject_otp_sender_id &&
                    formik.errors.employee_reject_otp_sender_id ? (
                      <div>{formik.errors.employee_reject_otp_sender_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              className={classes.contentInput}
              type="text"
              id="employee_reject_sms_content"
              name="employee_reject_sms_content"
              {...formik.getFieldProps("employee_reject_sms_content")}
            />
            <div className={classes.errMsg}>
              {formik.touched.employee_reject_sms_content &&
              formik.errors.employee_reject_sms_content ? (
                <div>{formik.errors.employee_reject_sms_content}</div>
              ) : null}
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputDiv}>
              <div className={classes.smsInputLeft}>
                <p>Visitor Meeting End SMS</p>
                <div>
                  <label>SMS Type :</label>  <br />
                  <input
                    type="radio"
                    id="Implicit"
                    name="visitor_end_sms_type"
                    value="Implicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.visitor_end_sms_type === "Implicit"
                    }
                  />
                    <label for="Implicit">Implicit</label> {" "}
                  {window.screen.width <= 480 ? <br /> : ""}
                  <input
                    type="radio"
                    id="Explicit"
                    name="visitor_end_sms_type"
                    value="Explicit"
                    onChange={formik.handleChange}
                    defaultChecked={
                      formik.values.visitor_end_sms_type === "Explicit"
                    }
                  />
                    <label for="Explicit">Explicit</label>
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_end_sms_type &&
                    formik.errors.visitor_end_sms_type ? (
                      <div>{formik.errors.visitor_end_sms_type}</div>
                    ) : null}
                  </div>
                </div>

                <label htmlFor="content1" className={classes.contentLable}>
                  Visitor End SMS Content*
                </label>
              </div>

              <div className={classes.smsInputRight}>
                <div>
                  <label>OTP Template ID*</label>
                  <input
                    type="text"
                    id="visitor_end_otp_template_id"
                    name="visitor_end_otp_template_id"
                    {...formik.getFieldProps("visitor_end_otp_template_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_end_otp_template_id &&
                    formik.errors.visitor_end_otp_template_id ? (
                      <div>{formik.errors.visitor_end_otp_template_id}</div>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label>OTP Sender ID*</label>
                  <input
                    type="text"
                    id="visitor_end_otp_sender_id"
                    name="visitor_end_otp_sender_id"
                    {...formik.getFieldProps("visitor_end_otp_sender_id")}
                  />
                  <div className={classes.errMsg}>
                    {formik.touched.visitor_end_otp_sender_id &&
                    formik.errors.visitor_end_otp_sender_id ? (
                      <div>{formik.errors.visitor_end_otp_sender_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              className={classes.contentInput}
              type="text"
              id="visitor_end_sms_content"
              name="visitor_end_sms_content"
              {...formik.getFieldProps("visitor_end_sms_content")}
            />
            <div className={classes.errMsg}>
              {formik.touched.visitor_end_sms_content &&
              formik.errors.visitor_end_sms_content ? (
                <div>{formik.errors.visitor_end_sms_content}</div>
              ) : null}
            </div>
          </div>

          <div className={btnClasses.formBtn}>
            <button
              type="submit"
              className={btnClasses.createBtn}
              disabled={loading}
            >
              {loading && (
                <div
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SmsConfiguration;
