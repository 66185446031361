import axios from "axios";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authContext from "../../../../AuthContext/authContext";
import appActions from "../../../../ReduxStore/appDataSlice";
import ConfirmModal from "../../Common/ConfirmModal/ConfirmModal";
import Home from "../../Common/Home/Home";
import Table from "../../Table/Table";
import classes from "../../Table/Table.module.css";
const base_url = process.env.REACT_APP_BASE_URL;
const SubadminWrapper = () => {
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [isLoading, setIsloading] = useState(false);
  const [subadminToDelete, setSubadminToDelete] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEdit = (id) => {
    dispatch(appActions.setActiveEditSubadminId(id));
    localStorage.setItem("activeEditSubadminId", id);
    navigate("/editSubadmin");
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${base_url}/user/subadmin/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
        toast.success("Sub admin deleted successfully.");
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        toast.error("Failed to delete sub admin.");
      });
  };

  const columns = [
    {
      name: "S. No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className={classes.icons}>
            <img
              style={{ cursor: "pointer" }}
              src={require("../../../../assets/img/admin-images/edit_thin.png")}
              onClick={() => handleEdit(row._id)}
            />
            <img
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              src={require("../../../../assets/img/admin-images/delete.png")}
              onClick={() =>
                setSubadminToDelete({ id: row._id, name: row.name })
              }
            />
          </div>
        </>
      ),
    },
  ];

  const options = {
    title: "Sub Admin",
    showCsvExport: false,
    showDownload: false,
    showExport: false,
    showLocation: false,
    showSms: false,
    showAddNew: true,
    showFirstHr: false,
    showSecondHr: false,
    showActions: false,
    type: "SubAdmin",
    columns: columns,
    apiUrl: `${base_url}/user/subadmin/list`,
  };
  return (
    <>
      <Home propComponent={<Table options={options} isLoading={isLoading} />} />
      <ConfirmModal
        handleDelete={handleDelete}
        id={subadminToDelete.id}
        msg={`Are you sure you want to delete ${subadminToDelete.name}?`}
      />
    </>
  );
};

export default SubadminWrapper;
