import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import classes from "./BarChart.module.css";
import { useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import authContext from "../../../AuthContext/authContext";
import { useState } from "react";
import { useSelector } from "react-redux";

const base_url = process.env.REACT_APP_BASE_URL;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function BarChart() {
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const [barData, setBarData] = useState([]);
  const payload = useSelector((state) => state.appData.barChartPayload);
  const barChartFreqency = useSelector(
    (state) => state.appData.barChartFreqency
  );

  const backgroundColor = [
    "#0000FF",
    "#008000",
    "#800080",
    "#FF0000",
    "#ffff00",
    "#006400",
    "#FF7F50",
    "#B22222",
    "#FF69B4",
    "#FFFACD",
    "#FC766AFF",
    "#5B84B1FF",
    "#42EADDFF",
    "#CDB599FF",
    "#F95700FF",
    "#00203FFF",
    "#ADEFD1FF",
  ];

  useEffect(() => {
    let reqObj = {
      method: "POST",
      url: `${base_url}/dashboard/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`,
      },
      data: { ...payload, frequency: barChartFreqency },
    };

    axios(reqObj)
      .then((res) => {
        setBarData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [payload, barChartFreqency]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Visitor",
      },
    },
    barPercentage: 0.5,
    categoryPercentage: 0.8,
    maintainAspectRatio: false,
  };

  const labels = barData.map((item) => {
    return item["_id"];
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Monthly",
        data: barData.map((item) => {
          return item["count"];
        }),
        // backgroundColor: "#9d41c9",
        backgroundColor: backgroundColor,
      },
    ],
  };

  return (
    <>
      <Bar options={options} data={data} />
      <div className={classes.ProgressDiv}>
        {barData.map((item, index) => {
          return (
            <>
              <div className={classes.bar}>
                <span>{item["_id"]}</span>
                <div>
                  <div class={`progress`}>
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{
                        width: item["count"],
                        backgroundColor: backgroundColor[index],
                        // backgroundColor: "#9d41c9",
                      }}
                      aria-valuenow="26"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <span>{item["count"]}</span>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
