import { BarChart } from "../Chart/BarChart";
import { LineChart } from "../Chart/LineChart";
import classes from "./Dashboard.module.css";
import Hexagon from "react-hexagon";
import { useContext, useEffect, useState } from "react";
import authContext from "../../../AuthContext/authContext";
import Loader from "../Common/Loader/Loader";
import { useDispatch } from "react-redux";
import appActions from "../../../ReduxStore/appDataSlice";
import PieChart from "../Chart/PieChart";
import UniqueVisitor from "../Chart/UniqueVisitor";
import DepartmentWise from "../Chart/DepartmentWise";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Dashboard = ({
  initialData,
  setCompanyId,
  setFrequency,
  companyList,
  locationList,
  setSelectedLocation,
  isLoading,
}) => {
  const {
    currentMonthVisitors,
    digipassGenerated,
    digipassRejected,
    lastMonthVisitors,
    smsCount,
    visitorCount,
    companyCount,
    repeatedVisitorNumber,
    uniqueVisitorNumber,
    subscriptionEndDate,
  } = initialData;

  const navigate = useNavigate();

  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  let showCompany = userType == "admin" || userType == "subadmin";
  let showLocations = !(userType === "location" || userType === "company");

  // useEffect(() => {
  //   if (showCompany && companyList.length > 0) {
  //     setCompanyId(companyList[0]["_id"]);
  //   }
  // }, []);

  let companyArr = [];

  for (let i = 0; i < companyCount; i++) {
    companyArr.push(0);
  }

  console.log("companyArr", companyArr);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(
  //     appActions.setBarChartPayload({ companyId: companyList[0]["_id"] })
  //   );
  //   localStorage.setItem(
  //     "barChartPayload",
  //     JSON.stringify({ companyId: companyList[0]["_id"] })
  //   );
  // }, []);

  const handleFrequency = (e) => {
    setFrequency(e.target.value);
    dispatch(appActions.setBarChartFreqency(e.target.value));
  };
  return (
    <div className={classes.dashboardCont}>
      <div className={classes.header}>
        <div>
          <h2>Dashboard</h2>
        </div>
        <div className={classes.rightCont} style={{ width: "70%" }}>
          {/* <select>
            <option>DF Technology</option>
          </select>
          <select>
            <option>Monthlys</option>
          </select> */}
          {subscriptionEndDate && (
            <div className={classes.renew}>
              Renew date:{" "}
              <strong>
                {" "}
                {moment.utc(subscriptionEndDate).local().format("DD MMMM YYYY")}
              </strong>
            </div>
          )}
          <div className={classes.dowpDown}>
            {showCompany && companyList.length > 0 && (
              <select
                class="form-select mr-2 mt-2"
                aria-label="Default select example"
                onChange={(e) => {
                  setCompanyId(e.target.value);
                  dispatch(
                    appActions.setBarChartPayload({ companyId: e.target.value })
                  );
                  localStorage.setItem(
                    "barChartPayload",
                    JSON.stringify({ companyId: e.target.value })
                  );

                  //reset location
                  dispatch(appActions.setChartLocation({ locationId: "" }));
                  localStorage.setItem(
                    "chartLocation",
                    JSON.stringify({ locationId: "" })
                  );
                  document.getElementById("locationlist").value = "";
                }}
              >
                <option selected value={companyList[0]["_id"]}>
                  {companyList[0]["name"]}
                </option>
                {companyList.map((company, index) => {
                  if (index !== 0) {
                    return (
                      <option key={index} value={company._id}>
                        {company.name}
                      </option>
                    );
                  }
                })}
              </select>
            )}

            {showLocations && locationList.length > 0 && (
              <select
                class="form-select mr-2 mt-2"
                aria-label="Default select example"
                id="locationlist"
                onChange={(e) => {
                  setSelectedLocation(e.target.value);
                  dispatch(
                    appActions.setChartLocation({ locationId: e.target.value })
                  );
                  localStorage.setItem(
                    "chartLocation",
                    JSON.stringify({ locationId: e.target.value })
                  );
                }}
              >
                <option selected value="">
                  All
                </option>
                {locationList.map((locations, index) => {
                  return (
                    <option key={index} value={locations._id}>
                      {locations.location}
                    </option>
                  );
                })}
              </select>
            )}

            <select
              class="form-select mr-2 mt-2"
              aria-label="Default select example"
              onChange={handleFrequency}
            >
              <option value={7} selected>
                7 days
              </option>
              <option value={30}>1 Month</option>
              <option value={90}>3 Months</option>
              <option value={365}>1 Year</option>
            </select>
          </div>
        </div>
      </div>

      {isLoading ? (
        <section class="container">
          {window.screen.width > 480 && showCompany && (
            <div
              className={`row ${classes.NewCompany}`}
              onClick={() => navigate("/dashboard/companyOnboarded")}
              style={{ cursor: "pointer" }}
            >
              <h2>Companies Onboarded - {companyCount}</h2>
              {/* <ul className={classes.list}>
                {Array.from({ length: companyCount }, () => (
                  <li className={classes.listItem}></li>
                ))}

                {Array.from({ length: 100 }, () => (
                  <li
                    className={classes.listItem}
                    style={{ border: "4px solid #fff" }}
                  ></li>
                ))}
              </ul> */}
            </div>
          )}

          {window.screen.width < 480 && (
            <div
              className="row"
              onClick={() => navigate("/dashboard/companyOnboarded")}
              style={{ cursor: "pointer" }}
            >
              <div className={`col m-3 ${classes.card} ${classes.card1}`}>
                <h1>{companyCount}</h1>
                <h2>
                  Companies <br />
                  Onboarded
                </h2>
              </div>
            </div>
          )}

          <div className="row">
            {/* {showCompany && (
              <div className={`col m-3 ${classes.card}`}>
                <h2>New company</h2>
                <h2>{companyCount}</h2>
              </div>
            )} */}

            <div className={`col m-3 ${classes.card} ${classes.card1}`}>
              <h1>{visitorCount}</h1>
              <h2>
                Total <br />
                Visitors
              </h2>
            </div>

            <div className={`col m-3 ${classes.card} ${classes.card2}`}>
              <h1>{uniqueVisitorNumber}</h1>
              <h2>
                Unique <br /> Visitors
              </h2>
            </div>

            <div className={`col m-3 ${classes.card} ${classes.card3}`}>
              <h1>{repeatedVisitorNumber}</h1>
              <h2>
                Repeat <br />
                Visitors
              </h2>
            </div>
          </div>

          <div className="row">
            <div className={`col m-3 ${classes.card} ${classes.card4}`}>
              <h1>{digipassGenerated}</h1>
              <h2>
                Pass <br /> Generated
              </h2>
            </div>

            <div className={`col m-3 ${classes.card} ${classes.card5}`}>
              <h1>{digipassRejected}</h1>
              <h2>
                Pass <br /> Rejected
              </h2>
            </div>

            <div className={`col m-3 ${classes.card} ${classes.card6}`}>
              <h1>{smsCount}</h1>
              <h2>
                Total SMS <br /> Pushed
              </h2>
            </div>
          </div>

          <div className="row">
            <div className={`col card text-bg-primary m-3 ${classes.pieCard}`}>
              <div className={`card-header text-center ${classes.header8}`}>
                Visitor Data
              </div>
              <div
                class={`card-body  ${classes.dog6}`}
                style={{ width: "100%", margin: "auto" }}
              >
                <PieChart
                  currentMonthVisitors={currentMonthVisitors}
                  lastMonthVisitors={lastMonthVisitors}
                />
              </div>
            </div>

            <div className={`col card text-bg-primary m-3 ${classes.pieCard}`}>
              <div className={`card-header text-center ${classes.header8}`}>
                Department wise visitors
              </div>
              <div
                class={`card-body  ${classes.dog6}`}
                style={{ width: "100%", margin: "auto" }}
              >
                <DepartmentWise />
              </div>
            </div>
          </div>

          <div className={` ${classes.lineChart}`}>
            <div>
              <h2 className="text-center">Visitors</h2>
              <div className={classes.lineChartDiv}>
                <LineChart />
              </div>
            </div>
          </div>

          {/* <div className={` ${classes.lineChart} ${classes.barChart}`}>
            <div>
              <h2 className="text-center">Department Wise Visitors</h2>
              <div className={classes.lineChartDiv}>
                <BarChart />
              </div>
            </div>
          </div> */}
        </section>
      ) : (
        <Loader centerLoader={"centerLoader"} />
      )}
    </div>
  );
};

export default Dashboard;
