import { Link } from "react-router-dom";
import tableClass from "../../Table/Table.module.css";
import classes from "../AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useContext } from "react";
import authContext from "../../../../AuthContext/authContext";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import departments from "../../Common/departmentDropDown/Departments";

const base_url = process.env.REACT_APP_BASE_URL;

const EditEmployee = ({ initialData }) => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const nameRegExp = /^[A-Za-z ]+$/;
  const departmentRegExp = /^[A-Za-z-& ]+$/;
  const formik = useFormik({
    enableReinitialize: initialData,
    initialValues: {
      name: initialData.name,
      email: initialData.email,
      phone: initialData.phone,
      designation: initialData.designation,
      department: initialData.department,
      locationId: initialData.locationId,
      location: initialData.location
    },
    validationSchema: Yup.object({
      name: Yup.string()
        // .matches(nameRegExp, "Please enter valid name")
        .required("Please enter employee name"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email address"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter your phone number"),
      designation: Yup.string()
        // .matches(departmentRegExp, "Please enter valid designation")
        .required("Please enter employee designation"),
      department: Yup.string()
        // .matches(departmentRegExp, "Please enter valid department")
        .required("Please enter employee department")
    }),
    onSubmit: async (values) => {
      let response = await axios.get(
        `${base_url}/user/profile/${initialData["locationId"]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ctx.token}`
          }
        }
      );
      // console.log("response", response);
      let locationName = response.data.location;

      let reqObj = {
        method: "PUT",
        url: `${base_url}/company/update-employee/${initialData._id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ctx.token}`
        },
        data: {
          name: values.name,
          email: values.email,
          phone: values.phone,
          designation: values.designation,
          department: values.department,
          location: initialData.location || locationName,
          companyId: initialData.companyId,
          locationId: initialData.locationId
        }
      };

      axios(reqObj)
        .then((res) => {
          toast.dismiss();
          toast.success("Employee data updated successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss();
          toast.error(
            err.response.data.error.message || "Failed to update employee data"
          );
        });
    }
  });

  const showIcons = userType !== "Building Manager";
  const showLocation = activeCompany.type === "Company";
  const showEmployeeDirectory = activeCompany.type === "Company";

  return (
    <>
      <div className={classes.mainCont}>
        <h3>Edit Employee</h3>
        <div className={classes.topLinks}>
          {showEmployeeDirectory && (
            <div className={tableClass.tableAction}>
              <Link to="/employeeDirectory">
                <img
                  src={require("../../../../assets/img/admin-images/copy.png")}
                />
                <span>Employee Directory</span>
              </Link>
            </div>
          )}
          {showLocation && (
            <div className={tableClass.tableAction}>
              <Link to="/location">
                <img
                  src={require("../../../../assets/img/admin-images/company_black.png")}
                />
                <span>Location</span>
              </Link>
            </div>
          )}
        </div>

        <form className={classes.FormDiv} onSubmit={formik.handleSubmit}>
          <div className={classes.formGrp}>
            <label for="name">Employee Name:</label>

            <div>
              <input
                type="text"
                id="name"
                name="name"
                {...formik.getFieldProps("name")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.name && formik.errors.name ? (
                  <div>{formik.errors.name}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="email">Employee Email:</label>
            <div>
              <input
                type="text"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.email && formik.errors.email ? (
                  <div>{formik.errors.email}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="phone">Employee Phone: </label>
            <div>
              <input
                type="text"
                id="phone"
                name="phone"
                {...formik.getFieldProps("phone")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.phone && formik.errors.phone ? (
                  <div>{formik.errors.phone}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="designation">Employee Designation:</label>
            <div>
              <input
                type="text"
                id="designation"
                name="designation"
                {...formik.getFieldProps("designation")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.designation && formik.errors.designation ? (
                  <div>{formik.errors.designation}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="department">Employee Department:</label>
            <div>
              {/* <input
                type="text"
                id="department"
                name="department"
                {...formik.getFieldProps("department")}
              /> */}
              <select
                placeholder="Select Department"
                id="department"
                name="department"
                {...formik.getFieldProps("department")}
              >
                <option value="">Select</option>
                {departments.map((department) => (
                  <option value={department}>{department}</option>
                ))}
                {/* <option value="Sales">Sales</option>
                <option value="Operations">Operations</option>
                <option value="Product">Product</option>
                <option value="Tech & IT">Tech & IT</option>
                <option value="Quality">Quality</option>
                <option value="Purchase">Purchase</option>
                <option value="Procurement">Procurement</option>
                <option value="Marketing">Marketing</option>
                <option value="Admin">Admin</option>
                <option value="HR">HR</option>
                <option value="Finance">Finance</option>
                <option value="Legal">Legal</option>
                <option value="Others">Others</option> */}
              </select>
              <span className={classes.profileerrMsg}>
                {formik.touched.department && formik.errors.department ? (
                  <div>{formik.errors.department}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formBtn}>
            {/* <button type="button" className={classes.cancleBtn}>
              Cancle
            </button> */}
            <button type="submit" className={classes.createBtn}>
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditEmployee;
