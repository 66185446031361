import Home from "../../Common/Home/Home";
import AddNewLocation from "./AddNewLocation";

const AddNewLocationWrapper = () => {
  return (
    <>
      <Home propComponent={<AddNewLocation />} />
    </>
  );
};

export default AddNewLocationWrapper;
