import Home from "../../Common/Home/Home";
import AddNewCompany from "./AddNewCompany";

const AddNewCompanyWrapper = () => {
  return (
    <>
      <Home propComponent={<AddNewCompany />} />
    </>
  );
};

export default AddNewCompanyWrapper;
