import { configureStore } from "@reduxjs/toolkit";
import { appDataSlice } from "./appDataSlice";
import { visitorSlice } from "./visitorSlice";
import { getDefaultMiddleware } from "@reduxjs/toolkit";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: {
    appData: appDataSlice.reducer,
    visitorData: visitorSlice.reducer,
  },
  middleware: customizedMiddleware,
});

export default store;
