const ClearData = () => {
  localStorage.removeItem("visitorFormData");
  localStorage.removeItem("companyData");
  localStorage.removeItem("visitorPhone");
  localStorage.removeItem("passwordId");
  localStorage.removeItem("maskedPhone");
  localStorage.removeItem("maskedDesignation");
  sessionStorage.removeItem("OTP");
};

export default ClearData;
