import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Header, Button, Popup, Grid } from "semantic-ui-react";
import authContext from "../../../../AuthContext/authContext";
import appActions from "../../../../ReduxStore/appDataSlice";
import Tableclasses from "../../Table/Table.module.css";
import classes from "./PopupDropdown.module.css";
const base_url = process.env.REACT_APP_BASE_URL;
const PopupDropdown = ({ type }) => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const ctx = useContext(authContext);
  const dispatch = useDispatch();
  let showDropDown = true;
  if (
    type == "EmployeeDirectory" ||
    type == "SubAdmin" ||
    type == "Location" ||
    type == "Building" ||
    type == "CompanyEmployeeDirectory" ||
    type == "SpecialVisitor"
  ) {
    showDropDown = false;
  }
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    var ele = document.getElementsByTagName("input");
    for (let i = 0; i < ele.length; i++) {
      if ((ele[i].type = "radio")) {
        if (ele[i].checked) {
          dispatch(appActions.setAddCompanyOrBuilding(ele[i].value));
          if (
            ele[i].value == "location1" ||
            ele[i].value == "Multiplelocation"
          ) {
            navigate("/addCompany");
          } else if (ele[i].value == "building") {
            navigate("/addBuilding");
          }
        }
      }
    }
  };

  const handleAddSubAdmin = (e) => {
    if (type === "SubAdmin") {
      navigate("/addSubadmin");
    } else if (type === "EmployeeDirectory") {
      navigate("/addEmployeeDirectory");
    } else if (type == "Location") {
      let reqObj = {
        method: "GET",
        url: `${base_url}/company/stats/${activeCompany._id}`,
        headers: {
          "Content-Type": "application/javascript",
          Authorization: `Bearer ${ctx.token}`,
        },
      };

      axios(reqObj)
        .then((res) => {
          if (res.data.allowedLocation > res.data.locationCount) {
            navigate("/addLocation");
          } else {
            toast.error("You have reached the location limit");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type == "Building") {
      navigate("/addCompanyInBuilding");
    } else if (type == "CompanyEmployeeDirectory") {
      navigate("/addEmployeeInCompany");
    } else if (type == "SpecialVisitor") {
      navigate("/create-special-pass");
    }
  };

  return (
    <>
      {!showDropDown && (
        <div
          className={Tableclasses.subHeaderRightItem}
          style={{ cursor: "pointer" }}
          onClick={handleAddSubAdmin}
        >
          <div
            className={Tableclasses.addbox}
            style={{ justifyContent: "center" }}
          >
            <img
              src={require("../../../../assets/img/admin-images/plus.png")}
            />
          </div>
          <p>Add New</p>
        </div>
      )}
      {showDropDown && (
        <Popup
          trigger={
            <div
              className={Tableclasses.subHeaderRightItem}
              style={{ cursor: "pointer" }}
              onClick={handleAddSubAdmin}
            >
              <div
                className={Tableclasses.addbox}
                style={{ justifyContent: "center" }}
              >
                <img
                  src={require("../../../../assets/img/admin-images/plus.png")}
                />
              </div>
              <p>Add New</p>
            </div>
          }
          flowing
          hoverable
        >
          <Grid centered divided columns={3}>
            <Grid.Column style={{ width: "100%" }}>
              <div>
                <div className={classes.title}>
                  <h4>Company Type :</h4>
                </div>
                <hr className={classes.hr} />
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.radioField}>
                      <input
                        type="radio"
                        id="location1"
                        name="Company1"
                        value="location1"
                      />
                        <label for="location1">Company With 1 Location</label>
                    </div>
                    <div className={classes.radioField}>
                      <input
                        type="radio"
                        id="Multiplelocation"
                        name="Company1"
                        value="Multiplelocation"
                      />
                       {" "}
                      <label for="Multiplelocation">
                        Company With Multiple Location
                      </label>
                    </div>
                    <div className={classes.radioField}>
                      <input
                        type="radio"
                        id="building"
                        name="Company1"
                        value="building"
                      />
                       {" "}
                      <label for="building">
                        Building with Multiple Companies
                      </label>
                    </div>

                    <button className={classes.btn}>Next</button>
                  </form>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Popup>
      )}
    </>
  );
};

export default PopupDropdown;
