import { Pie, Doughnut } from "react-chartjs-2";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import classes from "./PieChart.module.css";
import { useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import authContext from "../../../AuthContext/authContext";
import { useState } from "react";
import { useSelector } from "react-redux";

const base_url = process.env.REACT_APP_BASE_URL;

Chart.register(CategoryScale);

const DepartmentWise = () => {
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const [Data, setData] = useState([]);
  const payload = useSelector((state) => state.appData.barChartPayload);
  const locationId = useSelector((state) => state.appData.chartLocation);
  const barChartFreqency = useSelector(
    (state) => state.appData.barChartFreqency
  );

  let total = 0;
  Data.forEach((item) => (total += item["count"]));

  useEffect(() => {
    let data = {
      ...payload,
      frequency: barChartFreqency
    };
    if (locationId && locationId["locationId"] != "") {
      data = { ...data, ...locationId };
    }

    console.log("Location wise departments", data);
    let reqObj = {
      method: "POST",
      url: `${base_url}/dashboard/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`
      },
      data: data
    };

    axios(reqObj)
      .then((res) => {
        let sortedData = res.data.sort((r1, r2) =>
          r1["count"] < r2["count"] ? 1 : r1["count"] > r2["count"] ? -1 : 0
        );
        setData(sortedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [payload, barChartFreqency, locationId]);

  //   rgb(255, 23, 23)  rgb(215, 10, 10)
  // rgba(233, 129, 2, 0.907)  rgba(177, 100, 6, 0.907)
  // rgba(249, 231, 39, 0.907)  rgba(185, 171, 17, 0.907)
  // rgba(99, 250, 39, 0.907)   rgba(67, 191, 18, 0.907)
  // rgba(45, 252, 156, 0.907)  rgba(8, 197, 109, 0.907)
  // rgba(57, 246, 243, 0.907)  rgba(14, 197, 194, 0.907)
  // rgba(44, 93, 255, 0.907)   rgba(14, 54, 182, 0.907)
  // rgba(159, 60, 240, 0.907)  rgba(87, 1, 157, 0.907)
  // rgba(255, 72, 249, 0.907)  rgba(225, 4, 218, 0.907)
  // rgba(225, 4, 122, 0.907)   rgba(177, 5, 97, 0.907)
  // rgba(255, 73, 100, 0.907)  rgba(248, 42, 73, 0.907)
  //   rgb(143, 199, 223)    rgb(56, 136, 171)
  // rgb(217, 117, 45)  rgb(187, 95, 29)
  //   rgb(27, 233, 140)  rgb(18, 158, 95)
  //   rgb(185, 162, 184) rgb(157, 132, 155)
  //   rgb(28, 11, 76)  rgb(21, 8, 57)
  //   rgb(58, 30, 136)  rgb(32, 4, 109)
  const data = {
    labels: Data.map((item) => {
      return `${item._id} - ${item["count"]}`;
    }),
    datasets: [
      {
        data: Data.map((item) => item["count"]),
        borderRadius: 5,
        backgroundColor: [
          " rgb(255, 23, 23)",
          "rgba(233, 129, 2, 0.907)",
          "rgba(249, 231, 39, 0.907)",
          "rgb(185, 162, 184)",
          "rgb(58, 30, 136)",
          "rgba(57, 246, 243, 0.907)",
          "rgba(44, 93, 255, 0.907)",
          "rgba(159, 60, 240, 0.907)",
          "rgba(255, 72, 249, 0.907)",
          "rgba(225, 4, 122, 0.907) ",
          "rgba(255, 73, 100, 0.907)",
          "rgb(143, 199, 223)",
          "rgb(27, 233, 140)",
          // new Colors
          "rgb(0, 128, 63)",
          "rgb(63, 128, 0)",
          "rgb(63, 0, 128)",
          "rgb(0, 63, 128)",
          "rgb(63, 63, 63)",
          "rgb(190, 0, 0)",
          "rgb(0, 190, 0)",
          "rgb(0, 0, 190)",
          "rgb(190, 190, 0)",
          "rgb(190, 0, 190)",
          "rgb(0, 190, 190)",
          "rgb(128, 190, 0)",
          "rgb(128, 0, 190)",
          "rgb(0, 128, 190)",
          "rgb(190, 128, 0)",
          "rgb(190, 0, 128)",
          "rgb(0, 190, 128)",
          "rgb(190, 190, 190)",

          "rgb(255, 128, 0)",
          "rgb(255, 0, 128)",
          "rgb(128, 255, 0)",
          "rgb(128, 0, 255)",
          "rgb(0, 128, 255)",
          "rgb(0, 255, 128)",
          "rgb(128, 128, 128)",
          // "rgb(255, 255, 255)",
          "rgb(255, 128, 128)",
          "rgb(128, 255, 128)",
          "rgb(128, 128, 255)",
          "rgb(255, 255, 128)",
          "rgb(255, 128, 255)",
          "rgb(128, 255, 255)",
          "rgb(192, 0, 0)",
          "rgb(0, 192, 0)",
          "rgb(0, 0, 192)",
          "rgb(192, 192, 0)",
          "rgb(192, 0, 192)",
          "rgb(0, 192, 192)",
          "rgb(128, 192, 0)",
          "rgb(128, 0, 192)",
          "rgb(0, 128, 192)",
          "rgb(192, 128, 0)",
          "rgb(192, 0, 128)",
          "rgb(0, 192, 128)",
          "rgb(192, 192, 192)",
          "rgb(64, 0, 0)",
          "rgb(0, 64, 0)",
          "rgb(0, 0, 64)",
          "rgb(64, 64, 0)",
          "rgb(64, 0, 64)",
          "rgb(0, 64, 64)",
          "rgb(128, 64, 0)",
          "rgb(128, 0, 64)",
          "rgb(0, 128, 64)",
          "rgb(64, 128, 0)",
          "rgb(64, 0, 128)",
          "rgb(0, 64, 128)",
          "rgb(64, 64, 64)",
          "rgb(191, 0, 0)",
          "rgb(0, 191, 0)",
          "rgb(0, 0, 191)",
          "rgb(191, 191, 0)",
          "rgb(191, 0, 191)",
          "rgb(0, 191, 191)",
          "rgb(128, 191, 0)",
          "rgb(128, 0, 191)",
          "rgb(0, 128, 191)",
          "rgb(191, 128, 0)",
          "rgb(191, 0, 128)",
          "rgb(0, 191, 128)",
          "rgb(191, 191, 191)",
          "rgb(127, 0, 0)",
          "rgb(0, 127, 0)",
          "rgb(0, 0, 127)",
          "rgb(127, 127, 0)",
          "rgb(127, 0, 127)",
          "rgb(0, 127, 127)",
          "rgb(192, 127, 0)",
          "rgb(192, 0, 127)",
          "rgb(0, 192, 127)",
          "rgb(127, 192, 0)",
          "rgb(127, 0, 192)",
          "rgb(0, 127, 192)",
          "rgb(127, 127, 127)",
          "rgb(63, 0, 0)",
          "rgb(0, 63, 0)",
          "rgb(0, 0, 63)",
          "rgb(63, 63, 0)",
          "rgb(63, 0, 63)",
          "rgb(0, 63, 63)",
          "rgb(128, 63, 0)",
          "rgb(128, 0, 63)"
        ],
        hoverBackgroundColor: [
          "rgb(215, 10, 10)",
          "rgba(177, 100, 6, 0.907)",
          "rgba(185, 171, 17, 0.907)",
          "rgb(157, 132, 155)",
          "rgb(32, 4, 109)",
          "rgba(14, 197, 194, 0.907)",
          "rgba(14, 54, 182, 0.907)",
          "rgba(87, 1, 157, 0.907)",
          "rgba(225, 4, 218, 0.907)",
          "rgba(177, 5, 97, 0.907)",
          "rgba(248, 42, 73, 0.907)",
          "rgb(187, 95, 29)",
          "rgb(18, 158, 95)",
          //new colors
          "rgb(128, 63, 0)",
          "rgb(128, 0, 63)",
          "rgb(0, 128, 63)",
          "rgb(63, 128, 0)",
          "rgb(63, 0, 128)",
          "rgb(0, 63, 128)",
          "rgb(190, 0, 0)",
          "rgb(0, 190, 0)",
          "rgb(0, 0, 190)",
          "rgb(190, 190, 0)",
          "rgb(190, 0, 190)",
          "rgb(0, 190, 190)",
          "rgb(128, 190, 0)",
          "rgb(128, 0, 190)",
          "rgb(0, 128, 190)",
          "rgb(190, 128, 0)",
          "rgb(190, 0, 128)",
          "rgb(0, 190, 128)",

          "rgb(191, 0, 0)",
          "rgb(0, 191, 0)",
          "rgb(0, 0, 191)",
          "rgb(191, 191, 0)",
          "rgb(191, 0, 191)",
          "rgb(0, 191, 191)",
          "rgb(128, 191, 0)",
          "rgb(128, 0, 191)",
          "rgb(0, 128, 191)",
          "rgb(191, 128, 0)",
          "rgb(191, 0, 128)",
          "rgb(0, 191, 128)",
          "rgb(127, 0, 0)",
          "rgb(0, 127, 0)",
          "rgb(0, 0, 127)",
          "rgb(127, 127, 0)",
          "rgb(127, 0, 127)",
          "rgb(0, 127, 127)",
          "rgb(192, 127, 0)",
          "rgb(192, 0, 127)",
          "rgb(0, 192, 127)",
          "rgb(127, 192, 0)",
          "rgb(127, 0, 192)",
          "rgb(0, 127, 192)",
          "rgb(63, 0, 0)",
          "rgb(0, 63, 0)",
          "rgb(0, 0, 63)",
          "rgb(63, 63, 0)",
          "rgb(63, 0, 63)",
          "rgb(0, 63, 63)",
          "rgb(128, 63, 0)",
          "rgb(128, 0, 63)",
          "rgb(0, 128, 63)",
          "rgb(63, 128, 0)",
          "rgb(63, 0, 128)",
          "rgb(0, 63, 128)",
          "rgb(190, 0, 0)",
          "rgb(0, 190, 0)",
          "rgb(0, 0, 190)",
          "rgb(190, 190, 0)",
          "rgb(190, 0, 190)",
          "rgb(0, 190, 190)",
          "rgb(128, 190, 0)",
          "rgb(128, 0, 190)",
          "rgb(0, 128, 190)",
          "rgb(190, 128, 0)",
          "rgb(190, 0, 128)",
          "rgb(0, 190, 128)",
          "rgb(89, 0, 0)",
          "rgb(0, 89, 0)",
          "rgb(0, 0, 89)",
          "rgb(89, 89, 0)",
          "rgb(89, 0, 89)",
          "rgb(0, 89, 89)",
          "rgb(0, 89, 0)",
          "rgb(0, 0, 89)",
          "rgb(89, 89, 0)",
          "rgb(89, 0, 89)",
          "rgb(0, 89, 89)",
          "rgb(128, 0, 0)",
          "rgb(0, 128, 0)",
          "rgb(0, 0, 128)",
          "rgb(128, 128, 0)",
          "rgb(128, 0, 128)",
          "rgb(0, 128, 128)",
          "rgb(64, 0, 0)",
          "rgb(0, 64, 0)",
          "rgb(0, 0, 64)",
          "rgb(64, 64, 0)",
          "rgb(64, 0, 64)",
          "rgb(0, 64, 64)",
          "rgb(191, 0, 0)",
          "rgb(0, 191, 0)",
          "rgb(0, 0, 191)",
          "rgb(191, 191, 0)",
          "rgb(191, 0, 191)",
          "rgb(0, 191, 191)",
          "rgb(128, 191, 0)",
          "rgb(128, 0, 191)",
          "rgb(0, 128, 191)",
          "rgb(191, 128, 0)",
          "rgb(191, 0, 128)",
          "rgb(0, 191, 128)",
          "rgb(127, 0, 0)",
          "rgb(0, 127, 0)",
          "rgb(0, 0, 127)",
          "rgb(127, 127, 0)",
          "rgb(127, 0, 127)",
          "rgb(0, 127, 127)",
          "rgb(192, 127, 0)",
          "rgb(192, 0, 127)",
          "rgb(0, 192, 127)",
          "rgb(127, 192, 0)",
          "rgb(127, 0, 192)",
          "rgb(0, 127, 192)",
          "rgb(63, 0, 0)",
          "rgb(0, 63, 0)",
          "rgb(0, 0, 63)",
          "rgb(63, 63, 0)",
          "rgb(63, 0, 63)",
          "rgb(0, 63, 63)"
        ]
      }
    ],

    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          color: "rgb(255, 99, 132)"
        }
      },
      Plugin: {}
    },
    text: "10%"
  };

  return (
    <div className={classes.pieChart}>
      <Doughnut
        data={data}
        options={{
          elements: {
            center: {
              legend: { display: true, position: "right" },
              text: "Red is 2/3 the total numbers",
              color: "#FF6384", // Default is #000000
              fontStyle: "Arial", // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25 // Default is 25 (in px), used for when text wraps
            }
          },
          plugins: {
            legend: {
              display: true,
              position: window.screen.width < 480 ? "bottom" : "right",
              labels: {
                color: "black"
              }
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  console.log("Lable", context);

                  return `${((context.formattedValue / total) * 100).toFixed(
                    2
                  )}%`;
                }
              }
            }
          },
          cutout: "70%"
        }}
      />
    </div>
  );
};

export default DepartmentWise;
