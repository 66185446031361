import Home from "../../Common/Home/Home";
import AddCompanyInBuilding from "./AddCompanyInBuilding";

const AddCompanyInBuildingWrapper = () => {
  return (
    <>
      <Home propComponent={<AddCompanyInBuilding />} />
    </>
  );
};

export default AddCompanyInBuildingWrapper;
