import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import visitorActions from "../../../ReduxStore/visitorSlice";
import Footer from "../Footer/Footer";
import classes from "./Selfie.module.css";
import "../../../assets/css/visitor-css/master.css";
import { toast } from "react-toastify";
import ClearData from "../ClearData/ClearData";
import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
let isSubmit = true;
let isCovidQues = false;
const Selfie = () => {
  const selfieImg = useSelector((state) => state.visitorData.selfie);
  const facingMode = useSelector((state) => state.visitorData.facingMode);
  const isRepeated = useSelector((state) => state.visitorData.isRepeated);
  const companyData = useSelector((state) => state.visitorData.companyData);
  const visitorPhone = useSelector((state) => state.visitorData.visitorPhone);
  const OTPTime = useSelector((state) => state.visitorData.OTPTime);
  const visitorFormData = useSelector(
    (state) => state.visitorData.visitorFormData
  );
  // console.log("companyData", companyData);
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState();
  const [pageHeading, setPageHeading] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let DeviceDetails = navigator.userAgent;
  // console.log("selfieImg", selfieImg);
  // console.log("Type of selfieImg", typeof selfieImg);

  // let DeviceDetails = navigator.userAgent;
  // console.log(DeviceDetails);

  isSubmit = !companyData.validId;
  if (
    companyData.covidQuestionOne ||
    companyData.covidQuestionTwo ||
    companyData.covidQuestionThree ||
    companyData.covidQuestionFour ||
    companyData.covidQuestionFive
  ) {
    isSubmit = false;
    isCovidQues = true;
  }

  let buttonText = "Next";
  if (isSubmit) {
    buttonText = "Submit";
  }
  if (isCovidQues) {
    buttonText = "Next";
  }
  // console.log("isSubmit", isSubmit);
  useEffect(() => {
    let submit_failed = localStorage.getItem("submit_failed");
    if (submit_failed == "True") {
      toast.error("Internet was not stable, please retry", {
        position: "top-center",
      });
      localStorage.removeItem("submit_failed");
    }

    //Check selfie already exists
    if (isRepeated) {
      if (selfieImg && typeof selfieImg == "string") {
        setPageHeading("Use this selfie or click camera to retake");
      }
    }
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [selfieImg]);

  const handleSubmit = () => {
    if (isSubmit) {
      setLoading(true);
      if (selfieImg) {
        setErrMsg("");
      } else {
        setErrMsg("Please click a selfie");
        return;
      }

      //Form data
      const formData = new FormData();
      // formData.append("phone", 9988998840);
      formData.append("phone", Number(visitorPhone));
      formData.append("firstName", visitorFormData.firstName);
      formData.append("lastName", visitorFormData.lastName);
      formData.append("companyName", visitorFormData.companyName);
      formData.append("address", visitorFormData.Address);
      formData.append("purposeOfVisit", visitorFormData.Purpose);
      if (companyData.cardCheck) {
        if (visitorFormData["card_no"]) {
          formData.append("cardNo", visitorFormData["card_no"]);
        }
      }

      if (companyData.accessoryCheck) {
        if (visitorFormData["laptop_check"]) {
          formData.append("isLaptop", visitorFormData["laptop_check"]);
          if (visitorFormData["laptop_serial_no"]) {
            formData.append(
              "laptopSerial",
              visitorFormData["laptop_serial_no"]
            );
          }
        }

        if (visitorFormData["mobile_check"]) {
          formData.append("isMobile", visitorFormData["mobile_check"]);
          if (visitorFormData["mobile_serial_no"]) {
            formData.append(
              "mobileSerial",
              visitorFormData["mobile_serial_no"]
            );
          }
        }

        if (visitorFormData["hard_disk_check"]) {
          formData.append("isHardDisk", visitorFormData["hard_disk_check"]);
          if (visitorFormData["hard_disk_serial_no"]) {
            formData.append(
              "hardDiskSerial",
              visitorFormData["hard_disk_serial_no"]
            );
          }
        }

        if (visitorFormData["charger_check"]) {
          formData.append("isCharger", visitorFormData["charger_check"]);
          if (visitorFormData["charger_serial_no"]) {
            formData.append(
              "chargerSerial",
              visitorFormData["charger_serial_no"]
            );
          }
        }

        if (visitorFormData["other_accessory_check"]) {
          formData.append(
            "isOtherAccessory",
            visitorFormData["other_accessory_check"]
          );
          if (visitorFormData["other_accessory_serial_no"]) {
            formData.append(
              "otherAccessorySerial",
              visitorFormData["other_accessory_serial_no"]
            );
          }
        }
      }

      formData.append("empPhoneNumber", visitorFormData.meeting_person_phone);
      formData.append("empName", visitorFormData.meeting_person_name);
      formData.append(
        "empDesignation",
        visitorFormData.meeting_person_designation
      );
      formData.append(
        "empDepartment",
        visitorFormData.meeting_person_department
      );

      // formData.append("selfie", selfie.base64);
      // formData.append("docFront", doc1.base64);

      // if (doc2) {
      //   formData.append("docBack", doc2.base64);
      // }
      if (typeof selfieImg == "string") {
        formData.append("existingSelfie", selfieImg);
      } else {
        formData.append("selfieFile", selfieImg.file);
      }

      formData.append("isRepeated", isRepeated);
      formData.append("companyId", companyData._id);
      if (visitorFormData.locationId) {
        formData.append("locationId", visitorFormData.locationId);
      }

      formData.append("lat", visitorFormData.lat);
      formData.append("long", visitorFormData.long);

      formData.append("smsCount", 0);
      formData.append("otpSent", OTPTime);
      formData.append("checkedIn", true);
      formData.append("passType", "Normal");

      axios
        .post(`${base_url}/visitor/add`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          navigate("/thank-you");
          setLoading(false);
          ClearData();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(
            "Your request is taking longer than usual, Please check your internet connection and try again.",
            {
              position: "top-center",
            }
          );
          axios.post(
            "https://digipass-logger-default-rtdb.firebaseio.com/logs.json",
            {
              error: err,
              device: DeviceDetails,
              issueTime: new Date(),
              payload: {
                phone: visitorPhone,
                visitorFormData: visitorFormData,
                selfie:
                  JSON.stringify(selfieImg["file"]["name"]) || "No Selfie",
                covidQues: "Without covid questions",
              },
            }
          );
        });
      // SubmitForm(visitorFormData, selfie.file, doc1.file, doc2.file);
    } else {
      if (companyData.validId) {
        if (selfieImg) {
          navigate("/govt-id");
        } else {
          setErrMsg("Please click a selfie");
        }
      } else {
        if (selfieImg) {
          navigate("/covid-questions");
        } else {
          setErrMsg("Please click a selfie");
        }
      }
    }
  };

  return (
    <>
      <div className="wrapper-box">
        <div className="container">
          <div className="wrapper-mid" style={{ background: "none" }}>
            <div className="selfie-panel">
              <div className="selfietop-panel">
                <h2>
                  {pageHeading || "Please take your selfie to proceed further"}
                </h2>
              </div>
              <div className="selfie-bootom">
                <div className="camara-pic">
                  {!selfieImg && (
                    <img
                      src={require("../../../assets/img/visitor-images/images/selfie-img.png")}
                    />
                  )}
                  {selfieImg && (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                        "--webkit-transform":
                          facingMode == "user" ? "scaleX(-1)" : "scaleX(1)",
                        transform:
                          facingMode == "user" ? "scaleX(-1)" : "scaleX(1)",
                      }}
                      src={
                        typeof selfieImg == "string"
                          ? selfieImg
                          : selfieImg.base64
                      }
                    />
                  )}
                </div>

                <span className={classes.errMsg}>{errMsg}</span>
                <div className={classes.camera}>
                  <div>
                    <Link
                      to="/camera"
                      onClick={() =>
                        dispatch(visitorActions.setActiveCamera("selfie"))
                      }
                    >
                      <img
                        src={require("../../../assets/img/visitor-images/camera.png")}
                      />
                    </Link>
                  </div>
                </div>
                <div className={classes.btn}>
                  <button
                    className={classes.back}
                    onClick={() => navigate("/visitor-details")}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className={classes.next}
                    onClick={handleSubmit}
                  >
                    {/* Next */}
                    {/* <Link to="/govt-id">Next</Link> */}
                    {loading ? (
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      buttonText
                    )}
                  </button>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Selfie;
