import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import visitorActions from "../../../ReduxStore/visitorSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BackButton from "../BackButton/BackButton";
import Footer from "../Footer/Footer";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");
const CheckInCheckOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isCheckoutLoading, setCheckOutLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [subscriptionErr, setSubscriptionErr] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [newWidth, setNewWidth] = useState("80");
  const [newHeight, setNewHeight] = useState("80");
  const [locationRequired, setLocationRequired] = useState(false);
  const location = useLocation();
  const companyId = location.pathname.substring(9, location.pathname.length);
  console.log("companyId", companyId);
  const companyData = useSelector((state) => state.visitorData.companyData);
  //Todays changes
  useEffect(() => {
    // 64019334c5873c36bcafccb5
    // 640076ca0d26652a18de2129
    // 640076ca0d26652a18de2129
    // http://localhost:3000/visitor/company/details/63f6f30204d53824f24bcf8a
    axios(`${base_url}/visitor/company/details/${companyId}`)
      .then((res) => {
        dispatch(visitorActions.setCompanyData({ ...res.data.data }));
        localStorage.setItem(
          "companyData",
          JSON.stringify({ ...res.data.data })
        );
        setDisabled(false);
        const startDate = moment(
          res.data.data.subscriptionStartDate.substring(0, 10),
          "YYYY-MM-DD"
        );
        const endDate = moment(
          res.data.data.subscriptionEndDate.substring(0, 10),
          "YYYY-MM-DD"
        );
        const currentDate = moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD");

        if (currentDate.isBefore(startDate)) {
          setDisabled(false);
          setSubscriptionErr("Subscription is yet to start");
          toast.error("Subscription is yet to start", {
            position: "top-center"
          });
          return;
        }

        if (currentDate.isAfter(endDate)) {
          setDisabled(false);
          setSubscriptionErr("Subscription is expired");
          toast.error("Subscription is expired", {
            position: "top-center"
          });
          return;
        }

        //Get user location

        if (res.data.data["geoLocation"]) {
          setLocationRequired(true);
          navigator.geolocation.getCurrentPosition((pos) => {
            setLat(pos.coords.latitude);
            setLon(pos.coords.longitude);
            localStorage.setItem(
              "visitorFormData",
              JSON.stringify({
                lat: pos.coords.latitude,
                long: pos.coords.longitude
              })
            );
          });
        }
      })
      .catch((err) => {
        setDisabled(true);
      });
  }, []);

  const handleCheckin = () => {
    if (locationRequired && (lat == "" || lat == "undefined")) {
      toast.dismiss();
      toast.error("Please allow location to move forward", {
        position: "top-center"
      });
      return;
    }

    if (subscriptionErr) {
      toast.dismiss();
      toast.error(subscriptionErr, {
        position: "top-center"
      });
      return;
    }
    dispatch(visitorActions.setJourneyType("Checkin"));
    localStorage.setItem("journeyType", "Checkin");
    navigate("/visitor-phone");
  };

  const handleCheckout = () => {
    if (locationRequired && (lat == "" || lat == "undefined")) {
      toast.error("Please allow location to move forward", {
        position: "top-center"
      });
      return;
    }

    if (subscriptionErr) {
      toast.error(subscriptionErr, {
        position: "top-center"
      });
      return;
    }
    dispatch(visitorActions.setJourneyType("Checkout"));
    localStorage.setItem("journeyType", "Checkout");
    navigate("/visitor-phone");
  };

  var image = new Image();
  image.src = companyData?.logo;
  image.onload = function () {
    // Get the height and width
    var height = image.naturalHeight;
    var width = image.naturalWidth;

    console.log("Image height:", height);
    console.log("Image width:", width);
    const aspectRatio = width / height;

    if (aspectRatio > 1.7) {
      setNewWidth("160");
      setNewHeight("60");
    } else {
      setNewWidth("80");
      setNewHeight("80");
    }
  };
  return (
    <>
      {/* <div className="wrapper-box">
        <div className="container">
          <div
            className="wrapper-mid d-flex flex-column justify-content-center align-items-center"
            style={{ background: "none" }}
          >
            <button
              className="btn btn-primary submit-btn w-50"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Check IN"
              )}
            </button>
            <button
              className="btn btn-primary submit-btn w-50"
              onClick={handleCheckout}
            >
              {isCheckoutLoading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Check Out"
              )}
            </button>
          </div>
        </div>
      </div> */}

      <div className="wrapper-box z1">
        <div className="container z2" style={{ position: "relative" }}>
          {/* <div className="app-panel"> */}
          <div
            className="bg-special z3"
            // style={{
            //   backgroundColor: "rgb(10 81 170 / 95%)",
            // }}
          ></div>
          <div className="bg-ellipes-left z3"></div>
          <div className="bg-ellipes-right z3"></div>
          <div className="wrapper-mid z4">
            <div className="logo-div">
              <img
                src={require("../../../assets/img/visitor-images/images/logo.png")}
              />
            </div>

            <div className="text-logo">
              <h1>Digi-Pass</h1>
              <p style={{ fontSize: "21px", textTransform: "none" }}>
                Visitor Management System
              </p>
            </div>
            <div className="logo-res">
              {companyData ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img
                    className="logo-parter-img"
                    style={{
                      width: `${newWidth}px`,
                      height: `${newHeight}px`,
                      display: "inline-block",
                      margin: "0 auto"
                    }}
                    src={companyData?.logo}
                  />
                </div>
              ) : (
                <div role="status" class="spinner-border text-light">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
            <div className="input-panel">
              {/* <h2>Please enter your valid Mobile Number</h2> */}
              <div
                className="wrapper-mid d-flex flex-column justify-content-center align-items-center"
                style={{ background: "none" }}
              >
                <button
                  disabled={disabled}
                  className="btn btn-primary submit-btn w-80 bg-success text-light btn-checkin"
                  onClick={handleCheckin}
                >
                  {isLoading ? (
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "CHECK-IN"
                  )}
                </button>
                <button
                  disabled={disabled}
                  className="btn btn-primary submit-btn w-80 bg-danger text-light btn-checkin"
                  onClick={handleCheckout}
                >
                  {isCheckoutLoading ? (
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "CHECK-OUT"
                  )}
                </button>
              </div>
            </div>
            <Footer />
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default CheckInCheckOut;
