import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authContext from "../../../../AuthContext/authContext";
import appActions from "../../../../ReduxStore/appDataSlice";
import Home from "../Home/Home";
import Table from "../../Table/Table";
import classes from "../../Table/Table.module.css";
import axios from "axios";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");
const VisitorWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const userName = ctx?.userDetailedData?.name;
  console.log("Context value in visitor wrapper", ctx);
  const userType = ctx.userData.user_type;
  const sortDirection = useSelector((state) => state.appData.sortDirection);
  const [isLoading, setIsloading] = useState(false);
  const dateSort = (rowA, rowB) => {
    dispatch(appActions.setSortDirection(sortDirection === "1" ? "-1" : "1"));
    localStorage.setItem("sortDirection", sortDirection === "1" ? "-1" : "1");
  };

  const isHidden = userType == "admin" || userType == "subadmin";

  const maskText = (text) => {
    text = String(text);
    if (text.length <= 2) {
      return "*".repeat(text.length);
    }
    const maskedText = text.slice(0, 3) + "*".repeat(text.length - 3);
    return maskedText;
  };

  const handleApproveReject = (Id, status, empName, phone, currentStatus) => {
    if (currentStatus !== "pending") {
      return;
    }
    setIsloading(true);
    let reqObj = {
      method: "POST",
      url: `${base_url}/visitor/emp-decision`,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        visitorId: Id,
        status: status
      }
    };
    axios(reqObj)
      .then((res) => {
        toast.success(`Successfully ${status} request`);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to update`);
        setIsloading(false);
      });
    // /visitor/end-meeting
    if (status == "rejected") {
      axios
        .put(`${base_url}/visitor/check-out/${Number(phone)}`, {
          checkedoutBy: `${userName}(Admin)`
        })
        .then((visitor) => {});
    }
  };

  const approveAll = (isEmpty, status) => {
    setIsloading(true);
    if (isEmpty == "empty") {
      toast.dismiss();
      toast.error("There are no visitors");
      setIsloading(false);
      return;
    }

    let data = {
      status: status,
      companyId: activeCompany["_id"]
    };
    if (activeLocation) {
      if (activeLocation["_id"] !== "All") {
        data.locationId = activeLocation["_id"];
      }
    }
    let reqObj = {
      method: "PUT",
      url: `${base_url}/visitor/approve-all`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`
      },
      data: data
    };

    axios(reqObj)
      .then((res) => {
        toast.dismiss();
        toast.success(`Request ${status} for all visitors`);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss();
        toast.error(
          err.response.data.error.message || `Failed to set status to ${status}`
        );
        setIsloading(false);
      });
  };

  const columns = [
    {
      id: "srNo",
      name: "Visitor ID",
      cell: (row, index) => row._id
    },
    {
      id: "passType",
      name: "Pass Type",
      selector: (row) => {
        if (row.passType == "Delegate" || row.passType == "Vendor") {
          return row.passType;
        } else {
          return "Normal";
        }
      },
      sortable: false
      // sortFunction: dateSort,
    },
    {
      id: "date",
      name: "Request Date & Time",
      selector: (row) => {
        const formatedDate = moment(row.createdAt).format("MMM D, hh:mm A");
        return formatedDate;
      },
      sortable: false,
      sortFunction: dateSort
    },
    {
      name: "User/Company Name",
      selector: (row) => {
        // isHidden
        // ? maskText(`${row.firstName}-${row.companyName}`)
        // : `${row.firstName}-${row.companyName}`,
        if (row.firstName && row.companyName) {
          if (isHidden) {
            return maskText(`${row.firstName}-${row.companyName}`);
          } else {
            return `${row.firstName}-${row.companyName}`;
          }
        } else {
          if (isHidden) {
            return maskText(`${row.firstName}`);
          } else {
            return `${row.firstName}`;
          }
        }
      },

      sortable: true
    },
    {
      name: "Meeting Person/Designation",
      selector: (row) => {
        console.log("row", row);
        if (row.empName || row.empDesignation) {
          return `${row.empName ? row.empName : ""} ${
            row.empDesignation ? "-" + row.empDesignation : ""
          }`;
        } else {
          return "NA";
        }
      },
      // row.empDesignation == "undefined"
      //   ? `${row.empName}`
      //   : `${row.empName}-${row.empDesignation}`,
      sortable: true
    },
    {
      name: "Location",
      selector: (row) => (isHidden ? maskText(row.location) : row.location),
      sortable: true
    },
    {
      name: "Meeting Purpose",
      selector: (row) => (row.purposeOfVisit ? row.purposeOfVisit : "NA"),
      sortable: true
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {row.status == "pending" && (
            <div className={classes.icons}>
              <button
                className="btn btn-success m-2"
                onClick={() =>
                  handleApproveReject(
                    row._id,
                    "approved",
                    row.empName,
                    row.phone,
                    row.status
                  )
                }
              >
                Approve
              </button>
              <button
                className="btn btn-danger m-2"
                onClick={() =>
                  handleApproveReject(
                    row._id,
                    "rejected",
                    row.empName,
                    row.phone,
                    row.status
                  )
                }
              >
                Reject
              </button>
            </div>
          )}
          {row.status !== "pending" && "NA"}
        </>
      ),
      width: "200px"
    }
  ];

  let showCsvExport = false;
  let showDownload = true;
  let showExport = false;
  let showLocation = true;
  let showSms = true;
  let showAddNew = false;
  let showFirstHr = false;
  let showSecondHr = false;
  let showActions = true;
  let showActiveVisitors = true;

  if (
    userType === "Company Manager" ||
    userType === "location" ||
    userType === "Building Manager"
  ) {
    showCsvExport = false;
    showDownload = true;
    showExport = false;
    showLocation = false;
    showSms = false;
    showAddNew = false;
    showFirstHr = false;
    showSecondHr = false;
    showActions = true;
  }
  if (userType === "Building Manager") {
    showLocation = true;
  }

  if (userType == "subadmin") {
    showDownload = false;
    showActiveVisitors = false;
  }

  if (userType == "admin") {
    showActiveVisitors = false;
  }

  const options = {
    title: "Visitor",
    showCsvExport: showCsvExport,
    showDownload: showDownload,
    showExport: showExport,
    showLocation: showLocation,
    showSms: showSms,
    showAddNew: showAddNew,
    showFirstHr: showFirstHr,
    showSecondHr: showSecondHr,
    showActions: showActions,
    showActiveVisitors: showActiveVisitors,
    type: "Visitor",
    columns: columns,
    apiUrl: `${base_url}/visitor/list`,
    companyId: activeCompany._id, //for building company Id and Building Id is same
    locationId: userType === "location" ? activeLocation._id : ""
  };
  return (
    <>
      <Home
        propComponent={
          <Table
            options={options}
            approveAll={approveAll}
            isLoading={isLoading}
          />
        }
      />
    </>
  );
};

export default VisitorWrapper;
