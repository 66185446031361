import classes from "../../Employee/AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import React, { useContext, useEffect, useState } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import authContext from "../../../../AuthContext/authContext";
import ReactFileReader from "react-file-reader";
import axios from "axios";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const Terms = ({ initialData }) => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(initialData.terms || "")
      )
    )
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [termsFile, setTermsFile] = useState();
  const [downloadUrl, setDownloadUrl] = useState(initialData["termsFile"]);
  const [fileName, setFileName] = useState(
    initialData["termsFile"]
      ? `${initialData["termsFile"].substring(
          initialData["termsFile"].lastIndexOf("/") + 1
        )}`
      : ""
  );
  const [loading, setLoading] = useState(false);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log("Editor text", convertToHTML(editorState.getCurrentContent()));
    if (convertToHTML(editorState.getCurrentContent()) == "<p></p>") {
      toast.error("Please enter terms and conditions");
      return;
    }
    const formData = new FormData();
    formData.append("companyId", activeCompany._id);
    formData.append("terms", convertToHTML(editorState.getCurrentContent()));
    if (termsFile) {
      formData.append("termsFile", termsFile);
    }

    axios
      .post(`${base_url}/company/terms/save`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        toast.success("Terms and conditions updated successfully");
        let url = res.data["data"]["termsFile"];
        setDownloadUrl(url);
        setFileName(`${url.substring(url.lastIndexOf("/") + 1)}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err.response.data.error.message ||
            "Failed to update terms and conditions"
        );
        setLoading(false);
      });

    // let reqObj = {
    //   method: "POST",
    //   url: `${base_url}/company/terms/save`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   data: {
    //     companyId: activeCompany._id,
    //     terms: convertToHTML(editorState.getCurrentContent()),
    //   },
    // };

    // axios(reqObj)
    //   .then((res) => {
    //     console.log(res);
    //     toast.success("Terms and conditions updated successfully");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toast.error(
    //       err.response.data.error.message ||
    //         "Failed to update terms and conditions"
    //     );
    //   });
  };

  const handleFile = (file) => {
    // setPreview(file.base64);
    // formik.setFieldValue("logo", file.fileList[0]);
    console.log(file.fileList[0]);
    setFileName(file.fileList[0].name);
    setTermsFile(file.fileList[0]);
  };

  function downloadFile(url, fileName) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.target = "_blank";
        link.click();
      });
  }

  const handleDownload = () => {
    // window.location.href = downloadUrl;
    // window.open(downloadUrl, "_blank");
    downloadFile(downloadUrl, fileName);
  };

  return (
    <>
      <div className={classes.mainCont}>
        <h3>Terms & Conditions</h3>

        <section className={classes.Terms}>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorStyle={{
              backgroundColor: "#FFFFFF",
            }}
            editorClassName={classes.Editor}
            toolbarClassName="toolbar-class"
          />
        </section>

        <div className={classes.fileDiv}>
          <ReactFileReader
            name="logo"
            fileTypes={[
              ".png",
              ".jpg",
              ".jpeg",
              ".pdf",
              ".doc",
              ".docm",
              ".docx",
            ]}
            handleFiles={handleFile}
            // handleBlur={formik.handleBlur}
            base64={true}
          >
            <span className={classes.uploadBtn}>
              Upload{" "}
              <img
                src={require("../../../../assets/img/admin-images/upload.png")}
              />
            </span>
          </ReactFileReader>

          <div className={classes.downloadDiv}>
            {fileName && (
              <span onClick={handleDownload} style={{ marginLeft: "5px" }}>
                {fileName}
              </span>
            )}
            {downloadUrl && (
              <img
                style={{ width: "25px", height: "25px" }}
                src={require("../../../../assets/img/admin-images/downloads-icon.png")}
                onClick={handleDownload}
              />
            )}
          </div>
        </div>
        {/* <div>{fileName}</div> */}

        <div className={classes.formBtn} style={{ marginTop: "1rem" }}>
          <button className={classes.createBtn} onClick={handleSubmit}>
            {loading && (
              <div
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default Terms;
