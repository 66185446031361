import { useDispatch, useSelector } from "react-redux";
import Home from "../Home/Home";
import CreatePass from "./CreatePass";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import authContext from "../../../../AuthContext/authContext";
import { toast } from "react-toastify";
import { useEffect } from "react";

const CreatePassWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const token = ctx.token;
  console.log("Context value in visitor wrapper", ctx);
  const userType = ctx.userData.user_type;
  useEffect(() => {
    if (
      userType === "Company Manager" ||
      userType === "location" ||
      userType === "Building Manager" ||
      userType === "company"
    ) {
      let showCreatePass =
        !(userType === "admin" || userType === "subadmin") &&
        (activeCompany["delagateCheck"] || activeCompany["vendorCheck"]);
      if (!showCreatePass) {
        toast.error("Special Visitor is disabled");
        navigate("/dashboard");
      }
    }
  }, []);
  return (
    <>
      <Home propComponent={<CreatePass />} />
    </>
  );
};

export default CreatePassWrapper;
