import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton/BackButton";
import Footer from "../Footer/Footer";
import classes from "./Terms.module.css";
import { convertToHTML } from "draft-convert";
import { useSelector } from "react-redux";
const base_url = process.env.REACT_APP_BASE_URL;
const Terms = () => {
  const companyData = useSelector((state) => state.visitorData.companyData);
  const navigate = useNavigate();
  const [domloaded, setDomloaded] = useState(false);
  const [terms, setTerms] = useState();
  const [donwloadUrl, setDonwloadUrl] = useState();

  useEffect(() => {
    axios(`${base_url}/visitor/company/terms/${companyData._id}`)
      .then((res) => {
        setTerms(res.data.terms);
        setDonwloadUrl(res.data["termsFile"]);
        setDomloaded(true);
      })
      .catch((err) => {
        // formik.setFieldError("phone", "Invalid company Id");
        setDomloaded(true);
      });
  }, []);

  function downloadFile(url, fileName) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.target = "_blank";
        link.click();
      });
  }

  const handleDownload = () => {
    // window.location.href = downloadUrl;
    // window.open(downloadUrl, "_blank");
    downloadFile(
      donwloadUrl,
      `${donwloadUrl.substring(donwloadUrl.lastIndexOf("/") + 1)}`
    );
  };
  return (
    <>
      {domloaded && (
        <div className="wrapper-box">
          <div className="container">
            <div className="wrapper-mid" style={{ background: "#fff" }}>
              <div className="term-div">
                <h1 className="term-head" style={{ textAlign: "center" }}>
                  Terms & Conditions
                </h1>
                <hr className={classes.hr2} />

                <BackButton
                  path="/visitor-otp-verification"
                  className="back-btn"
                />
                <section>
                  <div
                    className="term-con-div"
                    dangerouslySetInnerHTML={{ __html: terms }}
                  />
                </section>
                {/* Download link here */}
                {donwloadUrl && (
                  <div onClick={handleDownload} className={classes.downloadBtn}>
                    <span>
                      <img
                        src={require("../../../assets/img/admin-images/terms_logo.png")}
                      />
                      Download
                    </span>
                  </div>
                )}
                <Footer footerColor={"black"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Terms;
