import classes from "../SmsConfiguration/SmsConfiguration.module.css";
import btnClasses from "../../Employee/AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useContext } from "react";
import axios from "axios";
import authContext from "../../../../AuthContext/authContext";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import EmailToModal from "./EmailToModal";
const base_url = process.env.REACT_APP_BASE_URL;

const MailConfiguration = ({ initialData }) => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [loading, setLoading] = useState(false);
  const [mailStatus, setMailStatus] = useState(false);
  let initialValues;
  if (initialData) {
    initialValues = {
      mailServerUrl: initialData.mailServerUrl,
      mailFrom: initialData.mailFrom,
      EmployeApprovalSubject: initialData.EmployeApprovalSubject,
      username: initialData.username,
      password: initialData.password,
      EmployeApprovalMailContent: initialData.EmployeApprovalMailContent,
      port: initialData.port,
      tls_ssl: initialData.tls_ssl,
      tls_ssl_version: initialData.tls_ssl_version
    };
  } else {
    initialValues = {
      mailServerUrl: "",
      mailFrom: "",
      EmployeApprovalSubject: "",
      username: "",
      password: "",
      EmployeApprovalMailContent: "",
      port: "",
      tls_ssl: false,
      tls_ssl_version: ""
    };
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      mailServerUrl: Yup.string().required("Please enter mail server url"),
      mailFrom: Yup.string(),
      EmployeApprovalSubject: Yup.string(),
      username: Yup.string().required("Please enter username"),
      password: Yup.string().required("Please enter password"),
      EmployeApprovalMailContent: Yup.string(),
      port: Yup.string().required("Please enter port"),
      tls_ssl_version: Yup.string(),
      tls_ssl: Yup.boolean().required("Please enter ssl/tls")
    }),
    onSubmit: (values) => {
      setLoading(true);

      const reqData = {
        mailServerUrl: values.mailServerUrl,
        //   mailFrom: values.mailFrom,
        username: values.username,
        password: values.password,
        port: values.port,
        tls_ssl: values.tls_ssl,
        companyId: activeCompany["_id"]
      };

      if (values.EmployeApprovalSubject) {
        reqData["EmployeApprovalSubject"] = values.EmployeApprovalSubject;
      }
      if (values.EmployeApprovalMailContent) {
        reqData["EmployeApprovalMailContent"] =
          values.EmployeApprovalMailContent;
      }
      if (values.tls_ssl_version) {
        reqData["tls_ssl_version"] = values.tls_ssl_version;
      }

      let reqObj = {
        method: "POST",
        url: `${base_url}/mail/settings/save`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        data: reqData
      };

      axios(reqObj)
        .then((res) => {
          toast.success("Mail configured successfully!!");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  });

  const saveSettings = () => {
    // let reqObj = {
    //   method: "POST",
    //   url: `${base_url}/mail/settings/save`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`
    //   },
    //   data: {
    //     mailServerUrl: values.mailServerUrl,
    //     mailFrom: values.mailFrom,
    //     EmployeApprovalSubject: values.EmployeApprovalSubject,
    //     username: values.username,
    //     password: values.password,
    //     EmployeApprovalMailContent: values.EmployeApprovalMailContent,
    //     port: values.port,
    //     tls_ssl: values.tls_ssl,
    //     tls_ssl_version: values.tls_ssl_version,
    //     companyId: activeCompany["_id"]
    //   }
    // };
    // axios(reqObj)
    //   .then((res) => {
    //     toast.success("Mail configured successfully!!");
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //   });
  };

  const sendTestEmail = (email_address) => {
    setMailStatus(true);
    let reqObj = {
      method: "POST",
      url: `${base_url}/visitor/send-test-email/${activeCompany["_id"]}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      data: {
        email_address: email_address
      }
    };

    axios(reqObj)
      .then((res) => {
        toast.success("Mail Sent successfully!!");
        document.getElementById("cancle-mail").click();
        setMailStatus(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Wrong mail configuration.");
        document.getElementById("cancle-mail").click();
        setMailStatus(false);
      });
  };

  return (
    <>
      <div className={classes.Container}>
        <h3>Mail Configuration</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.commonSetting}>
            <p>Mail Settings</p>
            <div className={classes.settingFields}>
              <div style={{ width: "95%" }}>
                <label>Mail Server Url*</label>
                <input
                  type="text"
                  id="mailServerUrl"
                  name="mailServerUrl"
                  {...formik.getFieldProps("mailServerUrl")}
                  style={{ width: "100%" }}
                />
                <span>
                  {formik.touched.mailServerUrl &&
                  formik.errors.mailServerUrl ? (
                    <div>{formik.errors.mailServerUrl}</div>
                  ) : null}
                </span>
              </div>
              {/* <div>
                <label>Mail From*</label>
                <input
                  type="text"
                  id="mailFrom"
                  name="mailFrom"
                  {...formik.getFieldProps("mailFrom")}
                />
                <span>
                  {formik.touched.mailFrom && formik.errors.mailFrom ? (
                    <div>{formik.errors.mailFrom}</div>
                  ) : null}
                </span>
              </div> */}
            </div>

            <div className={classes.settingFields}>
              <div>
                <label>Username*</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  {...formik.getFieldProps("username")}
                />
                <span>
                  {formik.touched.username && formik.errors.username ? (
                    <div>{formik.errors.username}</div>
                  ) : null}
                </span>
              </div>

              <div>
                <label>Password*</label>
                <input
                  type="text"
                  id="password"
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <span>
                  {formik.touched.password && formik.errors.password ? (
                    <div>{formik.errors.password}</div>
                  ) : null}
                </span>
              </div>
            </div>

            <div className={classes.settingFields}>
              <div>
                <label>Port*</label>
                <input
                  type="text"
                  id="port"
                  name="port"
                  {...formik.getFieldProps("port")}
                />
                <span>
                  {formik.touched.port && formik.errors.port ? (
                    <div>{formik.errors.port}</div>
                  ) : null}
                </span>
              </div>

              <div>
                <label>TLS/SSL version*</label>
                <select
                  type="text"
                  id="tls_ssl_version"
                  name="tls_ssl_version"
                  {...formik.getFieldProps("tls_ssl_version")}
                >
                  <option value="">Select</option>
                  <option value="TLSv1.2">TLSv1_2</option>
                  <option value="TLSv1.3">TLSv1_3</option>
                </select>
                <span>
                  {formik.touched.tls_ssl_version &&
                  formik.errors.tls_ssl_version ? (
                    <div>{formik.errors.tls_ssl_version}</div>
                  ) : null}
                </span>
              </div>
            </div>

            <div className={classes.settingFields}>
              <div className="form-check form-switch ps-0 d-flex flex-row  mt-4">
                <label className="form-check-label" for="card_check">
                  Is Secure connection?*
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tls_ssl"
                  name="tls_ssl"
                  {...formik.getFieldProps("tls_ssl")}
                  defaultChecked={formik.values.tls_ssl}
                  style={{
                    position: "static",
                    marginLeft: "1.5rem"
                  }}
                />
                <span>
                  {formik.touched.tls_ssl && formik.errors.tls_ssl ? (
                    <div>{formik.errors.tls_ssl}</div>
                  ) : null}
                </span>
              </div>
            </div>

            <div className={btnClasses.formBtn}>
              <button
                type="submit"
                className={btnClasses.createBtn}
                disabled={loading}
              >
                {loading && (
                  <div
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                Save
              </button>

              <button
                type="button"
                className={btnClasses.createBtn}
                disabled={loading}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                // onClick={() => sendTestEmail()}
              >
                Send test mail
              </button>
            </div>
          </div>

          <div className={`${classes.commonSetting} mt-3`}>
            <p>Employee Approval mail</p>
            <div className={classes.settingFields}>
              <div style={{ width: "100%" }}>
                <label>Mail Content*</label>
                <input
                  type="text"
                  id="EmployeApprovalMailContent"
                  name="EmployeApprovalMailContent"
                  {...formik.getFieldProps("EmployeApprovalMailContent")}
                  style={{ width: "95%" }}
                />
                <span>
                  {formik.touched.EmployeApprovalMailContent &&
                  formik.errors.EmployeApprovalMailContent ? (
                    <div>{formik.errors.EmployeApprovalMailContent}</div>
                  ) : null}
                </span>
              </div>
            </div>

            <div className={classes.settingFields}>
              <div style={{ width: "100%" }}>
                <label>Mail subject*</label>
                <input
                  type="text"
                  id="EmployeApprovalSubject"
                  name="EmployeApprovalSubject"
                  {...formik.getFieldProps("EmployeApprovalSubject")}
                  style={{ width: "95%" }}
                />
                <span>
                  {formik.touched.EmployeApprovalSubject &&
                  formik.errors.EmployeApprovalSubject ? (
                    <div>{formik.errors.EmployeApprovalSubject}</div>
                  ) : null}
                </span>
              </div>
            </div>
          </div>

          <div className={btnClasses.formBtn}>
            <button
              type="submit"
              className={btnClasses.createBtn}
              disabled={loading}
            >
              {loading && (
                <div
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              Submit
            </button>
          </div>
        </form>
      </div>
      <EmailToModal
        sendTestEmail={sendTestEmail}
        setMailStatus={setMailStatus}
        mailStatus={mailStatus}
      />
    </>
  );
};

export default MailConfiguration;
