import classes from "../../Employee/AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import dateClasses from "../../Company/AddNewCompany/AddNewCompany.module.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import authContext from "../../../../AuthContext/authContext";
import ReactFileReader from "react-file-reader";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import "../CreatePass/createPass.css";
import moment from "moment";

const base_url = process.env.REACT_APP_BASE_URL;
const EditSpecialPass = ({ initialData, onSuccess }) => {
  const ctx = useContext(authContext);
  const token = ctx.token;
  const userDetailedData = ctx.userDetailedData;
  const user_id = ctx.userData.user_id;
  const user_type = ctx.userData.user_type;
  const [locations, setLocations] = useState([]);
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const [IsDisabled, setIsDisabled] = useState(false);
  const [searchVal, setSearchVal] = useState({
    label: initialData.personToVisit || "",
    value: initialData.personToVisit || ""
  });

  console.log(" initialData[docFront] ", initialData);
  const [selfiePreview, setSelfiePreview] = useState(
    initialData["selfie"] || ""
  );
  const [docFrontPreview, setDocFrontPreview] = useState(
    initialData["docFront"] || ""
  );
  const [docBackPreview, setDocBackPreview] = useState(
    initialData["docBack"] || ""
  );
  const [visitingDate, setVisitingDate] = useState(
    initialData.visitingDate
      ? new Date(initialData.visitingDate?.slice(0, 10))
      : ""
  );
  const [validityStartDate, setValidityStartDate] = useState(
    initialData.validityStartDate
      ? new Date(initialData.validityStartDate?.slice(0, 10))
      : ""
  );
  const [validityEndDate, setValidityEndtDate] = useState(
    initialData.validityEndDate
      ? new Date(initialData.validityEndDate?.slice(0, 10))
      : ""
  );

  useEffect(() => {
    try {
      axios(`${base_url}/visitor/company/location/${activeCompany._id}`).then(
        (res) => {
          setLocations(res.data.location);
          console.log("Locations", res.data.location);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  let OneLocation = "";
  if (user_type == "location" || user_type == "company") {
    OneLocation = {
      _id: user_id,
      location: userDetailedData["location"]
    };
  }
  console.log("OneLocation", OneLocation);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const nameRegExp = /^[A-Za-z ]+$/;
  const locationRegExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\S]*$/;

  const formik = useFormik({
    initialValues: {
      passType: initialData.passType || "",
      passName: initialData.passName || "",
      location: initialData.location
        ? JSON.stringify({
            _id: initialData["locationId"],
            location: initialData["location"]
          })
        : OneLocation
        ? JSON.stringify({ ...OneLocation })
        : "",
      firstName: initialData.firstName || "",
      lastName: initialData.lastName || "",
      phone: initialData.phone || "",
      company_name: initialData.companyName || "",
      personToVisit: initialData.personToVisit || "",
      purposeOfVisit: initialData.purposeOfVisit || "",
      vendor_type: initialData.vendorType || "",
      visiting_date: visitingDate,
      validity_start_date: validityStartDate,
      validity_end_date: validityEndDate,
      selfieFile: undefined,
      docFrontFile: undefined,
      docBackFile: undefined,
      person_accompany: initialData.person_accompany || ""
    },
    validationSchema: Yup.object({
      passType: Yup.string().required("Please enter pass type"),
      passName: Yup.string().required("Please enter pass name"),
      firstName: Yup.string()
        .matches(nameRegExp, "Phone enter valid first name")
        .required("Phone enter first name"),
      lastName: Yup.string()
        .matches(nameRegExp, "Phone enter valid last name")
        .required("Phone enter last name"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter your phone number"),
      company_name: Yup.string(),
      personToVisit: Yup.string(),
      purposeOfVisit: Yup.string(),
      vendor_type: Yup.string(),
      visiting_date: Yup.string().when("passType", {
        is: "Delegate",
        then: () => Yup.string().required("Visiting date is required"),
        otherwise: () => Yup.string()
      }),
      validity_start_date: Yup.string().when("passType", {
        is: "Vendor",
        then: () => Yup.string().required("Validity start date is required"),
        otherwise: () => Yup.string()
      }),
      validity_end_date: Yup.string().when("passType", {
        is: "Vendor",
        then: () => Yup.string().required("Validity end date is required"),
        otherwise: () => Yup.string()
      }),
      location: Yup.string()
        .matches(locationRegExp, "Location cannot be a number")
        .required(
          `${
            user_type == "Company Manager"
              ? "Please Select Location"
              : "Please Select Company"
          }`
        ),
      selfieFile: Yup.mixed(),
      docFrontFile: Yup.mixed(),
      docBackFile: Yup.mixed(),
      person_accompany: Yup.string()
    }),
    onSubmit: (values) => {
      setIsDisabled(true);
      const formData = new FormData();
      formData.append("_id", initialData._id);
      formData.append("passType", values.passType);
      formData.append("passName", values.passName);
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("phone", values.phone);
      formData.append("checkedIn", true);
      if (values.passType == "Delegate") {
        if (values.visiting_date) {
          formData.append(
            "visitingDate",
            // values.visiting_date.toISOString().slice(0, 10)
            moment(values.visiting_date).format("YYYY-MM-DD")
          );
        }

        if (values.person_accompany) {
          formData.append("person_accompany", values.person_accompany);
        }
      }
      if (values.passType == "Vendor") {
        if (values.validity_start_date) {
          formData.append(
            "validityStartDate",
            // values.validity_start_date.toISOString().slice(0, 10)
            moment(values.validity_start_date).format("YYYY-MM-DD")
          );
        }

        if (values.validity_end_date) {
          formData.append(
            "validityEndDate",
            // values.validity_end_date.toISOString().slice(0, 10)
            moment(values.validity_end_date).format("YYYY-MM-DD")
          );
        }
        if (values.vendor_type) {
          formData.append("vendorType", values.vendor_type);
        }
      }
      if (values.company_name) {
        formData.append("companyName", values.company_name);
      }
      if (values.personToVisit) {
        formData.append("personToVisit", values.personToVisit);
      }
      if (values.purposeOfVisit) {
        formData.append("purposeOfVisit", values.purposeOfVisit);
      }
      if (values.selfieFile) {
        formData.append("selfieFile", values.selfieFile);
      }
      if (values.docFrontFile) {
        formData.append("docFrontFile", values.docFrontFile);
      }
      if (values.docBackFile) {
        formData.append("docBackFile", values.docBackFile);
      }

      if (user_type == "location" || user_type == "company") {
        formData.append("location", userDetailedData.location);
        formData.append("locationId", user_id);
      } else {
        let locationsObj = { ...JSON.parse(values.location) };
        formData.append("location", locationsObj.location);
        formData.append("locationId", locationsObj._id);
      }
      formData.append("companyId", activeCompany._id);
      // "multipart/form-data"
      // "application/json"
      axios
        .put(`${base_url}/visitor/updateSpecialVisitor`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          toast.dismiss();
          toast.success("Pass updated Successfully!!");
          setIsDisabled(false);
        })
        .catch((err) => {
          console.log(err);
          let errMsg = "";
          if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message
          ) {
            errMsg = err.response.data.error.message;
          } else {
            errMsg = "Opps...There seems to be a server error";
          }
          toast.dismiss();
          toast.error(errMsg);
          setIsDisabled(false);
        });
    }
  });

  const handleFile = (file, imgName) => {
    if (imgName == "selfie") {
      setSelfiePreview(file.base64);
      formik.setFieldValue("selfieFile", file.fileList[0]);
    }
    if (imgName == "docFront") {
      setDocFrontPreview(file.base64);
      formik.setFieldValue("docFrontFile", file.fileList[0]);
    }
    if (imgName == "docBack") {
      setDocBackPreview(file.base64);
      formik.setFieldValue("docBackFile", file.fileList[0]);
    }
  };
  const promiseOptions = async (searchStr) => {
    let location_id = "";
    if (user_type == "location" || user_type == "company") {
      location_id = user_id;
    } else {
      let locationsObj = { ...JSON.parse(formik.values.location) };
      location_id = locationsObj._id;
    }

    let reqObj = {
      method: "GET",
      url: `${base_url}/visitor/search/employeeNames/${activeCompany._id}/${location_id}/${searchStr}`,
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios(reqObj);
      let results = await res?.data?.data?.map((val) => {
        return {
          label: val.name,
          value: val.name,
          data: val
        };
      });

      return results;
    } catch (error) {}
  };

  const handleSearchChange = (res) => {
    setSearchVal({ label: res.data.name, value: res.data.name });
    // console.log("Seach val", res);
    formik.setFieldValue("personToVisit", res.data.name);
  };

  const handleFocus = () => {
    const divWithValue = document.getElementsByClassName(
      "css-1dimb5e-singleValue"
    )[0];
    divWithValue.style.display = "none";
  };

  const handleBlur = () => {
    document.getElementsByClassName(
      "css-1dimb5e-singleValue"
    )[0].style.display = "block";
  };

  return (
    <>
      <div className={classes.mainCont}>
        <h3>Update Pass</h3>

        <form className={classes.FormDiv} onSubmit={formik.handleSubmit}>
          <div className={classes.formGrp}>
            <label for="passType">Pass Type:</label>
            <div>
              <select
                type="text"
                id="passType"
                name="passType"
                {...formik.getFieldProps("passType")}
              >
                <option value="">Select</option>
                {activeCompany["delagateCheck"] && (
                  <option value="Delegate">Delegate</option>
                )}
                {activeCompany["vendorCheck"] && (
                  <option value="Vendor">Vendor</option>
                )}
              </select>
              <span className={classes.profileerrMsg}>
                {formik.touched.passType && formik.errors.passType ? (
                  <div>{formik.errors.passType}</div>
                ) : null}
              </span>
            </div>
          </div>

          {!OneLocation && (
            <div className={classes.formGrp}>
              <label>
                {user_type == "Company Manager"
                  ? "Select Location"
                  : "Select Company"}
              </label>
              <div>
                <select
                  placeholder={
                    user_type == "Company Manager"
                      ? "Select Location"
                      : "Select Company"
                  }
                  id="location"
                  name="location"
                  {...formik.getFieldProps("location")}
                >
                  <option value="">Select</option>
                  {locations &&
                    locations.map((loc) => {
                      return (
                        <option
                          value={JSON.stringify({
                            _id: loc._id,
                            location: loc.location
                          })}
                        >
                          {loc.location}
                        </option>
                      );
                    })}
                </select>
                <span className={classes.profileerrMsg}>
                  {formik.touched.location && formik.errors.location ? (
                    <div>{formik.errors.location}</div>
                  ) : null}
                </span>{" "}
              </div>
            </div>
          )}

          {formik.values.passType && formik.values.location && (
            <>
              <div className={classes.formGrp}>
                <label for="passName">Pass Name:</label>
                <div>
                  <input
                    type="text"
                    id="passName"
                    name="passName"
                    {...formik.getFieldProps("passName")}
                  />
                  <span className={classes.profileerrMsg}>
                    {formik.touched.passName && formik.errors.passName ? (
                      <div>{formik.errors.passName}</div>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className={classes.formGrp}>
                <label for="firstName">First Name:</label>
                <div>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                  />
                  <span className={classes.profileerrMsg}>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div>{formik.errors.firstName}</div>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className={classes.formGrp}>
                <label for="lastName">Last Name:</label>
                <div>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                  />
                  <span className={classes.profileerrMsg}>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div>{formik.errors.lastName}</div>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className={classes.formGrp}>
                <label for="lastName">Phone:</label>
                <div>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    {...formik.getFieldProps("phone")}
                  />
                  <span className={classes.profileerrMsg}>
                    {formik.touched.phone && formik.errors.phone ? (
                      <div>{formik.errors.phone}</div>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className={classes.formGrp}>
                <label for="company_name">Company Name:</label>
                <div>
                  <input
                    type="text"
                    id="company_name"
                    name="company_name"
                    {...formik.getFieldProps("company_name")}
                  />
                  <span className={classes.profileerrMsg}>
                    {formik.touched.company_name &&
                    formik.errors.company_name ? (
                      <div>{formik.errors.company_name}</div>
                    ) : null}
                  </span>
                </div>
              </div>
              {formik.values.passType == "Delegate" && (
                <div className={classes.formGrp}>
                  <label for="purposeOfVisit">Person Accompanying:</label>
                  <div>
                    <input
                      type="text"
                      id="person_accompany"
                      name="person_accompany"
                      {...formik.getFieldProps("person_accompany")}
                    />
                    <span className={classes.profileerrMsg}>
                      {formik.touched.person_accompany &&
                      formik.errors.person_accompany ? (
                        <div>{formik.errors.person_accompany}</div>
                      ) : null}
                    </span>
                  </div>
                </div>
              )}
              {formik.values.passType == "Delegate" && (
                <div className={classes.formGrp}>
                  <label for="purposeOfVisit">Purpose Of Visit:</label>
                  <div>
                    <input
                      type="text"
                      id="purposeOfVisit"
                      name="purposeOfVisit"
                      {...formik.getFieldProps("purposeOfVisit")}
                    />
                    <span className={classes.profileerrMsg}>
                      {formik.touched.purposeOfVisit &&
                      formik.errors.purposeOfVisit ? (
                        <div>{formik.errors.purposeOfVisit}</div>
                      ) : null}
                    </span>
                  </div>
                </div>
              )}
              {/* {formik.values.passType == "Delegate" && (
                <div className={classes.formGrp}>
                  <label for="personToVisit">Person To Visit:</label>
                  <div>
                    <input
                      type="text"
                      id="personToVisit"
                      name="personToVisit"
                      {...formik.getFieldProps("personToVisit")}
                    />
                    <span className={classes.profileerrMsg}>
                      {formik.touched.personToVisit &&
                      formik.errors.personToVisit ? (
                        <div>{formik.errors.personToVisit}</div>
                      ) : null}
                    </span>
                  </div>
                </div>
              )} */}
              {formik.values.passType == "Delegate" &&
                activeCompany["employeeDirectory"] && (
                  <div className="select-search-admin-cont">
                    <label>Person To Visit</label>

                    <AsyncSelect
                      className="select-search-admin"
                      cacheOptions
                      placeholder="Select an option"
                      defaultValue={searchVal}
                      value={searchVal}
                      id="personToVisit"
                      name="personToVisit"
                      onChange={(val) => handleSearchChange(val)}
                      loadOptions={promiseOptions}
                      // isSearchable
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      styles={{
                        placeholder: (base) => ({
                          ...base,
                          fontSize: "1em",
                          color: "black",
                          fontWeight: 400
                        })
                      }}
                    />

                    <span className={classes.profileerrMsg}>
                      {formik.touched.personToVisit &&
                      formik.errors.personToVisit ? (
                        <div>{formik.errors.personToVisit}</div>
                      ) : null}
                    </span>
                  </div>
                )}
              {formik.values.passType == "Delegate" &&
                !activeCompany["employeeDirectory"] && (
                  <div className={classes.formGrp}>
                    <label for="personToVisit">Person To Visit:</label>
                    <div>
                      <input
                        type="text"
                        id="personToVisit"
                        name="personToVisit"
                        {...formik.getFieldProps("personToVisit")}
                      />
                      <span className={classes.profileerrMsg}>
                        {formik.touched.personToVisit &&
                        formik.errors.personToVisit ? (
                          <div>{formik.errors.personToVisit}</div>
                        ) : null}
                      </span>
                    </div>
                  </div>
                )}
              {formik.values.passType == "Delegate" && (
                <div className={classes.formGrp}>
                  <label for="personToVisit">Visiting Date:</label>
                  <span>
                    <span className={dateClasses.dateDiv}>
                      <DatePicker
                        id="visiting_date"
                        name="visiting_date"
                        // dateFormatCalendar="YYYY"
                        showYearDropdown
                        minDate={new Date()}
                        placeholderText="Visiting Date"
                        selected={visitingDate}
                        onChange={(date) => {
                          setVisitingDate(date);
                          formik.setFieldValue("visiting_date", date);
                        }}
                        onBlur={formik.handleBlur}
                        value={visitingDate}
                        className={dateClasses.customDate}
                      />
                    </span>
                    <span className={classes.profileerrMsg}>
                      {formik.touched.visiting_date &&
                      formik.errors.visiting_date ? (
                        <div>{formik.errors.visiting_date}</div>
                      ) : null}
                    </span>
                  </span>
                </div>
              )}
              {formik.values.passType == "Vendor" && (
                <>
                  <div className={classes.formGrp}>
                    <label for="passName">Vendor Type:</label>
                    <div>
                      <input
                        type="text"
                        id="vendor_type"
                        name="vendor_type"
                        {...formik.getFieldProps("vendor_type")}
                      />
                      <span className={classes.profileerrMsg}>
                        {formik.touched.vendor_type &&
                        formik.errors.vendor_type ? (
                          <div>{formik.errors.vendor_type}</div>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    className={`${classes.formGrp}`}
                    style={{ display: "flex", margin: "0.8rem 0 0.8rem 0" }}
                  >
                    <label for="subscription">Validity:</label>
                    <span>
                      <span className={dateClasses.dateDiv}>
                        <DatePicker
                          id="validity_start_date"
                          name="validity_start_date"
                          // dateFormatCalendar="YYYY"
                          showYearDropdown
                          minDate={new Date()}
                          placeholderText="Start Date"
                          selected={validityStartDate}
                          onChange={(date) => {
                            setValidityStartDate(date);
                            formik.setFieldValue("validity_start_date", date);
                          }}
                          onBlur={formik.handleBlur}
                          value={validityStartDate}
                          className={dateClasses.customDate}
                        />

                        <div
                          style={{
                            width: "10%",
                            height: "0px",
                            backgroundColor: "black",
                            border: "1px solid black",
                            margin: "0 12px 0 0px"
                          }}
                        ></div>

                        <DatePicker
                          id="validity_end_date"
                          name="validity_end_date"
                          showYearDropdown
                          placeholderText="End Date"
                          selected={validityEndDate}
                          minDate={
                            new Date(formik.values.validity_start_date + 1)
                          }
                          onChange={(date) => {
                            setValidityEndtDate(date);
                            formik.setFieldValue("validity_end_date", date);
                          }}
                          onBlur={formik.handleBlur}
                          value={validityEndDate}
                          className={dateClasses.customDate}
                        />
                      </span>
                      <span
                        className={classes.profileerrMsg}
                        style={{
                          marginLeft: "1.5rem",
                          color: "rgb(223, 58, 58)"
                        }}
                      >
                        {(formik.touched.validity_start_date &&
                          formik.errors.validity_start_date) ||
                        (formik.touched.validity_end_date &&
                          formik.errors.validity_end_date) ? (
                          <div>
                            {formik.errors.validity_start_date ||
                              formik.errors.validity_end_date}
                          </div>
                        ) : null}
                      </span>
                    </span>
                  </div>
                </>
              )}
              <div className={classes.formGrp}>
                <label for="personToVisit">Valid ID:</label>
                <div className="valid-id-container">
                  <div className="valid-id-item">
                    <ReactFileReader
                      name="logo"
                      fileTypes={[".png", ".jpg", ".jpeg"]}
                      handleFiles={(e) => handleFile(e, "selfie")}
                      handleBlur={formik.handleBlur}
                      base64={true}
                    >
                      {!selfiePreview && (
                        <img
                          src={require("../../../../assets/img/admin-images/black_profile.png")}
                        />
                      )}
                      {selfiePreview && <img src={selfiePreview} />}
                    </ReactFileReader>

                    <p>Visitor Photo</p>
                  </div>
                  <div className="valid-id-item">
                    <ReactFileReader
                      name="logo"
                      fileTypes={[".png", ".jpg", ".jpeg"]}
                      handleFiles={(e) => handleFile(e, "docFront")}
                      handleBlur={formik.handleBlur}
                      base64={true}
                    >
                      {!docFrontPreview && (
                        <img
                          src={require("../../../../assets/img/admin-images/black_document.png")}
                        />
                      )}
                      {docFrontPreview && <img src={docFrontPreview} />}
                    </ReactFileReader>

                    <p>Doc Front</p>
                  </div>
                  <div className="valid-id-item">
                    <ReactFileReader
                      name="logo"
                      fileTypes={[".png", ".jpg", ".jpeg"]}
                      handleFiles={(e) => handleFile(e, "docBack")}
                      handleBlur={formik.handleBlur}
                      base64={true}
                    >
                      {!docBackPreview && (
                        <img
                          src={require("../../../../assets/img/admin-images/black_document.png")}
                        />
                      )}
                      {docBackPreview && <img src={docBackPreview} />}
                    </ReactFileReader>

                    <p>Doc Back</p>
                  </div>
                </div>
              </div>{" "}
            </>
          )}
          <div className={classes.formBtn}>
            {/* <button className={classes.cancleBtn}>Cancel</button> */}
            <button
              type="submit"
              className={classes.createBtn}
              //   disabled={IsDisabled}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditSpecialPass;
