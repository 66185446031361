import classes from "./Thankyou.module.css";
import Termsclasses from "../Terms/Terms.module.css";
import Footer from "../Footer/Footer";
import { useEffect } from "react";

const Thankyou = () => {
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  });
  return (
    <>
      <div className={classes.centerContent}>
        <p>Your response is submitted, Thank you!</p>
      </div>
      <Footer footerColor={"black"} />
    </>
  );
};

export default Thankyou;
