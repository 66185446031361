import classes from "./GovtId.module.css";
import btnClasses from "../Selfie/Selfie.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import visitorActions from "../../../ReduxStore/visitorSlice";
import { height } from "@mui/system";
import ReactFileReader from "react-file-reader";
import SubmitForm from "../submitApi/SubmitForm";
import { useContext } from "react";
import axios from "axios";
import Footer from "../Footer/Footer";
import "../../../assets/css/visitor-css/master.css";
import ClearData from "../ClearData/ClearData";
import { useEffect } from "react";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
let isSubmit = true;
const GovtId = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [docNum, SetDocNum] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doc1 = useSelector((state) => state.visitorData.doc1);
  const doc2 = useSelector((state) => state.visitorData.doc2);
  const activeCamera = useSelector((state) => state.visitorData.activeCamera);
  const companyData = useSelector((state) => state.visitorData.companyData);
  const visitorPhone = useSelector((state) => state.visitorData.visitorPhone);
  const OTPTime = useSelector((state) => state.visitorData.OTPTime);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageHeading, setPageHeading] = useState("");
  const visitorFormData = useSelector(
    (state) => state.visitorData.visitorFormData
  );
  const isRepeated = useSelector((state) => state.visitorData.isRepeated);
  const selfie = useSelector((state) => state.visitorData.selfie);
  let DeviceDetails = navigator.userAgent;
  // window.addEventListener("load", function (e) {
  //   // e.preventDefault();
  //   // this.setTimeout(() => {
  //   navigate("/selfie");
  //   // }, 5000);
  // });

  useEffect(() => {
    if (!selfie) {
      navigate("/selfie");
    }

    //Check govt id already exists
    if (isRepeated) {
      if (doc1 && typeof doc1 == "string") {
        setPageHeading("We have your valid id use this or upload new one");
      }
    }
  }, []);

  if (
    companyData.covidQuestionOne ||
    companyData.covidQuestionTwo ||
    companyData.covidQuestionThree ||
    companyData.covidQuestionFour ||
    companyData.covidQuestionFive
  ) {
    isSubmit = false;
  }

  const uploadDocumentOne = () => {
    setDisplayModal(!displayModal);
    setErrMsg("");
    dispatch(visitorActions.setActiveCamera("doc1"));
  };
  const uploadDocumentTwo = () => {
    setDisplayModal(!displayModal);
    setErrMsg("");
    dispatch(visitorActions.setActiveCamera("doc2"));
  };

  const handleDeleteDoc1 = () => {
    dispatch(visitorActions.setDoc1(""));
  };
  const handleDeleteDoc2 = () => {
    dispatch(visitorActions.setDoc2(""));
  };

  const handleSubmit = () => {
    // SubmitForm(visitorFormData, selfie.file, doc1.file, doc2.file);

    if (isSubmit) {
      setLoading(true);
      if (doc1 || doc2) {
        setErrMsg("");
      } else {
        setErrMsg("Please upload a document");
        return;
      }

      //Form data
      const formData = new FormData();
      // formData.append("phone", 9988998840);
      formData.append("phone", Number(visitorPhone));
      formData.append("firstName", visitorFormData.firstName);
      formData.append("lastName", visitorFormData.lastName);
      formData.append("companyName", visitorFormData.companyName);
      formData.append("address", visitorFormData.Address);
      formData.append("purposeOfVisit", visitorFormData.Purpose);
      if (companyData.cardCheck) {
        if (visitorFormData["card_no"]) {
          formData.append("cardNo", visitorFormData["card_no"]);
        }
      }

      if (companyData.accessoryCheck) {
        if (visitorFormData["laptop_check"]) {
          formData.append("isLaptop", visitorFormData["laptop_check"]);
          if (visitorFormData["laptop_serial_no"]) {
            formData.append(
              "laptopSerial",
              visitorFormData["laptop_serial_no"]
            );
          }
        }

        if (visitorFormData["mobile_check"]) {
          formData.append("isMobile", visitorFormData["mobile_check"]);
          if (visitorFormData["mobile_serial_no"]) {
            formData.append(
              "mobileSerial",
              visitorFormData["mobile_serial_no"]
            );
          }
        }

        if (visitorFormData["hard_disk_check"]) {
          formData.append("isHardDisk", visitorFormData["hard_disk_check"]);
          if (visitorFormData["hard_disk_serial_no"]) {
            formData.append(
              "hardDiskSerial",
              visitorFormData["hard_disk_serial_no"]
            );
          }
        }

        if (visitorFormData["charger_check"]) {
          formData.append("isCharger", visitorFormData["charger_check"]);
          if (visitorFormData["charger_serial_no"]) {
            formData.append(
              "chargerSerial",
              visitorFormData["charger_serial_no"]
            );
          }
        }

        if (visitorFormData["other_accessory_check"]) {
          formData.append(
            "isOtherAccessory",
            visitorFormData["other_accessory_check"]
          );
          if (visitorFormData["other_accessory_serial_no"]) {
            formData.append(
              "otherAccessorySerial",
              visitorFormData["other_accessory_serial_no"]
            );
          }
        }
      }

      formData.append("empPhoneNumber", visitorFormData.meeting_person_phone);
      formData.append("empName", visitorFormData.meeting_person_name);
      formData.append(
        "empDesignation",
        visitorFormData.meeting_person_designation
      );
      formData.append(
        "empDepartment",
        visitorFormData.meeting_person_department
      );

      // formData.append("selfie", selfie.base64);
      // formData.append("docFront", doc1.base64);

      // if (doc2) {
      //   formData.append("docBack", doc2.base64);
      // }
      if (typeof selfie == "string") {
        formData.append("existingSelfie", selfie);
      } else {
        formData.append("selfieFile", selfie.file);
      }

      if (typeof doc1 == "string") {
        formData.append("existingDocFront", doc1);
      } else {
        formData.append("docFrontFile", doc1.file);
      }

      if (doc2) {
        console.log("Type of doc2", typeof doc2);
        console.log("doc2 value", doc2);
        console.log(
          "doc2 value from local",
          { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
            "docBackPic"
          ]
        );
        if (typeof doc2 == "string") {
          formData.append("existingDocBack", doc2);
        } else {
          formData.append("docBackFile", doc2.file);
        }
      }

      formData.append("isRepeated", isRepeated);
      formData.append("companyId", companyData._id);
      if (visitorFormData.locationId) {
        formData.append("locationId", visitorFormData.locationId);
      }

      formData.append("lat", visitorFormData.lat);
      formData.append("long", visitorFormData.long);

      formData.append("smsCount", 0);
      formData.append("otpSent", OTPTime);
      formData.append("checkedIn", true);
      formData.append("passType", "Normal");
      //Submit form here

      // fetch(`${base_url}/visitor/add`, {
      //   method: "POST",
      //   body: formData,
      // })

      axios
        .post(`${base_url}/visitor/add`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          navigate("/thank-you");
          setLoading(false);
          ClearData();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(
            "Your request is taking longer than usual, Please check your internet connection and try again.",
            {
              position: "top-center",
            }
          );
          axios.post(
            "https://digipass-logger-default-rtdb.firebaseio.com/logs.json",
            {
              error: err,
              device: DeviceDetails,
              issueTime: new Date(),
              payload: {
                phone: visitorPhone,
                visitorFormData: visitorFormData,
                selfie: JSON.stringify(selfie["file"]["name"]) || "No Selfie",
                doc1: JSON.stringify(doc1["file"]["name"]) || "No govt-id 1",
                covidQues: "Without covid questions",
              },
            }
          );
        });
      // SubmitForm(visitorFormData, selfie.file, doc1.file, doc2.file);
    } else {
      if (doc1 || doc2) {
        setErrMsg("");
        navigate("/covid-questions");
      } else {
        setErrMsg("Please upload a document");
      }
    }
  };

  const handleFile = (file) => {
    if (activeCamera == "doc1") {
      dispatch(
        visitorActions.setDoc1({ file: file.fileList[0], base64: file.base64 })
      );
    } else if (activeCamera == "doc2") {
      dispatch(
        visitorActions.setDoc2({ file: file.fileList[0], base64: file.base64 })
      );
    }

    setDisplayModal(false);
  };

  return (
    <>
      <div className="wrapper-box">
        <div className="container">
          <div className="wrapper-mid" style={{ background: "none" }}>
            <div className="govt-panel">
              <div className="govttop-panel">
                <h2>
                  {/* Take the Photo or you can upload it from<br></br> your gallery */}
                  {pageHeading ||
                    "Please upload or click the photo of your Valid ID"}
                </h2>

                <div className={classes.documentCont}>
                  <div
                    className={classes.documentDiv}
                    // onClick={() => setDisplayModal(!displayModal)}
                  >
                    <p>Front</p>
                    <div className={classes.fileDiv}>
                      {!doc1 && (
                        <div
                          className={classes.fileIcon}
                          onClick={uploadDocumentOne}
                        >
                          <img
                            src={require("../../../assets/img/visitor-images/fileIcon.png")}
                          />
                        </div>
                      )}

                      {doc1 && (
                        <div className={classes.fileIcon}>
                          <img
                            src={typeof doc1 == "string" ? doc1 : doc1.base64}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderTopLeftRadius: "12px",
                              borderTopRightRadius: "12px",
                            }}
                          />
                        </div>
                      )}

                      <div className={classes.deleteIcon}>
                        <img
                          src={require("../../../assets/img/visitor-images/deleteIcon.png")}
                          onClick={handleDeleteDoc1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.vl}></div>
                  <div className={classes.documentDiv}>
                    <p>Back</p>
                    <div className={classes.fileDiv}>
                      {!doc2 && (
                        <div
                          className={classes.fileIcon}
                          onClick={uploadDocumentTwo}
                        >
                          <img
                            src={require("../../../assets/img/visitor-images/fileIcon.png")}
                          />
                        </div>
                      )}

                      {doc2 && (
                        <div className={classes.fileIcon}>
                          <img
                            src={typeof doc2 == "string" ? doc2 : doc2.base64}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderTopLeftRadius: "12px",
                              borderTopRightRadius: "12px",
                            }}
                          />
                        </div>
                      )}

                      <div className={classes.deleteIcon}>
                        <img
                          src={require("../../../assets/img/visitor-images/deleteIcon.png")}
                          onClick={handleDeleteDoc2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="govt-bootom">
                <div className={classes.errMsg}>{errMsg}</div>

                <div className={classes.UploadCont}>
                  {/* <div className={classes.UploadDiv}>
                    <div className={classes.IconCont}>
                      <div>
                        <img
                          src={require("../../../assets/img/visitor-images/fileIconWhite.png")}
                        />
                      </div>
                      <p>Take Picture</p>
                    </div>

                    <div className={classes.IconCont}>
                      <div>
                        <img src={require("../../../assets/img/visitor-images/upload.png")} />
                      </div>
                      <p>Choose & Upload</p>
                    </div>
                  </div> */}

                  <div className={btnClasses.btn}>
                    <button
                      className={btnClasses.back}
                      onClick={() => navigate("/selfie")}
                    >
                      Back
                    </button>
                    <button className={btnClasses.next} onClick={handleSubmit}>
                      {isSubmit ? (
                        loading ? (
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )
                      ) : (
                        "Next"
                      )}
                      {/* <Link to="/covid-questions">Next</Link> */}
                    </button>
                  </div>
                </div>

                <div className="ModalBottom">
                  <div className={`Modal ${displayModal ? "Show" : ""}`}>
                    <h3 className="Modal-h3">Upload your documents</h3>
                    <button
                      className="Close"
                      onClick={() => setDisplayModal(!displayModal)}
                    >
                      <span style={{ color: "#ffffff" }}>✖</span>
                    </button>
                    <div>
                      <div className={classes.UploadDiv}>
                        <div
                          className={classes.IconCont}
                          onClick={() => navigate("/camera")}
                        >
                          <div>
                            <img
                              src={require("../../../assets/img/visitor-images/fileIconWhite.png")}
                            />
                          </div>
                          <p>Take Picture</p>
                        </div>

                        <ReactFileReader
                          name="logo"
                          fileTypes={[".png", ".jpg", ".jpeg"]}
                          handleFiles={handleFile}
                          base64={true}
                        >
                          <div className={classes.IconCont}>
                            <div>
                              <img
                                src={require("../../../assets/img/visitor-images/upload.png")}
                              />
                            </div>
                            <p>Choose & Upload</p>
                          </div>
                        </ReactFileReader>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`Overlay ${displayModal ? "Show" : ""}`}
                    onClick={() => setDisplayModal(!displayModal)}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default GovtId;
