// import React, { useState, useRef } from "react";
import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton/BackButton";
import visitorActions from "../../../ReduxStore/visitorSlice";
import appActions from "../../../ReduxStore/appDataSlice";

const CameraComponent = () => {
  const dispatch = useDispatch();
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const videoConstraints = {
    width: 220,
    height: 200,
  };

  // const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const facingMode = useSelector((state) => state.visitorData.facingMode);
  const activeCamera = useSelector((state) => state.visitorData.activeCamera);
  const [image, setImage] = useState("");
  const webcamRef = React.useRef(null);

  useEffect(() => {
    if (activeCamera == "selfie") {
      dispatch(visitorActions.setFacingMode(FACING_MODE_USER));
    }
  }, [activeCamera]);

  const handleFacingMode = () => {
    dispatch(
      visitorActions.setFacingMode(
        facingMode === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
      )
    );
    // setFacingMode((prevState) =>
    //   prevState === FACING_MODE_USER
    //     ? FACING_MODE_ENVIRONMENT
    //     : FACING_MODE_USER
    // );
  };

  const navigate = useNavigate();

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  // const capture = React.useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setImage(imageSrc);
  // });

  const capture = React.useCallback(() => {
    const photo = webcamRef.current.getScreenshot();
    setImage(photo);
    if (photo) {
      const filename = uuidv4();
      var file = dataURLtoFile(photo, `${filename}.jpg`);
      if (activeCamera == "selfie") {
        dispatch(visitorActions.setSelfie({ file: file, base64: photo }));
      } else if (activeCamera == "doc1") {
        dispatch(visitorActions.setDoc1({ file: file, base64: photo }));
      } else if (activeCamera == "doc2") {
        dispatch(visitorActions.setDoc2({ file: file, base64: photo }));
      }

      navigate(`${activeCamera == "selfie" ? "/selfie" : "/govt-id"}`);
    }
  });

  return (
    <div className="wrapper-box">
      <div className="container">
        <div
          className="main-panel camera-screen"
          style={{ background: "#000" }}
        >
          <BackButton
            path={activeCamera == "selfie" ? "/selfie" : "/govt-id"}
          />
          <div className="camera-panel">
            {image == "" ? (
              <Webcam
                audio={false}
                style={
                  activeCamera == "selfie" && facingMode == "user"
                    ? {
                        "--webkit-transform": "scaleX(-1)",
                        transform: "scaleX(-1)",
                      }
                    : {}
                }
                height="100%"
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width="100%"
                videoConstraints={{
                  ...videoConstraints,
                  facingMode,
                }}
              />
            ) : (
              // <img
              //   style={{
              //     "--webkit-transform": "scaleX(-1)",
              //     transform: "scaleX(-1)",
              //   }}
              //   height="100%"
              //   width="100%"
              //   alt="selfie"
              //   src={image}
              // />
              ""
            )}
          </div>
          <div className="">
            <div className="camera-btn">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  capture();
                }}
                className="webcam-btn"
              >
                <img
                  src={require("../../../assets/img/visitor-images/compact-camera.png")}
                />
              </button>
              {activeCamera !== "selfie" && (
                <button onClick={handleFacingMode}>
                  <img
                    src={require("../../../assets/img/visitor-images/switch-camera.png")}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraComponent;
