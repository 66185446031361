import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Login.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import visitorActions from "../../../ReduxStore/visitorSlice";
import Footer from "../Footer/Footer";
import "../../../assets/css/visitor-css/master.css";
import { toast } from "react-toastify";
import ClearData from "../ClearData/ClearData";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");

const checkSpecialVisitor = (companyId, phone, lat, long) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${base_url}/visitor/search-special/${companyId}/${Number(phone)}`
      );
      if (response.data["data"]) {
        console.log("specialVisitor", response);
        let createPass = false;
        const today = moment();
        let specialVisitor = response.data["data"];

        if (specialVisitor.passType == "Delegate") {
          console.log("Visiting Date", specialVisitor["visitingDate"]);
          console.log("today", today);
          const visitingDate = moment(specialVisitor["visitingDate"]);

          if (visitingDate.isSame(today, "day")) {
            createPass = true;
          }
        } else {
          const startDateMoment = moment(specialVisitor["validityStartDate"]);
          const endDateMoment = moment(specialVisitor["validityEndDate"]);

          const isTodayEqualOrAfterStart = today.isSameOrAfter(
            startDateMoment,
            "day"
          );
          const isTodayEqualOrBeforeStart = today.isSameOrBefore(
            endDateMoment,
            "day"
          );

          if (isTodayEqualOrAfterStart && isTodayEqualOrBeforeStart) {
            createPass = true;
          }
        }

        //Create Pass
        if (createPass) {
          //Valid special Visitor
          const formData = new FormData();
          formData.append("phone", Number(specialVisitor.phone));
          formData.append("firstName", specialVisitor.firstName);
          formData.append("lastName", specialVisitor.lastName);
          if (specialVisitor["companyName"]) {
            formData.append("companyName", specialVisitor["companyName"]);
          }
          if (specialVisitor["purposeOfVisit"]) {
            formData.append("purposeOfVisit", specialVisitor["purposeOfVisit"]);
          }
          if (specialVisitor["personToVisit"]) {
            formData.append("empName", specialVisitor["personToVisit"]);
          }
          if (specialVisitor["selfie"]) {
            formData.append("existingSelfie", specialVisitor["selfie"]);
          }
          if (specialVisitor["docFront"]) {
            formData.append("existingDocFront", specialVisitor["docFront"]);
          }
          if (specialVisitor["docBack"]) {
            formData.append("existingDocBack", specialVisitor["docBack"]);
          }
          if (specialVisitor["companyId"]) {
            formData.append("companyId", specialVisitor["companyId"]);
          }
          if (specialVisitor["locationId"]) {
            formData.append("locationId", specialVisitor["locationId"]);
          }

          if (specialVisitor.passType == "Delegate") {
            formData.append("visitingDate", specialVisitor["visitingDate"]);
          }

          if (specialVisitor.passType == "Vendor") {
            formData.append(
              "validityStartDate",
              specialVisitor["validityStartDate"]
            );
            formData.append(
              "validityEndDate",
              specialVisitor["validityEndDate"]
            );
            if (specialVisitor["vendorType"]) {
              formData.append("vendorType", specialVisitor["vendorType"]);
            }
          }

          formData.append("isRepeated", true);
          formData.append("passType", specialVisitor["passType"]);
          formData.append("passName", specialVisitor["passName"]);
          formData.append("specialVisitorFyi", specialVisitor["createdAt"]);

          formData.append("lat", lat);
          formData.append("long", long);

          formData.append("smsCount", 0);
          formData.append("checkedIn", true);

          try {
            const visitorResponse = await axios.post(
              `${base_url}/visitor/add`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            );

            resolve({ status: true, data: visitorResponse });
          } catch (error) {}
        } else {
          //special visitor found but validity is expired
          resolve({ status: false, data: {} });
        }
      } else {
        //special visitor not found
        resolve({ status: false, data: {} });
      }
    } catch (error) {
      resolve({ status: false, data: {} });
    }
  });
};

const VisitorLogin = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [subscriptionErr, setSubscriptionErr] = useState("");
  const [newWidth, setNewWidth] = useState("80");
  const [newHeight, setNewHeight] = useState("80");
  // const [lat, setLat] = useState("");
  // const [lon, setLon] = useState("");
  const [locationRequired, setLocationRequired] = useState(false);
  const journeyType = useSelector((state) => state.visitorData.journeyType);
  const { lat, long } = useSelector(
    (state) => state.visitorData.visitorFormData
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const companyData = useSelector((state) => state.visitorData.companyData);
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((pos) => {
  //     setLat(pos.coords.latitude);
  //     setLon(pos.coords.longitude);
  //     localStorage.setItem(
  //       "visitorFormData",
  //       JSON.stringify({ lat: pos.coords.latitude, long: pos.coords.longitude })
  //     );
  //   });
  // }, []);
  let btnText = journeyType == "Checkout" ? "Checkout" : "Submit";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: ""
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter your phone number")
    }),
    onSubmit: async (values) => {
      //Check valid checkin checkout
      let isCheckedReturn = false;
      setLoading(true);

      //test
      await axios
        .get(
          `${base_url}/visitor/company/search/${companyData["_id"]}/${Number(
            values.phone
          )}`
        )
        .then(async (visitor) => {
          if (visitor.data["data"]) {
            console.log("Existing Visitor data...", visitor.data["data"]);

            if (visitor.data["data"]["checkedIn"]) {
              if (journeyType == "Checkin") {
                toast.dismiss();
                toast.error("You are already checked in, please checkout.", {
                  position: "top-center"
                });
                isCheckedReturn = true;
                setLoading(false);
                // dispatch(visitorActions.setJourneyType("Checkout"));
                navigate(`/visitor/${companyData._id}`);
                return;
              }
            } else {
              if (journeyType == "Checkout") {
                toast.dismiss();
                toast.error("You are not checked in, please checkin first.", {
                  position: "top-center"
                });
                isCheckedReturn = true;
                setLoading(false);
                // dispatch(visitorActions.setJourneyType("Checkin"));
                navigate(`/visitor/${companyData._id}`);
                return;
              }
            }
            let returnAfterCheckout = false;
            if (journeyType == "Checkout") {
              if (visitor.data["data"]["status"] == "pending") {
                toast.dismiss();
                toast.error(
                  "You have already applied for a pass, but your pass is not approved yet",
                  {
                    position: "top-center"
                  }
                );
                setLoading(false);
                isCheckedReturn = true;
                navigate(`/visitor/${companyData._id}`);
                return;
              }
              //End meeting

              // let reqObj = {
              //   method: "POST",
              //   url: `${base_url}/visitor/end-meeting`,
              //   headers: {
              //     "Content-Type": "application/json",
              //   },
              //   data: {
              //     visitorId: visitor.data["data"]["_id"],
              //   },
              // };
              // await axios(reqObj)
              //   .then((res) => {

              //   })
              //   .catch((err) => {
              //     console.log(err);
              //   });

              await axios
                .put(
                  `${base_url}/visitor/company/check-out/${
                    companyData["_id"]
                  }/${Number(values.phone)}`,
                  {
                    checkedoutBy: `${visitor.data["data"]["firstName"]}(Self)`
                  }
                )
                .then((visitor) => {
                  setLoading(false);
                  returnAfterCheckout = true;
                  isCheckedReturn = true;
                  if (visitor.data["status"]) {
                    toast.dismiss();
                    toast.success("You are successfully checked out!", {
                      position: "top-center"
                    });
                    // dispatch(visitorActions.setJourneyType("Checkin"));
                    navigate(`/visitor/${companyData._id}`);
                  } else {
                    console.log("No visitor found");
                    toast.dismiss();
                    toast.error("Something went wrong.", {
                      position: "top-center"
                    });
                  }
                })
                .catch(() => {
                  returnAfterCheckout = true;
                  isCheckedReturn = true;
                  setLoading(false);
                });
            }
            if (returnAfterCheckout) {
              return;
            }

            // return;
            let visitorData = {
              firstName: visitor.data["data"]["firstName"],
              lastName: visitor.data["data"]["lastName"],
              companyName: visitor.data["data"]["companyName"],
              Address: visitor.data["data"]["address"],
              Purpose: visitor.data["data"]["purposeOfVisit"],
              SelfiePic: visitor.data["data"]["selfie"] || "",
              docFrontPic: visitor.data["data"]["docFront"] || "",
              docBackPic: visitor.data["data"]["docBack"] || "",
              isRepeatedVisitor: true
              // meeting_person_phone: visitor.data["data"]["empPhoneNumber"],
              // meeting_person_name: visitor.data["data"]["empName"],
              // meeting_person_designation:
              //   visitor.data["data"]["empDesignation"],
              // meeting_person_department: visitor.data["data"]["empDepartment"],
              // locationId: visitor.data["data"]["locationId"],
              // companyOrBuildingLocations: visitor.data["data"]["companyId"],
            };
            localStorage.setItem(
              "visitorFormData",
              JSON.stringify({
                ...JSON.parse(localStorage.getItem("visitorFormData")),
                ...visitorData
              })
            );
            if (visitor.data["data"]["selfie"]) {
              dispatch(
                visitorActions.setSelfie(visitor.data["data"]["selfie"])
              );
            }
            if (visitor.data["data"]["docFront"]) {
              dispatch(
                visitorActions.setDoc1(visitor.data["data"]["docFront"])
              );
            }
            if (visitor.data["data"]["docBack"]) {
              dispatch(visitorActions.setDoc2(visitor.data["data"]["docBack"]));
            }
            dispatch(visitorActions.setIsRepeated(true));
          } else {
            console.log("Visitor not found");
          }
        });

      //test
      if (isCheckedReturn) {
        return;
      }

      let specialVisitor = await checkSpecialVisitor(
        companyData["_id"],
        values.phone,
        lat,
        long
      );

      if (specialVisitor["status"]) {
        console.log("specialVisitor...", specialVisitor);
        setLoading(false);
        ClearData();
        navigate("/thank-you");
        return specialVisitor;
      }

      let reqObj = {
        method: "POST",
        url: `${base_url}/visitor/send-verification-otp`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          phone: Number(values.phone),
          companyId: companyData._id
        }
      };

      axios(reqObj)
        .then((res) => {
          navigate("/visitor-otp-verification");
          dispatch(visitorActions.setVisitorPhone(Number(values.phone)));
          localStorage.setItem("visitorPhone", values.phone);
          setLoading(false);
          dispatch(visitorActions.setOTPTime(res.data.smsTime));
          localStorage.setItem("OTPTime", res.data.smsTime);
        })
        .catch((err) => {
          console.log(err);
          formik.setFieldError(
            "phone",
            "Opps...There seems to be a server error"
          );
          setLoading(false);
        });
    }
  });

  var image = new Image();
  image.src = companyData?.logo;
  image.onload = function () {
    // Get the height and width
    var height = image.naturalHeight;
    var width = image.naturalWidth;

    console.log("Image height:", height);
    console.log("Image width:", width);
    const aspectRatio = width / height;

    if (aspectRatio > 1.7) {
      setNewWidth("160");
      setNewHeight("60");
    } else {
      setNewWidth("80");
      setNewHeight("80");
    }
  };

  return (
    <>
      <div className="wrapper-box z1">
        <div className="container z2" style={{ position: "relative" }}>
          {/* <div className="app-panel"> */}
          <div
            className="bg-special z3"
            // style={{
            //   backgroundColor: "rgb(10 81 170 / 95%)",
            // }}
          ></div>
          <div className="bg-ellipes-left z3"></div>
          <div className="bg-ellipes-right z3"></div>
          <div className="wrapper-mid z4">
            <div className="logo-div">
              <img
                src={require("../../../assets/img/visitor-images/images/logo.png")}
              />
            </div>

            <div className="text-logo">
              <h1>Digi-Pass</h1>
              <p style={{ fontSize: "21px", textTransform: "none" }}>
                Visitor Management System
              </p>
            </div>
            <div className="logo-res">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <img
                  className="logo-parter-img"
                  style={{
                    width: `${newWidth}px`,
                    height: `${newHeight}px`,
                    display: "inline-block",
                    margin: "0 auto"
                  }}
                  src={companyData?.logo}
                />
              </div>
            </div>
            <div className="input-panel">
              <h2>Please enter your valid Mobile Number</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group logininput">
                  <input
                    type="number"
                    className="form-control input-field visitor-number"
                    placeholder="Enter Phone Number"
                    id="phone"
                    name="phone"
                    {...formik.getFieldProps("phone")}
                  />

                  <span className={classes.errMsg}>
                    {formik.touched.phone && formik.errors.phone ? (
                      <div>{formik.errors.phone}</div>
                    ) : null}
                  </span>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    value="Submit"
                    className="btn btn-primary submit-btn"
                  >
                    {isLoading ? (
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      btnText
                    )}
                  </button>
                </div>
              </form>
            </div>
            <Footer />
            {/* <Footer footerColor={"black"} /> */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default VisitorLogin;
