import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authContext from "../../../../AuthContext/authContext";
import appActions from "../../../../ReduxStore/appDataSlice";
import Home from "../Home/Home";
import Table from "../../Table/Table";
import classes from "../../Table/Table.module.css";
import { toast } from "react-toastify";
import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");
const ActiveVisitorWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const userName = ctx?.userDetailedData?.name;
  console.log("Context value in visitor wrapper", ctx);
  const userType = ctx.userData.user_type;
  const sortDirection = useSelector((state) => state.appData.sortDirection);
  const dateSort = (rowA, rowB) => {
    dispatch(appActions.setSortDirection(sortDirection === "1" ? "-1" : "1"));
    localStorage.setItem("sortDirection", sortDirection === "1" ? "-1" : "1");
  };

  const isHidden = userType == "admin" || userType == "subadmin";

  const maskText = (text) => {
    text = String(text);
    if (text.length <= 2) {
      return "*".repeat(text.length);
    }
    const maskedText = text.slice(0, 3) + "*".repeat(text.length - 3);
    return maskedText;
  };

  const handleChekout = (phone) => {
    setIsloading(true);
    axios
      .put(
        `${base_url}/visitor/company/check-out/${activeCompany["_id"]}/${Number(
          phone
        )}`,
        {
          checkedoutBy: `${userName}(Admin)`,
        }
      )
      .then((visitor) => {
        setIsloading(false);
        toast.dismiss();
        toast.success("Visitor successfully checked out!");
      })
      .catch(() => {
        toast.dismiss();
        toast.success("There was error in checking out visitor.");
        setIsloading(false);
      });
  };

  const columns = [
    {
      id: "srNo",
      name: "Visitor ID",
      cell: (row, index) => row._id,
    },
    {
      id: "date",
      name: "Request Date & Time",
      selector: (row) => {
        const formatedDate = moment(row.createdAt).format("MMM D, hh:mm A");
        return formatedDate;
      },
      sortable: false,
      sortFunction: dateSort,
    },
    {
      name: "User/Company Name",
      selector: (row) =>
        isHidden
          ? maskText(`${row.firstName}-${row.companyName}`)
          : `${row.firstName}-${row.companyName}`,
      sortable: true,
    },
    {
      name: "Meeting Person/Designation",
      selector: (row) =>
        row.empDesignation == "undefined"
          ? `${row.empName}`
          : `${row.empName}-${row.empDesignation}`,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => (isHidden ? maskText(row.location) : row.location),
      sortable: true,
    },
    {
      name: "Meeting Purpose",
      selector: (row) => row.purposeOfVisit,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className={classes.icons}>
            <img
              style={{ cursor: "pointer" }}
              src={require("../../../../assets/img/admin-images/check-out-single.png")}
              onClick={() => handleChekout(row.phone)}
            />
          </div>
        </>
      ),
    },
  ];

  const checkOutAll = (isEmpty) => {
    setIsloading(true);
    if (isEmpty == "empty") {
      toast.dismiss();
      toast.error("There are no checked in visitors");
      setIsloading(false);
      return;
    }

    let data = {
      companyId: activeCompany["_id"],
      checkedoutBy: `${userName}(Admin)`,
    };
    if (activeLocation) {
      if (activeLocation["_id"] !== "All") {
        data.locationId = activeLocation["_id"];
      }
    }
    let reqObj = {
      method: "PUT",
      url: `${base_url}/visitor/check-out-all`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`,
      },
      data: data,
    };

    axios(reqObj)
      .then((res) => {
        toast.dismiss();
        toast.success("All visitors checked out");
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss();
        toast.error(
          err.response.data.error.message || "Failed to checkout visitors"
        );
        setIsloading(false);
      });
  };

  let showCsvExport = false;
  let showDownload = false;
  let showExport = false;
  let showLocation = true;
  let showSms = true;
  let showAddNew = false;
  let showFirstHr = false;
  let showSecondHr = false;
  let showActions = true;

  if (
    userType === "Company Manager" ||
    userType === "location" ||
    userType === "Building Manager"
  ) {
    showCsvExport = false;
    showDownload = false;
    showExport = false;
    showLocation = false;
    showSms = false;
    showAddNew = false;
    showFirstHr = false;
    showSecondHr = false;
    showActions = true;
  }
  if (userType === "Building Manager") {
    showLocation = true;
  }

  if (userType == "subadmin") {
    showDownload = false;
  }

  const options = {
    title: "Active Visitors",
    showCsvExport: showCsvExport,
    showDownload: showDownload,
    showExport: showExport,
    showLocation: showLocation,
    showSms: showSms,
    showAddNew: showAddNew,
    showFirstHr: showFirstHr,
    showSecondHr: showSecondHr,
    showActions: showActions,
    showVisitor: true,
    type: "ActiveVisitors",
    columns: columns,
    apiUrl: `${base_url}/visitor/listActiveVisitors`,
    companyId: activeCompany._id, //for building company Id and Building Id is same
    locationId: userType === "location" ? activeLocation._id : "",
  };
  return (
    <>
      <Home
        propComponent={
          <Table
            options={options}
            checkOutAll={checkOutAll}
            isLoading={isLoading}
          />
        }
      />
    </>
  );
};

export default ActiveVisitorWrapper;
