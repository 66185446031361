import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authContext from "../../../../AuthContext/authContext";
import appActions from "../../../../ReduxStore/appDataSlice";
import Home from "../Home/Home";
import Table from "../../Table/Table";
import classes from "../../Table/Table.module.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");
const SpecialVisitorWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const token = ctx.token;
  console.log("Context value in visitor wrapper", ctx);
  const userType = ctx.userData.user_type;
  const [isLoading, setIsloading] = useState(false);
  const [visitorToDelete, setVisitorToDelete] = useState({});
  const sortDirection = useSelector((state) => state.appData.sortDirection);
  const dateSort = (rowA, rowB) => {
    dispatch(appActions.setSortDirection(sortDirection === "1" ? "-1" : "1"));
    localStorage.setItem("sortDirection", sortDirection === "1" ? "-1" : "1");
  };

  useEffect(() => {
    if (
      userType === "Company Manager" ||
      userType === "location" ||
      userType === "Building Manager" ||
      userType === "company"
    ) {
      let showCreatePass =
        !(userType === "admin" || userType === "subadmin") &&
        (activeCompany["delagateCheck"] || activeCompany["vendorCheck"]);
      if (!showCreatePass) {
        toast.error("Special Visitor is disabled");
        navigate("/dashboard");
      }
    }
  }, []);

  const isHidden = userType == "admin" || userType == "subadmin";

  const maskText = (text) => {
    text = String(text);
    if (text.length <= 2) {
      return "*".repeat(text.length);
    }
    const maskedText = text.slice(0, 3) + "*".repeat(text.length - 3);
    return maskedText;
  };

  const handleEdit = (id) => {
    dispatch(appActions.setActiveEditVisitorId(id));
    localStorage.setItem("activeEditVisitorId", id);
    navigate("/edit-special-visitor");
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${base_url}/visitor/delete-special-visitor/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
        toast.success("Special visitor deleted successfully.");
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        toast.error("Failed to delete Special Visitor.");
      });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const columns = [
    {
      id: "srNo",
      name: "Visitor ID",
      cell: (row, index) => row._id,
    },
    {
      name: "Pass Name",
      selector: (row) => row.passName,
      sortable: true,
    },
    {
      name: "Pass Type",
      selector: (row) => row.passType,
      sortable: true,
    },
    {
      name: "Pass URL",
      selector: (row) => (
        <div className="d-flex">
          <span className={classes.passUrl}>{row.passUrl}</span>
          <span
            id="copypan"
            style={{
              cursor: "pointer",
              margin: "0",
              padding: "0",
              marginLeft: "4px",
            }}
            onClick={() => handleCopy(row.passUrl)}
          >
            {" "}
            <img
              src={require("../../../../assets/img/admin-images/copy.png")}
              style={{
                margin: "0",
                padding: "0",
              }}
              onMouseDown={(event) => {
                const element = event.currentTarget;
                element.style.transform = "scale(0.75)";
              }}
              onMouseUp={(event) => {
                const element = event.currentTarget;
                element.style.transform = "scale(1)";
              }}
            />
          </span>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      id: "date",
      name: "Request Date & Time",
      selector: (row) => {
        const formatedDate = moment(row.createdAt).format("MMM D, hh:mm A");
        return formatedDate;
      },
      sortable: false,
      sortFunction: dateSort,
    },
    {
      name: "User/Company Name",
      selector: (row) =>
        isHidden
          ? maskText(`${row.firstName}-${row.companyName}`)
          : `${row.firstName}-${row.companyName}`,
      sortable: true,
    },
    {
      name: "Meeting Person",
      selector: (row) => row.personToVisit || "NA",
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => (isHidden ? maskText(row.location) : row.location),
      sortable: true,
    },
    {
      name: "Meeting Purpose",
      selector: (row) => row.purposeOfVisit || "NA",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className={classes.icons}>
            {/* <img src={require("../../../../assets/img/admin-images/eye.png")} /> */}
            <img
              style={{ cursor: "pointer" }}
              src={require("../../../../assets/img/admin-images/edit_thin.png")}
              onClick={() => handleEdit(row._id)}
            />
            <img
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              src={require("../../../../assets/img/admin-images/delete.png")}
              // onClick={() => handleDelete(row._id)}
              onClick={() =>
                setVisitorToDelete({ id: row._id, name: row.firstName })
              }
            />
          </div>
        </>
      ),
    },
  ];

  let showCsvExport = false;
  let showDownload = true;
  let showExport = false;
  let showLocation = true;
  let showSms = true;
  let showAddNew = false;
  let showFirstHr = false;
  let showSecondHr = false;
  let showActions = true;
  let showActiveVisitors = true;

  if (
    userType === "Company Manager" ||
    userType === "location" ||
    userType === "Building Manager"
  ) {
    showCsvExport = false;
    showDownload = true;
    showExport = false;
    showLocation = false;
    showSms = false;
    showAddNew = true;
    showFirstHr = false;
    showSecondHr = false;
    showActions = true;
  }
  if (userType === "Building Manager") {
    showLocation = true;
  }

  if (userType == "subadmin") {
    showDownload = false;
    showActiveVisitors = false;
  }

  if (userType == "admin") {
    showActiveVisitors = false;
  }

  const options = {
    title: "Special Visitor",
    showCsvExport: showCsvExport,
    showDownload: false,
    showExport: showExport,
    showLocation: showLocation,
    showSms: showSms,
    showAddNew: showAddNew,
    showFirstHr: showFirstHr,
    showSecondHr: showSecondHr,
    showActions: showActions,
    showActiveVisitors: showActiveVisitors,
    showVisitor: true,
    type: "SpecialVisitor",
    columns: columns,
    apiUrl: `${base_url}/visitor/list-special-visitor`,
    companyId: activeCompany._id, //for building company Id and Building Id is same
    locationId: userType === "location" ? activeLocation._id : "",
  };
  return (
    <>
      <Home propComponent={<Table options={options} isLoading={isLoading} />} />
      <ConfirmModal
        handleDelete={handleDelete}
        id={visitorToDelete.id}
        msg={`Are you sure you want to delete speccial visitor ${visitorToDelete.name}?`}
      />
    </>
  );
};

export default SpecialVisitorWrapper;
