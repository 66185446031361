import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authContext from "../../../../AuthContext/authContext";
import EditEmployee from "./EditEmployee";
import Home from "../../Common/Home/Home";
const base_url = process.env.REACT_APP_BASE_URL;
const EditEmployeeWrapper = () => {
  const activeEditEmployeeId = useSelector(
    (state) => state.appData.activeEditEmployeeId
  );
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/company/employee-detail/${activeEditEmployeeId}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setInitialData({ ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Home propComponent={<EditEmployee initialData={initialData} />} />
    </>
  );
};

export default EditEmployeeWrapper;
