import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./UserForm.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import visitorActions from "../../../ReduxStore/visitorSlice";
import Footer from "../Footer/Footer";
import "../../../assets/css/visitor-css/master.css";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import Departments from "../../admin/Common/departmentDropDown/Departments";

const base_url = process.env.REACT_APP_BASE_URL;

const UserForm = () => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.visitorData.companyData);
  const showCardNo = companyData.cardCheck;
  const showAccessories = companyData.accessoryCheck;
  // console.log("User form company data", companyData);
  const employeeDirectory = companyData.employeeDirectory;
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState(false);
  const [phoneErr, setPhoneErr] = useState("");
  let initialsearchVal = {
    ...JSON.parse(localStorage.getItem("visitorFormData"))
  }["meeting_person_name"];
  const [searchVal, setSearchVal] = useState({
    label: initialsearchVal,
    value: initialsearchVal
  });
  const [drowdownVal, setDropDownVal] = useState([]);
  const [maskedPhone, setMaskedPhone] = useState(
    localStorage.getItem("maskedPhone") || ""
  );
  const [maskedDesignation, setMaskedDesignation] = useState(
    localStorage.getItem("maskedDesignation") || ""
  );

  useEffect(() => {
    try {
      axios(`${base_url}/visitor/company/location/${companyData._id}`).then(
        (res) => {
          setLocations(res.data.location);
          console.log("Locations", res.data.location);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp = /^[0-9]{10}$/;
  const nameRegExp = /^[A-Za-z ]+$/;
  const departmentRegExp = /^[A-Za-z-& ]+$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "firstName"
        ] || "",
      lastName:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "lastName"
        ] || "",
      companyName:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "companyName"
        ] || "",
      Address:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }["Address"] ||
        "",
      Purpose:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }["Purpose"] ||
        "",
      meeting_person_phone:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_phone"
        ] || "",
      meeting_person_name:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_name"
        ] || "",
      meeting_person_designation:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_designation"
        ] || "",
      meeting_person_department:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_department"
        ] || "",
      card_no:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }["card_no"] ||
        "",
      accessory_check:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "accessory_check"
        ] || "",
      laptop_check:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "laptop_check"
        ] || false,
      mobile_check:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "mobile_check"
        ] || false,
      hard_disk_check:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "hard_disk_check"
        ] || false,
      charger_check:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "charger_check"
        ] || false,
      other_accessory_check:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "other_accessory_check"
        ] || false,
      laptop_serial_no:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "laptop_serial_no"
        ] || "",
      mobile_serial_no:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "mobile_serial_no"
        ] || "",
      hard_disk_serial_no:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "hard_disk_serial_no"
        ] || "",
      charger_serial_no:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "charger_serial_no"
        ] || "",
      other_accessory_serial_no:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "other_accessory_serial_no"
        ] || "",
      locationId: "",
      companyOrBuildingLocations:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "companyOrBuildingLocations"
        ] || `${companyData.noOfLocation !== 1 ? "" : locations?.[0]?._id}`
      // lat: lat,
      // long: lon,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        // .matches(nameRegExp, "Please enter valid name")
        .required("Please enter your first name"),
      lastName: Yup.string()
        // .matches(nameRegExp, "Please enter valid name")
        .required("Please enter your last name"),
      companyName: Yup.string().required("Please enter your company name"),
      Address: Yup.string().required("Please enter your address"),
      Purpose: Yup.string().required("Please enter purpose of visit"),
      meeting_person_phone: Yup.string()
        .length(10, "Phone number should be of 10 digits")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Please enter meeting person phone number"),
      meeting_person_name: Yup.string()
        // .matches(nameRegExp, "Please enter valid name")
        .required("Meeting person name is required"),
      meeting_person_designation: Yup.string(),
      // .required(
      //   "Meeting person designation is required"
      // ),
      // .matches(
      //   departmentRegExp,
      //   "Please enter valid designation"
      // ),
      // .required("Meeting person designation is required"),
      meeting_person_department: Yup.string().required(
        "Meeting person department is required"
      ),
      // .matches(departmentRegExp, "Please enter valid department")
      // .required("Meeting person department is required"),
      locationId: Yup.string(),
      card_no: Yup.string(),
      accessory_check: Yup.boolean(),
      laptop_check: Yup.boolean(),
      mobile_check: Yup.boolean(),
      hard_disk_check: Yup.boolean(),
      charger_check: Yup.boolean(),
      other_accessory_check: Yup.boolean(),
      laptop_serial_no: Yup.string(),
      mobile_serial_no: Yup.string(),
      hard_disk_serial_no: Yup.string(),
      charger_serial_no: Yup.string(),
      other_accessory_serial_no: Yup.string(),
      companyOrBuildingLocations: Yup.string().required(
        `${
          companyData.type == "Company"
            ? "Please select Location"
            : "Please select building"
        }`
      )
      // lat: Yup.string().required("You must enable location to move forward"),
      // long: Yup.string().required("You must enable location to move forward"),
    }),

    onSubmit: (values) => {
      if (phoneErr) {
        return;
      }
      let visitorData = { ...values };
      if (values.companyOrBuildingLocations !== "Dummy") {
        //For multiple location
        dispatch(
          visitorActions.setMultipleLocationId(
            values.companyOrBuildingLocations
          )
        );
        localStorage.setItem(
          "multipleLocationId",
          values.companyOrBuildingLocations
        );
        visitorData = {
          ...values,
          locationId: values.companyOrBuildingLocations
        };
      } else {
        if (locations.length) {
          //For single location
          visitorData = {
            ...values,
            locationId: locations[0]._id
          };
        } else {
          toast.error("No location added for this company", {
            position: "top-center"
          });
          return;
        }
      }

      if (!visitorData.locationId) {
        delete visitorData.locationId;
      }
      if (!visitorData.meeting_person_designation) {
        delete visitorData.meeting_person_designation;
      }
      dispatch(
        visitorActions.setVisitorFormData({
          ...JSON.parse(localStorage.getItem("visitorFormData")),
          ...visitorData
        })
      );
      localStorage.setItem(
        "visitorFormData",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("visitorFormData")),
          ...visitorData
        })
      );
      navigate("/selfie");
    }
  });

  const handleMeetingPersonData = (phone) => {
    formik.setFieldValue("meeting_person_phone", phone);
    if (phoneRegExp.test(phone) && phone.length !== 10) {
      setTimeout(() => {
        formik.setFieldError(
          "meeting_person_phone",
          "Please enter valid phone number"
        );
      }, 0);
      return;
    }

    if (!companyData.employeeDirectory) {
      return;
    }

    if (phone.length !== 10) {
      formik.setFieldValue("meeting_person_name", "");
      formik.setFieldValue("meeting_person_designation", "");
      formik.setFieldValue("meeting_person_department", "");
      return;
    }
    if (
      formik.values.companyOrBuildingLocations !== "Dummy" &&
      formik.values.companyOrBuildingLocations == ""
    ) {
      toast.error(
        "Please select location before entering meeting person number",
        {
          position: "top-center"
        }
      );
      return;
    }

    let reqObj = {
      method: "GET",
      // url: `${base_url}/visitor/search/employee/${phone}/${companyData._id}/${formik.values.companyOrBuildingLocations}`,
      url: `${base_url}/visitor/search/employee/${phone}/${companyData._id}/${formik.values.companyOrBuildingLocations}`,
      headers: {
        "Content-Type": "application/json"
      }
    };

    axios(reqObj)
      .then((res) => {
        if (!res["data"]["data"]) {
          setPhoneErr("Meeting person phone number does not exist");
          throw new Error("Meeting person phone number does not exist");
        }
        setPhoneErr(false);
        formik.setFieldValue("meeting_person_name", res.data.data.name);
        formik.setFieldValue(
          "meeting_person_designation",
          res.data.data.designation
        );
        formik.setFieldValue(
          "meeting_person_department",
          res.data.data.department
        );
        setTimeout(() => {
          formik.setFieldError("meeting_person_name", "");
          formik.setFieldError("meeting_person_designation", "");
          formik.setFieldError("meeting_person_department", "");
        }, 0);
        formik.setFieldValue("locationId", res.data.data.locationId);
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          formik.setFieldError(
            "meeting_person_phone",
            "Meeting person phone number does not exist"
          );
        }, 0);
      });
  };

  const getSearchValues = async (searchStr) => {
    return new Promise(async (resolve, reject) => {
      if (searchStr.length < 1) {
        reject(false);
      }
      console.log("Here");
      let reqObj = {
        method: "GET",
        // url: `${base_url}/visitor/search/employee/${phone}/${companyData._id}/${formik.values.companyOrBuildingLocations}`,
        url: `${base_url}/visitor/search/employeeNames/${companyData._id}/${formik.values.companyOrBuildingLocations}/${searchStr}`,
        headers: {
          "Content-Type": "application/json"
        }
      };

      try {
        const res = await axios(reqObj);
        let results = res?.data?.data?.map((val) => {
          return { label: val.name, value: val.name };
        });
        // return results;
        // setDropDownVal(results);
        resolve(results);
        // .then((res) => {
        //   console.log("Search result", res.data.data);
        //   let results = res.data.data.map((val) => {
        //     return { name: val.name, value: val.name };
        //   });
        //   return results;
        // });
      } catch (error) {
        reject(error);
      }
    });
  };

  const promiseOptions = async (searchStr) => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/visitor/search/employeeNames/${companyData._id}/${formik.values.companyOrBuildingLocations}/${searchStr}`,
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios(reqObj);
      let results = await res?.data?.data?.map((val) => {
        return {
          label: `${val.name} - ${val.department}`,
          value: val.name,
          data: val
        };
      });
      return results;
      setDropDownVal(results);
    } catch (error) {}
  };

  const maskText = (text) => {
    if (text) {
      text = text.toString();
      if (text.length <= 2) {
        return "*".repeat(text.length);
      }
      const maskedText = text.slice(0, 3) + "*".repeat(text.length - 3);
      return maskedText;
    } else {
      return "";
    }
  };

  const handleSearchChange = (res) => {
    setSearchVal({ label: res.data.name, value: res.data.name });
    console.log("Seach val", res);
    formik.setFieldValue("meeting_person_name", res.data.name);
    formik.setFieldValue("meeting_person_phone", res.data.phone);
    formik.setFieldValue("meeting_person_designation", res.data.designation);
    formik.setFieldValue("meeting_person_department", res.data.department);
    setTimeout(() => {
      formik.setFieldError("meeting_person_name", undefined);
      formik.setFieldError("meeting_person_designation", undefined);
      formik.setFieldError("meeting_person_department", undefined);
      formik.setFieldError("meeting_person_phone", undefined);
    }, 0);
    formik.setFieldValue("locationId", res.data.locationId);
    setMaskedPhone(() => maskText(res.data.phone));
    localStorage.setItem("maskedPhone", maskText(res.data.phone));
    setMaskedDesignation(() => maskText(res.data.designation));
    localStorage.setItem("maskedDesignation", maskText(res.data.designation));
    console.log("erooorr", formik.errors);
  };

  const handleFocus = () => {
    const divWithValue = document.getElementsByClassName(
      "css-1dimb5e-singleValue"
    )[0];
    divWithValue.style.display = "none";
    // console.log("Value", divWithValue.textContent);
    //Set input value
    // const parentDiv = document.querySelector(".css-qbdosj-Input");
    // const childElement = parentDiv.querySelector("input");
    // console.log("Child", childElement);
    // setTimeout(() => {
    //   childElement.style.width = "100% !important";
    //   childElement.value = divWithValue.textContent;
    // }, 1000);
  };

  const handleBlur = () => {
    document.getElementsByClassName(
      "css-1dimb5e-singleValue"
    )[0].style.display = "block";
  };

  return (
    <>
      <div className="wrapper-box">
        <div className="container">
          <div className="wrapper-mid" style={{ background: "none" }}>
            <div className={`${classes.container}`}>
              <form onSubmit={formik.handleSubmit}>
                <p>Please enter your details</p>
                <hr className={classes.hr2} />

                <div className={classes.inputField}>
                  <label>First Name</label>
                  <input
                    placeholder="First Name"
                    id="firstName"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div>{formik.errors.firstName}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Last Name</label>
                  <input
                    placeholder="Last Name"
                    id="lastName"
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div>{formik.errors.lastName}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Company Name</label>
                  <input
                    placeholder="Company Name"
                    id="companyName"
                    name="companyName"
                    {...formik.getFieldProps("companyName")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <div>{formik.errors.companyName}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Address</label>
                  <input
                    placeholder="Address"
                    id="Address"
                    name="Address"
                    {...formik.getFieldProps("Address")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.Address && formik.errors.Address ? (
                      <div>{formik.errors.Address}</div>
                    ) : null}
                  </span>
                </div>

                {/* <div className={classes.inputField}>
                  <label>Purpose of Visit</label>
                  <input
                    placeholder="Purpose of Visit"
                    id="Purpose"
                    name="Purpose"
                    {...formik.getFieldProps("Purpose")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.Purpose && formik.errors.Purpose ? (
                      <div>{formik.errors.Purpose}</div>
                    ) : null}
                  </span>
                </div> */}

                <div className={classes.inputField}>
                  <label>Purpose of Visit</label>
                  <select
                    placeholder="Purpose of Visit"
                    id="Purpose"
                    name="Purpose"
                    {...formik.getFieldProps("Purpose")}
                  >
                    <option value="">Select</option>
                    <option value="Official">Official</option>
                    <option value="Personal">Personal</option>
                    <option value="Interview">Interview</option>
                    <option value="Meeting">Meeting</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className={classes.errMsg}>
                    {formik.touched.Purpose && formik.errors.Purpose ? (
                      <div>{formik.errors.Purpose}</div>
                    ) : null}
                  </span>
                </div>

                {showCardNo && (
                  <div className={classes.inputField}>
                    <label>Card No</label>
                    <input
                      placeholder="Card No"
                      id="card_no"
                      name="card_no"
                      {...formik.getFieldProps("card_no")}
                    />
                    <span className={classes.errMsg}>
                      {formik.touched.card_no && formik.errors.card_no ? (
                        <div>{formik.errors.card_no}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                {companyData.noOfLocation !== 1 && (
                  <div className={classes.inputField}>
                    <label>
                      {companyData.type == "Company"
                        ? "Select Location"
                        : "Select Company"}
                    </label>
                    <select
                      placeholder={
                        companyData.type == "Company"
                          ? "Select Location"
                          : "Select Company"
                      }
                      id="companyOrBuildingLocations"
                      name="companyOrBuildingLocations"
                      {...formik.getFieldProps("companyOrBuildingLocations")}
                    >
                      <option value="">Select</option>
                      {locations &&
                        locations.map((loc) => {
                          return (
                            <option value={loc._id}>{loc.location}</option>
                          );
                        })}
                    </select>
                    <span className={classes.errMsg}>
                      {formik.touched.companyOrBuildingLocations &&
                      formik.errors.companyOrBuildingLocations ? (
                        <div>{formik.errors.companyOrBuildingLocations}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                {employeeDirectory && (
                  <div className={classes.inputField}>
                    <label>Meeting Person Name</label>
                    <AsyncSelect
                      className="select-search"
                      cacheOptions
                      placeholder="Select an option"
                      defaultValue={searchVal}
                      value={searchVal}
                      id="meeting_person_name"
                      name="meeting_person_name"
                      onChange={(val) => handleSearchChange(val)}
                      loadOptions={promiseOptions}
                      // isSearchable
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      styles={{
                        placeholder: (base) => ({
                          ...base,
                          fontSize: "1em",
                          color: "black",
                          fontWeight: 400
                        })
                      }}
                    />
                    <span className={classes.errMsg}>
                      {formik.touched.meeting_person_name &&
                      formik.errors.meeting_person_name ? (
                        <div>{formik.errors.meeting_person_name}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                {!employeeDirectory && (
                  <div className={classes.inputField}>
                    <label>Meeting Person Name</label>
                    <input
                      placeholder="Meeting Person Name"
                      id="meeting_person_name"
                      name="meeting_person_name"
                      {...formik.getFieldProps("meeting_person_name")}
                    />
                    <span className={classes.errMsg}>
                      {formik.touched.meeting_person_name &&
                      formik.errors.meeting_person_name ? (
                        <div>{formik.errors.meeting_person_name}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                <div className={classes.inputField}>
                  <label>Meeting Person Mobile Number</label>
                  <input
                    placeholder="Meeting Person Mobile Number"
                    id="meeting_person_phone"
                    name="meeting_person_phone"
                    {...formik.getFieldProps("meeting_person_phone")}
                    value={
                      employeeDirectory
                        ? maskedPhone
                        : formik.values.meeting_person_phone
                    }
                    // value={() => "7878787878"}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.meeting_person_phone &&
                    formik.errors.meeting_person_phone ? (
                      <div>{formik.errors.meeting_person_phone}</div>
                    ) : null}
                  </span>
                </div>

                {/* <div className={classes.inputField}>
                  <label>Meeting Person Mobile Number</label>
                  <input
                    placeholder="Meeting Person Mobile Number"
                    id="meeting_person_phone"
                    name="meeting_person_phone"
                    onChange={(e) => handleMeetingPersonData(e.target.value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.meeting_person_phone}
                  />
                  {phoneErr ? (
                    <span className={classes.errMsg}>
                      <div>{phoneErr}</div>
                    </span>
                  ) : (
                    <span className={classes.errMsg}>
                      {formik.touched.meeting_person_phone &&
                      formik.errors.meeting_person_phone ? (
                        <div>{formik.errors.meeting_person_phone}</div>
                      ) : null}
                    </span>
                  )}
                </div> */}

                <div className={classes.inputField}>
                  <label>Designation(optional)</label>
                  <input
                    placeholder="Designation"
                    id="meeting_person_designation"
                    name="meeting_person_designation"
                    {...formik.getFieldProps("meeting_person_designation")}
                    value={
                      employeeDirectory
                        ? maskedDesignation
                        : formik.values.meeting_person_designation
                    }
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.meeting_person_designation &&
                    formik.errors.meeting_person_designation ? (
                      <div>{formik.errors.meeting_person_designation}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Department</label>
                  {employeeDirectory ? (
                    <input
                      placeholder="Department"
                      id="meeting_person_department"
                      name="meeting_person_department"
                      {...formik.getFieldProps("meeting_person_department")}
                    />
                  ) : (
                    <select
                      placeholder="Select Department"
                      id="meeting_person_department"
                      name="meeting_person_department"
                      {...formik.getFieldProps("meeting_person_department")}
                    >
                      <option value="">Select</option>

                      {Departments.map((department) => {
                        return <option value={department}>{department}</option>;
                      })}
                    </select>
                  )}

                  <span className={classes.errMsg}>
                    {formik.touched.meeting_person_department &&
                    formik.errors.meeting_person_department ? (
                      <div>{formik.errors.meeting_person_department}</div>
                    ) : null}
                  </span>

                  <span className={classes.errMsg}>
                    {formik.errors.lat ? <div>{formik.errors.lat}</div> : null}
                  </span>
                </div>

                {showAccessories && (
                  <div className={classes.checkField}>
                    <div>
                      <span>Accessories:</span>
                      <span>
                        <input
                          className={classes.accessoryCheck}
                          type="radio"
                          id="accessory_check"
                          name="accessory_check"
                          defaultChecked={formik.values.accessory_check}
                          // {...formik.getFieldProps("accessory_check")}
                          onClick={() =>
                            formik.setFieldValue("accessory_check", true)
                          }
                        />{" "}
                        Yes
                      </span>
                      <span>
                        <input
                          className={classes.accessoryCheck}
                          type="radio"
                          id="accessory_check"
                          name="accessory_check"
                          defaultChecked={!formik.values.accessory_check}
                          // {...formik.getFieldProps("accessory_check")}
                          onClick={() =>
                            formik.setFieldValue("accessory_check", false)
                          }
                        />{" "}
                        No
                      </span>
                    </div>
                    {formik.values.accessory_check && (
                      <>
                        <div className={classes.accessoryList}>
                          <div className={classes.listItem}>
                            <input
                              type="checkbox"
                              id="laptop_check"
                              name="laptop_check"
                              defaultChecked={formik.values.laptop_check}
                              {...formik.getFieldProps("laptop_check")}
                            />{" "}
                            Laptop
                            {formik.values.laptop_check && (
                              <div className={classes.inputField}>
                                <input
                                  placeholder="Laptop serial no"
                                  id="laptop_serial_no"
                                  name="laptop_serial_no"
                                  defaultChecked={
                                    formik.values.laptop_serial_no
                                  }
                                  {...formik.getFieldProps("laptop_serial_no")}
                                />
                                <span className={classes.errMsg}>
                                  {formik.touched.laptop_serial_no &&
                                  formik.errors.laptop_serial_no ? (
                                    <div>{formik.errors.laptop_serial_no}</div>
                                  ) : null}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={classes.accessoryList}>
                          <div className={classes.listItem}>
                            <input
                              type="checkbox"
                              id="mobile_check"
                              name="mobile_check"
                              defaultChecked={formik.values.mobile_check}
                              {...formik.getFieldProps("mobile_check")}
                            />{" "}
                            Mobile
                            {formik.values.mobile_check && (
                              <div className={classes.inputField}>
                                <input
                                  placeholder="Mobile serial no"
                                  id="mobile_serial_no"
                                  name="mobile_serial_no"
                                  {...formik.getFieldProps("mobile_serial_no")}
                                />
                                <span className={classes.errMsg}>
                                  {formik.touched.mobile_serial_no &&
                                  formik.errors.mobile_serial_no ? (
                                    <div>{formik.errors.mobile_serial_no}</div>
                                  ) : null}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={classes.accessoryList}>
                          <div className={classes.listItem}>
                            <input
                              type="checkbox"
                              id="hard_disk_check"
                              name="hard_disk_check"
                              defaultChecked={formik.values.hard_disk_check}
                              {...formik.getFieldProps("hard_disk_check")}
                            />{" "}
                            Hard disk
                            {formik.values.hard_disk_check && (
                              <div className={classes.inputField}>
                                <input
                                  placeholder="Hard disk serial no"
                                  id="hard_disk_serial_no"
                                  name="hard_disk_serial_no"
                                  {...formik.getFieldProps(
                                    "hard_disk_serial_no"
                                  )}
                                />
                                <span className={classes.errMsg}>
                                  {formik.touched.hard_disk_serial_no &&
                                  formik.errors.hard_disk_serial_no ? (
                                    <div>
                                      {formik.errors.hard_disk_serial_no}
                                    </div>
                                  ) : null}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={classes.accessoryList}>
                          <div className={classes.listItem}>
                            <input
                              type="checkbox"
                              id="charger_check"
                              name="charger_check"
                              defaultChecked={formik.values.charger_check}
                              {...formik.getFieldProps("charger_check")}
                            />{" "}
                            Charger
                            {formik.values.charger_check && (
                              <div className={classes.inputField}>
                                <input
                                  placeholder="Charger serial no"
                                  id="charger_serial_no"
                                  name="charger_serial_no"
                                  {...formik.getFieldProps("charger_serial_no")}
                                />
                                <span className={classes.errMsg}>
                                  {formik.touched.charger_serial_no &&
                                  formik.errors.charger_serial_no ? (
                                    <div>{formik.errors.charger_serial_no}</div>
                                  ) : null}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={classes.accessoryList}>
                          <div className={classes.listItem}>
                            <input
                              type="checkbox"
                              id="other_accessory_check"
                              name="other_accessory_check"
                              defaultChecked={
                                formik.values.other_accessory_check
                              }
                              {...formik.getFieldProps("other_accessory_check")}
                            />{" "}
                            Other
                            {formik.values.other_accessory_check && (
                              <div className={classes.inputField}>
                                <input
                                  placeholder="Other accessory"
                                  id="other_accessory_serial_no"
                                  name="other_accessory_serial_no"
                                  {...formik.getFieldProps(
                                    "other_accessory_serial_no"
                                  )}
                                />
                                <span className={classes.errMsg}>
                                  {formik.touched.other_accessory_serial_no &&
                                  formik.errors.other_accessory_serial_no ? (
                                    <div>
                                      {formik.errors.other_accessory_serial_no}
                                    </div>
                                  ) : null}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <button
                  type="submit"
                  className={classes.btn}
                  onClick={() => {
                    if (Object.keys(formik.errors).length) {
                      console.log(formik.errors);
                      toast.dismiss();
                      console.log("error array", formik.errors);
                      toast.error("Please fill required details", {
                        position: "top-center"
                      });
                    }
                  }}
                >
                  Submit
                </button>
              </form>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;
