import { Pie, Doughnut } from "react-chartjs-2";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import classes from "./PieChart.module.css";

Chart.register(CategoryScale);

const UniqueVisitor = ({ uniqueVisitorNumber, repeatedVisitorNumber }) => {
  const data = {
    labels: [
      `Unique Visitor - ${uniqueVisitorNumber}`,
      `Repeated Visitor - ${repeatedVisitorNumber}`,
    ],
    datasets: [
      {
        data: [uniqueVisitorNumber, repeatedVisitorNumber],
        backgroundColor: [
          "#0437F2",
          "#0096FF",
          "rgb(236,107,109)",
          "rgb(122,231,125)",
          "rgb(195,233,151)",
        ],
        hoverBackgroundColor: ["#000080", "#0c7cad", "#FFCE56"],
      },
    ],

    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2,
      },
      Plugin: {},
    },
    text: "10%",
  };

  return (
    <div className={classes.pieChart}>
      <Pie
        data={data}
        options={{
          elements: {
            center: {
              legend: { display: true, position: "right" },
              text: "Red is 2/3 the total numbers",
              color: "#FF6384", // Default is #000000
              fontStyle: "Arial", // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25, // Default is 25 (in px), used for when text wraps
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  // console.log(context);
                  // return context.label;
                  let percentageChange =
                    ((uniqueVisitorNumber - repeatedVisitorNumber) /
                      uniqueVisitorNumber) *
                    100;
                  if (context.label.includes("Unique")) {
                    return `${percentageChange.toFixed(2)}%`;
                  } else {
                    return repeatedVisitorNumber;
                  }
                },
                title: (context) => {
                  // console.log(context);

                  return context[0].label
                    .toString()
                    .substring(0, context[0].label.toString().indexOf("("));

                  // if (context[0].label.includes("MTD")) {
                  //   return context[0].label
                  //     .toString()
                  //     .substring(0, context[0].label.toString().indexOf("("));
                  // } else {
                  //   return "";
                  // }
                },
              },
            },
          },
          // cutout: "60%",
        }}
      />
    </div>
  );
};

export default UniqueVisitor;
