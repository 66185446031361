let departments = [
  "Sales",
  "Operations",
  "Product",
  "Tech & IT",
  "Quality",
  "Purchase",
  "Procurement",
  "Marketing",
  "Admin",
  "HR",
  "Finance",
  "Legal",
  "Audit",
  "Delivery",
  "Interview",
  "Others",
];

export default departments;
