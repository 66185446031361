import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useContext } from "react";
import authContext from "../../../AuthContext/authContext";
import { useSelector } from "react-redux";
import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function LineChart() {
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const [lineData, setLineData] = useState([]);
  const payload = useSelector((state) => state.appData.barChartPayload);
  const locationId = useSelector((state) => state.appData.chartLocation);
  const lineChartFreqency = useSelector(
    (state) => state.appData.barChartFreqency
  );

  function addMissingDates(dataArray) {
    const updatedArray = [];
    const startDate = new Date(dataArray[0]._id);
    const endDate = new Date(dataArray[dataArray.length - 1]._id);

    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dateString = currentDate.toISOString().split("T")[0];
      const matchingObject = dataArray.find((obj) => obj._id === dateString);

      if (matchingObject) {
        updatedArray.push(matchingObject);
      } else {
        updatedArray.push({ _id: dateString, count: 0 });
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return updatedArray;
  }

  useEffect(() => {
    let data = {
      ...payload,
      frequency: lineChartFreqency,
    };
    if (locationId && locationId["locationId"] != "") {
      data = { ...data, ...locationId };
    }
    let reqObj = {
      method: "POST",
      url: `${base_url}/dashboard/visitors`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`,
      },
      data: data,
    };

    axios(reqObj)
      .then((res) => {
        if (lineChartFreqency == 7) {
          const updatedDataArray = addMissingDates(res.data);
          const newArr = updatedDataArray.slice(-7);
          setLineData(newArr);
        } else {
          setLineData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [payload, lineChartFreqency]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labels = lineData.map((item) => {
    return item["_id"];
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: lineData.map((item) => {
          return item["count"];
        }),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} />;
}
