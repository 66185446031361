import { useState } from "react";

const EmailToModal = ({ sendTestEmail, setMailStatus, mailStatus }) => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const sendEmail = () => {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (!email) {
      setEmailErr("Please enter email");

      return;
    }
    if (!emailRegex.test(email)) {
      console.log("Email wrong");
      setEmailErr("Please enter valid email");

      return;
    }
    sendTestEmail(email);
  };
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Enter email address
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                <div className="mb-3">Send test email to:</div>
                <input
                  class="form-control"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailErr("");
                  }}
                  value={email}
                />
                <div className="text-danger">{emailErr}</div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                id="cancle-mail"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setEmailErr("");
                  setEmail("");
                }}
              >
                Cancel
              </button>
              <button type="button" class="btn btn-primary" onClick={sendEmail}>
                {mailStatus && (
                  <div
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                {mailStatus ? "Sending Mail..." : "Send Email"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailToModal;
