import EditLocation from "./EditLocation";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authContext from "../../../../AuthContext/authContext";
import Home from "../../Common/Home/Home";
const base_url = process.env.REACT_APP_BASE_URL;

const EditLocationWrapper = () => {
  const activeEditLocationId = useSelector(
    (state) => state.appData.activeEditLocationId
  );
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState({});
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/user/profile/${activeEditLocationId}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setInitialData({ ...res.data });
        setDomLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Home
        propComponent={domLoaded && <EditLocation initialData={initialData} />}
      />
    </>
  );
};

export default EditLocationWrapper;
