import Home from "../../Common/Home/Home";
import AddNewBuilding from "./AddNewBuilding";

const AddNewBuildingWrapper = () => {
  return (
    <>
      <Home propComponent={<AddNewBuilding />} />
    </>
  );
};

export default AddNewBuildingWrapper;
